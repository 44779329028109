import React, { useState, useEffect } from "react"

import { Card, Input, Select, Button, Badge } from "antd"

import { useSelector, useDispatch } from "react-redux"
// import { getUserSearchFilters } from "../../redux/actions/ccmActions"

import AdvancedFilters from "./AdvancedFilters"
import { clearFilterModalValuesAction } from "../../redux/actions/utilsActions"
import moment from "moment"
import { CloseOutlined } from "@ant-design/icons"

export const sortBy = Object.freeze({
  AtoZ: 0,
  ZtoA: 1,
  DailyReadingCount: 2,
  LastActiveCount: 3,
  CriticalSortCount: 4
})

const ProviderPatientFilter = props => {
  const {
    allPatients,
    setSortedData,
    applyFilters,
    resetFilters,
  } = props

  const [sortValue, setSortValue] = useState("title")
  const [onBoardSelect, setOnBoardSelect] = useState(false)
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")

  const userInfo = useSelector(state => state.userInfo)
  const dispatch = useDispatch()

  const { getUserSearchData: facilities } = useSelector(state => state.filterSearch)

  const handleSort = patientList => {
    if (sortValue === "count") {
      return sortReadingCount("desc", patientList)
    } else if (sortValue === "Reading") {
      return sortRecentReading("desc", patientList)
    } else if (sortValue === "critical") {
      return filterCriticalReading("desc", patientList)
    }
    return patientList
  }

  const handleSearch = (e) => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }

  const showSearchedData = () => {
    if (searchQuery) {
      const searchedResult = allPatients?.filter(m => {
        const fullName = `${m?.firstName}${m?.middleName || ""}${m?.lastName}`.toLowerCase()
        const reversedFullName = `${m?.lastName}${m?.middleName || ""}${m?.firstName}`.toLowerCase()
        const trimmedSearchValue = searchQuery.replace(/\s+/g, "").toLowerCase()
        return (
          m?.firstName?.toLowerCase().includes(searchQuery) ||
          m?.middleName?.toLowerCase().includes(searchQuery) ||
          m?.lastName?.toLowerCase().includes(searchQuery) ||
          m?.email?.toLowerCase().includes(searchQuery) ||
          m?.mobileNumber?.toLowerCase().includes(searchQuery?.replace(/[()-]/g, "").replace(/\s/g, "") || "") ||
          m?.userName?.toLowerCase().includes(searchQuery) ||
          m?.id?.toLowerCase().includes(searchQuery) ||
          fullName.includes(trimmedSearchValue) ||
          reversedFullName.includes(trimmedSearchValue)
        )
      })
      return searchedResult
    } 
    return allPatients
  }

  const getSearchAndFilterData = () => {
    let patientList = []
    patientList = showSearchedData()
    patientList = handleSort(patientList)
    setSortedData(patientList)
  }

  useEffect(()=>{
    getSearchAndFilterData()
  },[allPatients, searchQuery, sortValue])

  const sortReadingCount = (val, patientList) => {
    const countExistsArr = patientList?.filter(
      x =>
        x?.rpmReadingCount !== null &&
        x?.rpmReadingCount !== undefined &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    // const noCountArr = allPatients.filter(
    //   x =>
    //     x?.rpmReadingCount === undefined || x?.rpmReadingCount === null || x?.rpmReadingCount === 0
    // )

    let sd = []
    if (val === "asc") {
      const ascCountArr = countExistsArr.sort((a, b) => a?.rpmReadingCount - b?.rpmReadingCount)
      sd = [...ascCountArr]
      // sd = noCountArr.concat(ascCountArr)
    } else {
      const descCountArr = countExistsArr.sort((a, b) => b?.rpmReadingCount - a?.rpmReadingCount)
      sd = [...descCountArr]
      // sd = descCountArr.concat(noCountArr)
    }
    return [...sd]
  }

  const sortRecentReading = (val, patientList) => {
    const dateExistsArr = patientList?.filter(
      x =>
        x?.rpmActiveDate !== null &&
        x?.rpmActiveDate !== undefined &&
        x?.rpmActiveDate !== "" &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    // const noDateArr = allPatients?.filter(
    //   x => x?.rpmActiveDate === undefined || x?.rpmActiveDate === null || x?.rpmActiveDate === ""
    // )

    let sd = []
    if (val === "asc") {
      const ascDateArr = dateExistsArr.sort(
        (a, b) => new Date(a?.rpmActiveDate) - new Date(b?.rpmActiveDate)
      )
      sd = [...ascDateArr]
      // sd = noDateArr.concat(ascDateArr)
    } else {
      const descDateArr = dateExistsArr.sort(
        (a, b) => new Date(b?.rpmActiveDate) - new Date(a?.rpmActiveDate)
      )
      sd = [...descDateArr]
      // sd = descDateArr.concat(noDateArr)
    }
    return [...sd]
  }

  const filterCriticalReading = (val, patientList) => {
    const criticalPatients = patientList?.filter(x => x?.criticalStatus === true)
    return criticalPatients
  }

  const resetCallback = () => {
    setSelectedFacility(null)
    setOnBoardSelect(false)
  }

  return (
    <Card
      className="rounded-b-none"
      // title={
      //   providerValue ? (
      //     <h6 className="text-xl font-normal">
      //       Patient's list of Provider : <span className="font-medium">{providerValue}</span>
      //     </h6>
      //   ) : null
      // }
    >
      <div>
        <div className="flex gap-4 flex-wrap items-center justify-between">
          <div className="flex flex-col gap-2">
            <Input.Search
              allowClear
              size="large"
              value={searchQuery}
              className="max-w-[300px]"
              placeholder="Search Patient..."
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="flex flex-wrap gap-4">
            <Select
              size="large"
              onChange={setSortValue}
              value={sortValue}
              className="min-w-[200px]"
              options={[
                {
                  disabled: true,
                  value: "title",
                  label: "Filter Patient By"
                },
                {
                  value: "count",
                  label: "Reading Count"
                },
                {
                  value: "Reading",
                  label: "Recent Reading"
                },
                {
                  value: "critical",
                  label: "Critical Reading"
                }
              ]}
            />
            <Badge dot={selectedFacility}>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  setShowAdvancedFilters(true)
                }}>
                Advanced Filters
              </Button>
            </Badge>
            <Button
              danger
              size="large"
              onClick={() => {
                setSortValue("title")
                setSelectedFacility({})
                resetFilters(resetCallback)
                dispatch(clearFilterModalValuesAction())
              }}>
              Reset Filters
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        {!selectedFacility?.facilityId && selectedFacility?.userType === "outpatient" && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">OutPatient</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {selectedFacility?.facilityName && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Facility: {selectedFacility.facilityName}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {selectedFacility?.unit && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Unit: {selectedFacility.unit}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...selectedFacility,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
                setSelectedFacility({
                  ...selectedFacility,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {selectedFacility?.room && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Room: {selectedFacility.room}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...selectedFacility,
                  room: undefined,
                  bedLocation: undefined
                })
                setSelectedFacility({
                  ...selectedFacility,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {selectedFacility?.bedLocation && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Bed Position: {selectedFacility.bedLocation?.split("_")?.[0]}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...selectedFacility,
                  bedLocation: undefined
                })
                setSelectedFacility({
                  ...selectedFacility,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
      </div>
      {showAdvancedFilters === true && (
        <AdvancedFilters
          setSortValue={setSortValue}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
          onBoardSelect={onBoardSelect}
          resetCallback={resetCallback}
          facilities={facilities}
          selectedFacility={selectedFacility}
          setOnBoardSelect={setOnBoardSelect}
          setSelectedFacility={setSelectedFacility}
          showAdvancedFilters={showAdvancedFilters}
          setShowAdvancedFilters={setShowAdvancedFilters}
        />
      )}
    </Card>
  )
}

export default ProviderPatientFilter
