import { SettingOutlined, FileTextOutlined, UserOutlined } from "@ant-design/icons"

const userInfo = JSON.parse(localStorage.getItem("userInfo"))

const PRACTITIONER = "practitioner"

const PROVIDER_LABEL = "Providers"

const Navigation = [
  {
    key: "/patient",
    label: "Patients",
    icon: <FileTextOutlined />
  },
  userInfo?.userType?.toLowerCase() === "provider" && {
    key: "/practitioners",
    label: "Practitioners",
    icon: <UserOutlined />
  },
  userInfo?.userType?.toLowerCase() === PRACTITIONER && {
    key: "/providers",
    label: PROVIDER_LABEL,
    icon: <UserOutlined />
  },
  {
    key: "/setting",
    label: "Settings",
    icon: <SettingOutlined />
  }
]

export default Navigation
