import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import ReactToPrint from "react-to-print"
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
import RPMOnlyViewForm from "../../../RPMAdminQuestions/RPMOnlyViewForm"
import moment from "moment"

import { Card } from "antd"

const RPMViewPrintV2 = () => {
  const [providerName, setProviderName] = useState();
  let navigate = useNavigate()
  const location = useLocation()

  const componentRef = useRef()

  const patientInfoDetail = location?.state && location?.state?.patientInfoDetail

  const plan = location?.state && location?.state?.plan
  const planDate = location?.state && location?.state?.planDate
  const planIcds = location?.state && location?.state?.planIcds
  const addedBy = location?.state && location?.state?.addedBy
  const patientMonitoringData = location?.state && location?.state?.patientMonitoringData
  const patientCaretaker = location?.state && location?.state?.patientCaretaker
  const providers = location.state.providers;
  const practitioners = location.state.practitioners;

  useEffect(() => {
    if (providers && (providers?.length !== 0)) {
      setProviderName(providers[0])
    } else if (practitioners && (practitioners?.length !== 0)) {
      setProviderName(practitioners[0])
    }
  }, [providers, practitioners])

  const generatePageNum = () => {
    //let totalPages = Math.ceil(document.body.scrollHeight / 1123);  //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    let totalPages = Math.ceil(Object.keys(plan).length / 2) + 1
    for (let i = 1; i <= totalPages; i++) {
      let pageNumberDiv = document.createElement("div")
      let pageNumber = document.createTextNode("Page " + i + " of " + totalPages)
      pageNumberDiv.classList.add("page-num-div")
      pageNumberDiv.style.position = "absolute"
      pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - 40px)" //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
      pageNumberDiv.style.height = "16px"
      pageNumberDiv.appendChild(pageNumber)
      let parentDiv = document.getElementById("view-container")
      let originalDiv = document.getElementById("content")
      parentDiv.insertBefore(pageNumberDiv, originalDiv.nextSibling)
      pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + 100px))"
      pageNumberDiv.style.backgroundColor = "#DBDBDB"
      pageNumberDiv.style.paddingBottom = "24px"
      pageNumberDiv.style.borderRadius = "6px"
    }
  }

  useEffect(() => {
    if (!plan || plan === null) {
      navigate("/")
    } else generatePageNum()
  }, [location?.state])

  const styledPdf = `
  @media print {
    html, body {
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
      display: table;
      width: 100%;
    }
    .page-break {
      display: table-row;
      page-break-inside: avoid;
      margin-top: 1rem;
    }
  }`

  return (
    plan && (
      <>
        <div className="view-container">
          {/* <SidebarComponent sidebarOpen={sidebarOpen} /> */}
          <div id="content" className="no-hadow">
            {/* <RPMViewNavbar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} userInfo={userInfo} /> */}
            <div
              className="view-container"
              id="view-container"
              ref={componentRef}
              style={{
                padding: "20px",
                overflowY: "auto"
              }}>
              <div className="d-flex justify-content-between w-100 py-2">
                <h4 className="Ish px-3">Answers</h4>
                <div className="d-flex noprint">
                  <div>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => window.history.back()}>
                      Back
                    </button>
                  </div>
                  <div>
                    <ReactToPrint
                      pageStyle={styledPdf}
                      trigger={() => (
                        <button className="btn btn-primary btn-sm mx-2">Print as PDF</button>
                      )}
                      content={() => componentRef.current}
                    />
                  </div>
                  {/* <div>
                            <button className="btn btn-dark btn-sm mx-2" onClick={exportPdf}>Test Print</button>
                        </div> */}
                </div>
              </div>
              <div className="CCMAddChronic no-hadow capitalize">
                <Card>
                  <h4 className="text-secondary text-2xl">RPM Treatement Plan</h4>
                  <h3 className="text-secondary mt-1 mb-2">Patient Details</h3>
                  <div className="row justify-content-between">
                    <div className="col-md-6">
                      <p className="mb-1">
                        Date Of Service : {moment(planDate).format("MM/DD/YYYY")}
                      </p>
                      <p className="mb-1">
                        Patient Name :{" "}
                        {`${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${
                          patientInfoDetail?.lastName
                        }`}
                      </p>
                      <p className="mb-1">
                        Patient DOB :{" "}
                        {patientInfoDetail?.dob &&
                          moment(patientInfoDetail?.dob).format("MM/DD/YYYY")}
                      </p>
                      <p className="mb-1">Added By : {`${addedBy?.name} (${addedBy?.userType})`}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-1">
                        Monitor Type :{" "}
                        <span className="uppercase">
                          {patientMonitoringData && Object.keys(patientMonitoringData).join(", ")}
                        </span>
                      </p>
                      <p className="mb-1 flex gap-1">
                        <span>Caretakers</span>
                        <span> : </span>
                        <span>
                          {patientCaretaker
                            ?.map(
                              ({ firstName, middleName, lastName }) =>
                                `${firstName} ${middleName ?? ""} ${lastName}`
                            )
                            .join(", ")}
                        </span>
                      </p>
                      {providers && (providers?.length !== 0) && (
                        <p className="mb-1 flex gap-1">
                          <span className="capitalize"> Providers :
                            {" "}{providers?.join(", ")}
                          </span>
                        </p>
                      )}
                      {practitioners && (practitioners?.length !== 0) && (
                        <p className="mb-1 flex gap-1">
                          <span className="capitalize"> Practitioner :
                            {" "}{practitioners?.join(", ")}
                          </span>
                        </p>
                      )}
                      <p className="mb-1 flex gap-1">
                        <span>Diagnostic(ICD) Codes</span>
                        <span> : </span>
                        <span>{planIcds?.join(", ")}</span>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>

              {/* -------------------description div starts---------------------------- */}
              {plan?.[3]?.questionaryOptions[2]?.checked === true ? (
                <div className="shadow w-100 p-4 card no-hadow desc-div my-5">
                  <h4>Description</h4>
                  <p>
                    <span className="fw-bold text-capitalize">
                      {patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                      {patientInfoDetail?.lastName}
                    </span>{" "}
                    currently has the following conditions:<br></br>
                    {/* {carePlanData?.careplanDetails?.map((item, i) => (
                    <div className="text-capitalize fw-bold" key={i}>{i + 1}. {item}</div>
                  ))} */}
                    Dr.{" "}
                    <span className="fw-bold text-capitalize">{providerName}</span>
                    identified this patient as a good candidate for enrollment into the Remote
                    Patient Monitoring (RPM) program.<br></br>
                    The healthcare provider has recommended the following devices:<br></br>
                    {/* <span className="fw-bold">{patientInfoDetail?.moniteringDetails?.map((item, i) => (
                    item?.moniterData?.name === 'rpm' ?
                      item?.deviceDetails?.map((dev, idx) => (
                        <div className="text-capitalize" key={idx}>{idx + 1}. {dev?.deviceData?.name}</div>
                      ))
                      :
                      null
                  ))}</span> */}
                    The FitPeo devices will be used to collect health data from the patient and to
                    monitor their treatment plan remotely.<br></br>
                    The patient has been provided informed verbal consent for the RPM program at the
                    time of annual enrollment.<br></br>
                    <span className="fw-bold text-capitalize">{addedBy?.name}</span> spoke with the
                    patient today via (video call, phone call, virtual call) to initiate and review
                    the treatment plan.<br></br>
                    Equipment was mailed to the patient successfully and received on{" "}
                    {plan?.[4]?.text !== "" ? (
                      <span className="fw-bold">{plan[4].text}</span>
                    ) : (
                      "--.--.----"
                    )}{" "}
                    by the patient.<br></br>
                    The patient has been educated on how to properly utilize the equipment and the
                    importance of monitoring their vital signs daily via the FitPeo equipment
                    provided.<br></br>
                    This education was provided on{" "}
                    {plan?.[4]?.text !== "" ? (
                      <span className="fw-bold">{plan[4].text}</span>
                    ) : (
                      "--.--.----"
                    )}
                    .<br></br>
                    The patient has agreed to the treatment plan and has been provided with our
                    contact information for any questions/concerns.
                  </p>
                </div>
              ) : plan?.[3]?.questionaryOptions[1]?.checked === true ? (
                <div className="shadow w-100 p-4 card no-hadow desc-div my-5">
                  <h4>Description</h4>
                  <p>
                    <span className="fw-bold text-capitalize">
                      {patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                      {patientInfoDetail?.lastName}
                    </span>{" "}
                    currently has the following conditions:<br></br>
                    {/* {carePlanData?.careplanDetails?.map((item, i) => (
                      <div className="text-capitalize fw-bold" key={i}>{i + 1}. {item}</div>
                    ))} */}
                    Dr.{" "}
                    <span className="fw-bold text-capitalize">{providerName}</span>
                    identified this patient as a good candidate for enrollment into the Remote
                    Patient Monitoring (RPM) program.<br></br>
                    The healthcare provider has recommended the following devices:<br></br>
                    {/* <span className="fw-bold">{patientInfoDetail?.moniteringDetails?.map((item, i) => (
                      item?.moniterData?.name === 'rpm' ?
                        item?.deviceDetails?.map((dev, idx) => (
                          <div className="text-capitalize">{idx + 1}. {dev?.deviceData?.name}</div>
                        ))
                        :
                        null
                    ))}</span> */}
                    The FitPeo devices will be used to collect health data from the patient and to
                    monitor their treatment plan remotely.<br></br>
                    The patient has been provided informed verbal consent for the RPM program at the
                    time of annual enrollment.<br></br>
                    <span className="fw-bold text-capitalize">{addedBy?.name}</span> spoke with the
                    patient today to initiate and review the treatment plan.<br></br>
                    Equipment was delivered to the patient successfully and received on{" "}
                    {plan?.[4]?.text !== "" ? (
                      <span className="fw-bold">{plan[4].text}</span>
                    ) : (
                      "--.--.----"
                    )}{" "}
                    by the patient.<br></br>
                    The patient has been educated on how to properly utilize the equipment and the
                    importance of monitoring their vital signs daily via the FitPeo equipment
                    provided.<br></br>
                    This education was provided on{" "}
                    {plan?.[4]?.text !== "" ? (
                      <span className="fw-bold">{plan[4].text}</span>
                    ) : (
                      "--.--.----"
                    )}
                    .<br></br>
                    The patient has agreed to the treatment plan and has been provided with our
                    contact information for any questions/concerns.
                  </p>
                </div>
              ) : plan?.[3]?.questionaryOptions[0]?.checked === true ? (
                <div className="shadow w-100 p-4 card no-hadow desc-div my-5">
                  <h4>Description</h4>
                  <p>
                    <span className="fw-bold text-capitalize">
                      {patientInfoDetail?.firstName}&nbsp;{patientInfoDetail?.middleName}&nbsp;
                      {patientInfoDetail?.lastName}
                    </span>{" "}
                    currently has the following conditions:<br></br>
                    {/* {carePlanData?.careplanDetails?.map((item, i) => (
                        <div className="text-capitalize fw-bold" key={i}>{i + 1}. {item}</div>
                      ))} */}
                    Dr.{" "}
                    <span className="fw-bold text-capitalize">
                      {providerName}
                    </span>{" "}
                    identified this patient as a good candidate for enrollment into the Remote
                    Patient Monitoring (RPM) program.<br></br>
                    The healthcare provider has recommended the following devices:<br></br>
                    {/* <span className="fw-bold">{patientInfoDetail?.moniteringDetails?.map((item, i) => (
                        item?.moniterData?.name === 'rpm' ?
                          item?.deviceDetails?.map((dev, idx) => (
                            <div className="text-capitalize">{idx + 1}. {dev?.deviceData?.name}</div>
                          ))
                          :
                          null
                      ))}</span> */}
                    The FitPeo devices will be used to collect health data from the patient and to
                    monitor their treatment plan remotely.<br></br>
                    The patient has been provided informed verbal consent for the RPM program at the
                    time of annual enrollment.<br></br>
                    <span className="fw-bold text-capitalize">{addedBy?.name}</span> spoke with the
                    patient today to initiate and review the treatment plan.<br></br>
                    Equipment was given to the patient on{" "}
                    {plan?.[4]?.text !== "" ? (
                      <span className="fw-bold">{plan[4].text}</span>
                    ) : (
                      "--.--.----"
                    )}{" "}
                    in the office by{" "}
                    <span className="fw-bold text-capitalize">{`${patientMonitoringData?.rpm?.[0]?.providerfirstName
                      } ${patientMonitoringData?.rpm?.[0]?.providerMiddleName ?? ""} ${patientMonitoringData?.rpm?.[0]?.providerLastName
                      }`}</span>
                    .<br></br>
                    The patient has been educated on how to properly utilize the equipment and the
                    importance of monitoring their vital signs daily via the FitPeo equipment
                    provided.<br></br>
                    This education was provided on{" "}
                    {plan?.[4]?.text !== "" ? (
                      <span className="fw-bold">{plan[4].text}</span>
                    ) : (
                      "--.--.----"
                    )}
                    .<br></br>
                    The patient has agreed to the treatment plan and has been provided with our
                    contact information for any questions/concerns.
                  </p>
                </div>
              ) : null}
              {/* --------------------description div ends-------------------- */}
              <div className="plan-print-div" style={{ counterIncrement: "page" }}>
                {Object.values(plan)?.map((ques, index) => (
                  <div
                    className="card w-100 mb-3 shadow p-4 no-hadow mt-4"
                    key={index}
                    style={{ pageBreakAfter: "auto", color: "black" }}>
                    <h4 className="form_heading fw-bold">{ques?.name}</h4>
                    <h5 className="subHeading">{ques?.title}</h5>

                    <div className="not_applicable">
                      {ques.notApplicable !== undefined && ques.notApplicable ? (
                        <>
                          <label className="d-flex pt-4 text-dark">
                            <input
                              type="checkbox"
                              checked={ques?.notApplicable ? true : false}
                              disabled
                              style={{
                                marginRight: "18px"
                              }}
                            />
                            Not Applicable
                          </label>
                        </>
                      ) : ques.anyValue !== undefined && !ques.anyValue ? (
                        <div className="not_applicable">
                          <>
                            <label className="d-flex pt-4 text-dark">
                              <input
                                type="checkbox"
                                checked
                                disabled
                                style={{
                                  marginRight: "18px"
                                }}
                              />
                              Not Applicable
                            </label>
                          </>
                        </div>
                      ) : (
                        <RPMOnlyViewForm ques={ques} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default RPMViewPrintV2
