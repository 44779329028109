import React, { useState } from "react"

import { Modal, Radio, Space, Divider, Button } from "antd"

import Loading from "../Loading/Loading"

import { useDispatch, useSelector } from "react-redux"
import { getProviderPatientList } from "../../redux/actions/providerAction"

const ProviderModal = ({
  showProviderSelectModal,
  setShowProviderSelectModal,
  setProviderValue
}) => {
  const [value, setValue] = useState("")
  const dispatch = useDispatch()
  const onChange = e => {
    setValue(e.target.value)
  }

  const { loading, providerList } = useSelector(state => state.providerList)
  // console.log('providerList',providerList)

  const handleCloseModal = () => {
    if (!value) {
      return alert("Please select a provider first.")
    }
    setShowProviderSelectModal(false)
    let newProivder = providerList?.filter(x => x?.userId === value)
    // console.log('np',newProivder)
    let str = ""
    if (newProivder) {
      let np = newProivder[0]
      str =
        `${np?.firstName?.charAt(0)?.toUpperCase() + np?.firstName?.slice(1)}` +
        " " +
        `${np?.middleName?.charAt(0)?.toUpperCase() + np?.middleName?.slice(1)}` +
        " " +
        `${np?.lastName?.charAt(0)?.toUpperCase() + np?.lastName?.slice(1)}`
    }
    // console.log('str',str)
    setProviderValue(str)
    dispatch(getProviderPatientList(value))
    let newObj = { providerDetail: str, providerId: value }
    localStorage.setItem("PractitionerProviderId", JSON.stringify(newObj))
  }

  return (
    <Modal
      footer={null}
      closable={false}
      open={showProviderSelectModal}
      title={<h4 className="text-xl">Provider List</h4>}>
      <div className="py-2">
        <p className="font-medium">Select a provider</p>
        <Divider />
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {!loading && providerList?.length ? (
              providerList?.map((item, i) => (
                <Radio key={i} value={item?.userId}>
                  {item?.firstName?.charAt(0)?.toUpperCase() + item?.firstName?.slice(1)}{" "}
                  {item?.middleName?.charAt(0)?.toUpperCase() + item?.middleName?.slice(1)}{" "}
                  {item?.lastName?.charAt(0)?.toUpperCase() + item?.lastName?.slice(1)}
                </Radio>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <Loading />
              </div>
            )}
          </Space>
        </Radio.Group>
        <Divider />
        <Button disabled={value === ""} type="primary" onClick={handleCloseModal}>
          Continue
        </Button>
      </div>
    </Modal>
  )
}

export default ProviderModal
