import React, { useState, useEffect } from "react"
import Loading from "../components/Loading/Loading"
import { Helmet } from "react-helmet"
import PlanView from "@fitpeo/fit-plan-viewer"
import { TempAxiosGet } from "../utils/TokenStorage"

const MobileRPM = () => {
  const [patientInfoDetail, setPatientInfoDetail] = useState(null)
  const [treatmentPlanData, setTreatmentPlanData] = useState(null)
  const [providers, setProviders] = useState(null)
  const [practitioners, setPractitioners] = useState(null)
  const [icdCodes, setIcdCodes] = useState(null)
  const [loading, setLoading] = useState(true)
  const [firstCall, setFirstCall] = useState(false)
  const [planDate, setplanDate] = useState()
  const [addedBy, setAddedBy] = useState()

  // useEffect(() => {
  //     setLoading(true)
  //     const timer = setTimeout(() => {
  //         if (window.value !== undefined && window.token !== undefined && firstCall === false) {
  //             getPatientData(window.value.patientId)
  //             getRpmPlan(window.value.patientId)
  //             setFirstCall(true)
  //     }
  //     }, 1000);
  //     setLoading(false)
  //     return () => clearTimeout(timer);

  // }, [window.token]);

  useEffect(() => {
    setTimeout(() => {
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        getPatientData(window.value.patientId)
        setFirstCall(true)
      }
    }, 1000)
  }, [window.token])

  // console.log('passed token',window.token)
  const getRpmPlan = async patientID => {
    const res = await TempAxiosGet(
      window.token,
      `/api/rpm/readings/treatmentplan/latest/${patientID}/fetch`
    )
    if (res?.details) {
      setplanDate(res?.details?.planDate.slice(0, 10))
      setAddedBy(res?.details?.plan?.addingBy)
      setTreatmentPlanData(Object.values(res?.details?.plan))
    }
  }

  const getProvider = async patientID => {
    const res = await TempAxiosGet(window.token, `api/cs/patients/monitoring/${patientID}/fetch`)
    if (res?.details?.rpm) {
      let providers = []
      let practitioners = []
      // eslint-disable-next-line array-callback-return
      res?.details?.rpm?.map(item => {
        if (item.providerId) {
          providers.push(`${item?.providerfirstName}${(item?.providerMiddleName && item?.providerMiddleName !== "") ? " " + item?.providerMiddleName : ""} ${item?.providerLastName}`)
        }
        if (item.practitionerId) {
          practitioners.push(`${item?.practitionerfirstName}${(item?.practitionerMiddleName && item?.practitionerMiddleName !== "") ? " " + item?.practitionerMiddleName : ""} ${item?.practitionerLastName}`)
        }
      })
      setProviders(providers)
      setPractitioners(practitioners)
    }
    return
  }

  const getIcds = async patientID => {
    const res = await TempAxiosGet(window.token, `api/cs/patients/diagnostic/${patientID}/fetch`)
    setIcdCodes(res?.details?.map(({ icdCode }) => icdCode))
  }

  const getPatientData = async patientId => {
    let res = await TempAxiosGet(window.token, `/api/cs/patients/${patientId}/profile`)
    if (res?.status === 200) {
      setPatientInfoDetail(res?.details)
      await getRpmPlan(patientId)
      await getIcds(patientId)
      await getProvider(patientId)
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <script>{`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData));
                // var content = {"token":"eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoiMzk3Y2E4YzMtZjllNy00YTliLWFjN2UtYzk0MzQ3OWQ1Yzc4IiwiZXhwIjoxNzE4MDMyMzM5LCJpYXQiOjE3MTgwMjg3MzksInNjb3BlIjoicHJvdmlkZXIifQ.QRpTIcb73AeOjTtiMJNLTwJRQypoBKcQNeJ_dhjoLD8fkuRvpqG_hfa3oPyIl0i3SIPTJzlk7U2QTe2Dm8B-h6sQjYvkdEjN9-XsjwvjuHW0tzFBOI5c9-sJ_g5znUhNyYcCUwMtU_7EgwW-PHQZRYqgIphko2x8p_Yb_93m7AMferFluQuvvqIxPbsTaM9cuJG4Eap5RTNs-A3RZRj2MhCCpYHAsIpVfM_G0oEP5IPkpq4NsI7IHkmF8SavzZv20rOI5LVx5LWfEc20PAzuhCadLdCVT3ZXqHnRmLPXcxT--XNRG5vcyEX-2tlEetFuzy1duO4b1hZiFbpJREgYXw", patientId:"8e9d8b22-e487-4226-829d-3009d7c98af1"};
                
                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token; 
                
        }`}</script>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <PlanView
          type="rpm"
          patientInfoDetail={patientInfoDetail}
          plan={treatmentPlanData?.filter(data => data.id)}
          planDate={planDate}
          planIcds={icdCodes}
          addedBy={addedBy}
          providers={providers}
          practitioners={practitioners}
        />
      )}
    </>
  )
}

export default MobileRPM
