export const OnBoardingPendingStage = {
  SignUpPending: "Sign Up Pending",
  SignUpInitiated: "Sign Up Initiated",
  DeniedService: "Denied Enrollment"
}
export const OnBoardedStage = {
  SignedUp: "Signed Up",
  DevicesReceived: "Devices Received"
}

export const PatientStage = {
  Active: "active",
  Eligible: "eligible"
}

export const BulkMessageMailActionWelcome = {
  SendWelcomeMessageToPhone: "Send welcome message (SMS) to phone",
  SendWelcomeToFitPeoMail: "Send welcome to FitPeo mail"
}

export const BulkMessageMailActionIntroduction = {
  sendIntroductionMail: "Send introduction mail",
  sendIntroductionMessageToPhone: "Send introduction message (SMS) to phone"
}

export const BulkMessageMailActionNotificationDeviceRecived = {
  SendMessageToTakeReading: "Send message (SMS) to take reading",
  SendMailToTakeReading: "Send mail to take reading",
  SendAppNotificationToTakeReading: "Send app notification to take reading"
}

export const MessageType = {
  IntroductionMail: "introduction_mail",
  IntroductionMessage: "introduction_message",
  WelcomeMail: "welcome_mail",
  WelcomeMessage: "welcome_message"
}

export const PatientStatus = {
  Active : "Active",
  DeniedServices : "Denied Services",
  DeviceReceived: "Device Received",
  FacingIssue : "Facing Issue",
  Inactive : "Inactive"
}

export const EquipmentStatus = {
  Delivered : "Delivered",
  DeliveryRequested : "Delivery Requested",
  EquipmentActive : "Equipment Active",
  EquipmentMissing : "Equipment Missing",
  FacingEquipmentIssue : "Facing Equipment Issue",
  InTransit : "In Transit",
  PickedUpFromClinic : "Picked Up From Clinic",
  WillPickUpFromClinic : "Will Pick Up From Clinic"
}

