import React, { useState, useEffect } from "react"

import { Card, Input, Select, Button, Badge } from "antd"

import { useSelector, useDispatch } from "react-redux"

import AdvancedFilters from "./AdvancedFilters"
import { useLocation } from "react-router-dom"
import { clearFilterModalValuesAction } from "../../redux/actions/utilsActions"
import { resetPractitionerPatientAdvanceFilter } from "../../redux/actions/practitionerAction"
import { CloseOutlined } from "@ant-design/icons"

export const sortBy = Object.freeze({
  AtoZ: 0,
  ZtoA: 1,
  DailyReadingCount: 2,
  LastActiveCount: 3,
  CriticalSortCount: 4
})

const PractitionerPatientFilter = props => {
  const {
    searchQuery,
    applyFilters,
    resetFilters,
    setSearchQuery,
    sortValue,
    setSortValue
  } = props
  const [onBoardSelect, setOnBoardSelect] = useState(false)
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const { practitionerPatientAdvFilter: patientAdvFilter } = useSelector(
    state => state.practitionerPatientAdvFilter
  )
  const [filteredValues, setFilteredValues] = useState(patientAdvFilter)
  const state = useLocation()
  const dispatch = useDispatch()
  // const filter = useSelector(state => state.filter)
  const userInfo = useSelector(state => state.userInfo)
  const { getUserSearchData, loading } = useSelector(state => state.filterSearch)

  // useEffect(() => {
  //   // dispatch(getUserSearchFilters(userInfo?.userInfo?.orgId))
  // }, [dispatch, userInfo?.userInfo?.orgId])

  const resetCallback = () => {
    dispatch(resetPractitionerPatientAdvanceFilter())
    setFilteredValues({})
    setOnBoardSelect(false)
  }

  useEffect(() => {
    if (state?.state?.mark && onBoardSelect !== true) {
      setOnBoardSelect(true)
      dispatch(clearFilterModalValuesAction())
      // dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
    }
  }, [])

  const handlesearch = (e) => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchQuery(queryWithoutSpace)
  }

  return (
    <Card title={<h4 className="text-xl">Filters</h4>}>
      <div className="flex flex-wrap gap-3 justify-between">
        <div className="flex flex-col gap-2">
          <Input.Search
            allowClear
            size="large"
            value={searchQuery}
            className="max-w-[300px]"
            placeholder="Search Patient..."
            onChange={(e) => handlesearch(e)}
          />
        </div>
        <Select
          size="large"
          value={sortValue}
          onChange={setSortValue}
          className="min-w-[300px]"
          options={[
            {
              disabled: true,
              value: "title",
              label: "Filter Patient By"
            },
            {
              value: "count",
              label: "Reading Count"
            },
            {
              value: "Reading",
              label: "Recent Reading"
            },
            {
              value: "critical",
              label: "Critical Reading"
            }
          ]}
        />
        <Badge dot={patientAdvFilter} style={{ dot: { width: "50px" } }}>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setShowAdvancedFilters(true)
            }}>
            Advanced Filters
          </Button>
        </Badge>
        <Button
          danger
          size="large"
          onClick={() => {
            setSortValue("title")
            setFilteredValues({})
            resetFilters(resetCallback)
            dispatch(clearFilterModalValuesAction())
          }}>
          Reset Filters
        </Button>
      </div>
      <div className="flex flex-wrap gap-2">
        {!patientAdvFilter?.facilityId && patientAdvFilter?.userType === "outpatient" && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">OutPatient</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {patientAdvFilter?.facilityName && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Facility: {patientAdvFilter.facilityName}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                resetFilters(resetCallback)
              }}
            />
          </div>
        )}
        {patientAdvFilter?.unit && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Unit: {patientAdvFilter.unit}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  unit: undefined,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {patientAdvFilter?.room && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">Room: {patientAdvFilter.room}</span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  room: undefined,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  room: undefined,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
        {patientAdvFilter?.bedLocation && (
          <div
            className="mt-2.5"
            style={{
              padding: "2px 2px 2px 12px",
              borderRadius: 20,
              border: "1px solid rgba(0, 0, 0, 0.5)",
              display: "inline-block"
            }}>
            <span className="font-semibold capitalize">
              Bed Position: {patientAdvFilter.bedLocation?.split("_")?.[0]}
            </span>
            <Button
              className="ml-2"
              type="text"
              shape="circle"
              icon={<CloseOutlined className="text-[#00000080]" />}
              onClick={() => {
                applyFilters(onBoardSelect ? "onboard" : "normal", {
                  ...patientAdvFilter,
                  bedLocation: undefined
                })
                setFilteredValues({
                  ...patientAdvFilter,
                  bedLocation: undefined
                })
              }}
            />
          </div>
        )}
      </div>
      <AdvancedFilters
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        onBoardSelect={onBoardSelect}
        resetCallback={resetCallback}
        facilities={getUserSearchData}
        filteredValues={filteredValues}
        setOnBoardSelect={setOnBoardSelect}
        setFilteredValues={setFilteredValues}
        showAdvancedFilters={showAdvancedFilters}
        setShowAdvancedFilters={setShowAdvancedFilters}
      />
    </Card>
  )
}

export default PractitionerPatientFilter
