import {
    GET_CCM_FAIL,
    GET_CCM_REQUEST,
    GET_CCM_SUCCESS,
    POST_CCM_USER_FAIL,
    POST_CCM_USER_REQUEST,
    POST_CCM_USER_SUCCESS,
    GET_CCM_CAREPLAN_REQUEST,
    GET_CCM_CAREPLAN_SUCCESS,
    GET_CCM_CAREPLAN_FAIL,
    GET_FILTER_USERS_REQUEST,
    RESET_FILTER_USERS_REQUEST,
    GET_FILTER_USERS_SUCCESS,
    GET_FILTER_USERS_FAIL,
    GET_USER_SEARCH_REQUEST,
    GET_USER_SEARCH_SUCCESS,
    GET_USER_SEARCH_FAIL,
    GET_FACILITY_ADDRESS_REQUEST,
    GET_FACILITY_ADDRESS_SUCCESS,
    GET_FACILITY_ADDRESS_FAIL,
} from "../types/ccmConstant";

export const getCcmReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CCM_REQUEST:
            return { loading: true };
        case GET_CCM_SUCCESS:
            return { loading: false, getCcm: action.payload };
        case GET_CCM_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const postCCMReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_CCM_USER_REQUEST:
            return { loading: true };
        case POST_CCM_USER_SUCCESS:
            return { loading: false, postCCMUserData: action.payload };
        case POST_CCM_USER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const getCcmCarePlanReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CCM_CAREPLAN_REQUEST:
            return { loading: true };
        case GET_CCM_CAREPLAN_SUCCESS:
            return { loading: false, getCcmCarePlan: action.payload };
        case GET_CCM_CAREPLAN_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const getFilterUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FILTER_USERS_REQUEST:
            return { loading: true };
        case RESET_FILTER_USERS_REQUEST:
            return { loading: false };
        case GET_FILTER_USERS_SUCCESS:
            return { loading: false, getFilterUsersData: action.payload };
        case GET_FILTER_USERS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const getUserSearchReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_SEARCH_REQUEST:
            return { loading: true };
        case GET_USER_SEARCH_SUCCESS:
            return { loading: false, getUserSearchData: action.payload };
        case GET_USER_SEARCH_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const getFacilityAddressReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FACILITY_ADDRESS_REQUEST:
            return { loading: true };
        case GET_FACILITY_ADDRESS_SUCCESS:
            return { loading: false, getFacilityAddressData: action.payload };
        case GET_FACILITY_ADDRESS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};