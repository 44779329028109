import { axiosGet, axiosPost } from "../axios";
import {
    PROVIDER_ADD_FAIL,
    PROVIDER_ADD_REQUEST,
    PROVIDER_ADD_SUCCESS,
    PROVIDER_KPI_FAIL,
    PROVIDER_KPI_REQUEST,
    PROVIDER_KPI_SUCCESS,
    PROVIDER_LIST_FAIL,
    PROVIDER_LIST_REQUEST,
    PROVIDER_LIST_SUCCESS,
    PROVIDER_NPI_FAIL,
    PROVIDER_NPI_REQUEST,
    PROVIDER_NPI_SUCCESS,
    PROVIDER_PATIENT_LIST_FAIL,
    PROVIDER_PATIENT_LIST_REQUEST,
    PROVIDER_PATIENT_LIST_SUCCESS,
    SINGLE_PROVIDER_FAIL,
    SINGLE_PROVIDER_REQUEST,
    SINGLE_PROVIDER_SUCCESS,
    UPDATE_PROVIDER_FAIL,
    UPDATE_PROVIDER_REQUEST,
    UPDATE_PROVIDER_SUCCESS,
    GET_PROVIDER_SPECIALIZATION_REQUEST,
    GET_PROVIDER_SPECIALIZATION_SUCCESS,
    GET_PROVIDER_SPECIALIZATION_FAIL,
    GET_ADVANCED_FILTERS_OPTIONS_REQUEST,
    GET_ADVANCED_FILTERS_OPTIONS_SUCCESS,
    GET_ADVANCED_FILTERS_OPTIONS_FAIL,
    GET_PROVIDER_ADDRESS_REQUEST,
    GET_PROVIDER_ADDRESS_SUCCESS,
    GET_PROVIDER_ADDRESS_FAIL,
    GET_PROVIDER_NPI_REQUEST,
    GET_PROVIDER_NPI_SUCCESS,
    GET_PROVIDER_NPI_FAIL,
    GET_PROVIDER_FILTER_PATIENTS_REQUEST,
    GET_PROVIDER_FILTER_PATIENTS_SUCCESS,
    GET_PROVIDER_FILTER_PATIENTS_FAIL,
    GET_PROVIDER_FILTER_PATIENTS_RESET,
    SET_PROVIDER_PATIENT_ADVANCE_FILTER,
    RESET_PROVIDER_PATIENT_ADVANCE_FILTER
} from "../types/providerConstant";
import { userLogoutAction } from './userActions';

export const fetchProviderProfile = providerId => async dispatch => {
  try {
    dispatch({
      type: SINGLE_PROVIDER_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/provider/${providerId}/profile`)

    dispatch({
      type: SINGLE_PROVIDER_SUCCESS,
      payload: data?.details
    })

    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())

    const errMsg = error?.response?.data?.details
      ? Object.values(error.response?.data?.details)[0]
      : error?.response?.data?.error

    dispatch({
      type: SINGLE_PROVIDER_FAIL,
      payload: errMsg
    })
    return errMsg
  }
}

export const getProviderAddressAction = providerId => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_ADDRESS_REQUEST })

    const { data } = await axiosGet(`/api/cs/address/provider/${providerId}/fetch`)
    dispatch({ type: GET_PROVIDER_ADDRESS_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PROVIDER_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getProviderNPIAction = providerId => async dispatch => {
  try {
    dispatch({ type: GET_PROVIDER_NPI_REQUEST })

    const { data } = await axiosGet(`/api/cs/provider/npi/${providerId}/fetch`)
    dispatch({ type: GET_PROVIDER_NPI_SUCCESS, payload: data.details })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PROVIDER_NPI_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getFilterProviderPatientAction =
  ({ providerId, formData }) =>
  async dispatch => {
    try {
      dispatch({
        type: GET_PROVIDER_FILTER_PATIENTS_REQUEST
      })

      const { data } = await axiosPost(
        `/api/cs/patients/filters/provider/${providerId}/fetch`,
        formData
      )
      dispatch({
        type: GET_PROVIDER_FILTER_PATIENTS_SUCCESS,
        payload: data?.details
      })
      return data?.details
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: GET_PROVIDER_FILTER_PATIENTS_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }
  }

export const resetFilterProviderPatientAction = () => async dispatch => {
  dispatch({
    type: GET_PROVIDER_FILTER_PATIENTS_RESET
  })
}

export const saveProviderPatientAdvanceFilter = data => async dispatch => {
  dispatch({
    type: SET_PROVIDER_PATIENT_ADVANCE_FILTER,
    payload: data
  })
}

export const resetProviderPatientAdvanceFilter = () => async dispatch => {
  dispatch({
    type: RESET_PROVIDER_PATIENT_ADVANCE_FILTER
  })
}


export const getAdvancedFiltersOptions = () => async dispatch => {
    try {
      const userInfo =
        localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
      dispatch({
        type: GET_ADVANCED_FILTERS_OPTIONS_REQUEST
      })
  
      const { data } = await axiosGet(`/api/cs/config/facility/orgId/${userInfo.orgId}/fetch`)
      dispatch({
        type: GET_ADVANCED_FILTERS_OPTIONS_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: GET_ADVANCED_FILTERS_OPTIONS_FAIL,
        payload: error.response && error?.response?.data?.error
      })
    }
}

export const getSpecializationAction = providerId => async dispatch => {
    try {
      dispatch({ type: GET_PROVIDER_SPECIALIZATION_REQUEST })

      const { userType } = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
      
      const { data } = await axiosGet(`/api/cs/${userType}/specializations/${providerId}/fetch`)
      dispatch({ type: GET_PROVIDER_SPECIALIZATION_SUCCESS, payload: data.details })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: GET_PROVIDER_SPECIALIZATION_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }
  }

export const getProviderList = () => async (dispatch) => {
    try {
        dispatch({
            type: PROVIDER_LIST_REQUEST,
        });
        
        const {data} = await axiosGet(`/providers/v1/getProviderList`);
        // const {data} = await axiosGet(`/admin/v1/getProvidersForOrg`);
        
        dispatch({
            type: PROVIDER_LIST_SUCCESS,
            payload: data?.result,
        });
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: PROVIDER_LIST_FAIL,
            payload: error.response && error?.response?.data?.detail,
        });
    }
};

export const getProviderKPI = (orgId) => async (dispatch) => {
    try {
        dispatch({
            type: PROVIDER_KPI_REQUEST,
        });
        
        const {data} = await axiosGet(`v1/getProviderListKPIDB?orgId=${orgId}`);
        
        dispatch({
            type: PROVIDER_KPI_SUCCESS,
            payload: data,
        });
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: PROVIDER_KPI_FAIL,
            payload: error.response && error?.response?.data?.detail,
        });
    }
};

export const getProviderPatientList = (id, userType) => async dispatch => {
  try {
    dispatch({
      type: PROVIDER_PATIENT_LIST_REQUEST
    })
    // userType can be provider or practitioner

    const { data } = await axiosGet(`/api/cs/patients/${userType}/${id}/fetchAll`)
    dispatch({
      type: PROVIDER_PATIENT_LIST_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PROVIDER_PATIENT_LIST_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const providerNPIAction = (providerId) => async (dispatch) => {
    try {
        dispatch({type: PROVIDER_NPI_REQUEST});

        const { userType } = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))

        const {data} = await axiosGet(`/api/cs/${userType}/npi/${providerId}/fetch`);
        dispatch({type: PROVIDER_NPI_SUCCESS, payload: data?.details});
    } catch (error) {
        dispatch({
            type: PROVIDER_NPI_FAIL,
            payload: error.response && error?.response?.data?.details,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const getSingleProviderAction = (provider) => async (dispatch) => {
    try {
        dispatch({type: SINGLE_PROVIDER_REQUEST});
        
        // const {data} = await axiosGet(`v1/getDoctorProfileForUser?doctorId=${providerId}`);
        dispatch({type: SINGLE_PROVIDER_SUCCESS, payload: provider});
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: SINGLE_PROVIDER_FAIL,
            payload: error.response && error?.response?.data?.detail,
        });
    }
};

export const addProviderAction =
      ({
           email,
           phoneNumber,
           userName,
           firstName,
           middleName,
           lastName,
           orgId,
           gender,
           npi,
           specality,
           taxonomyData,
           profileImage,
           countryCode,
           addressDetails,
           primaryAddress,
           mailingAddress,
       }) =>
            async (dispatch) => {
                try {
                    dispatch({
                        type: PROVIDER_ADD_REQUEST,
                    });
                    
                    const formData = {
                        email,
                        phoneNumber,
                        firstName,
                        userName,
                        middleName,
                        lastName,
                        profileImage,
                        orgId,
                        gender,
                        npiNumber: npi.toString(),
                        specality,
                        taxonomyData,
                        primaryAddress,
                        mailingAddress,
                        countryCode,
                        addressDetails
                    }
                    
                    const {data} = await axiosPost(`/admin/v1/registerProvider`,
                          formData, {
                              headers: {
                                  'Content-Type': 'application/json'
                              }
                          });
                    
                    dispatch({
                        type: PROVIDER_ADD_SUCCESS,
                        payload: data,
                    });
                    return data;
                } catch (error) {
                    error?.response?.status === 401 && dispatch(userLogoutAction())
                    dispatch({
                        type: PROVIDER_ADD_FAIL,
                        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
                    });
                }
            };

//provider list for practitioner-
// export const getProviderListForPractitioner = () => async (dispatch) => {
//     try {
//         dispatch({
//             type: PROVIDER_PATIENT_LIST_REQUEST,
//         });
        
//         const {data} = await axiosGet(`/providers/v1/getProviderList`);
        
//         dispatch({
//             type: PROVIDER_PATIENT_LIST_SUCCESS,
//             payload: data.result,
//         });
//     } catch (error) {
//         error?.response?.status === 401 && dispatch(userLogoutAction())
//         dispatch({
//             type: PROVIDER_PATIENT_LIST_FAIL,
//             payload: error.response && error?.response?.data?.detail,
//         });
//     }
// };