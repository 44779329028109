export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL";

export const FORGET_PASSWORD_OPT_REQUEST = "FORGET_PASSWORD_OPT_REQUEST";
export const FORGET_PASSWORD_OPT_SUCCESS = "FORGET_PASSWORD_OPT_SUCCESS";
export const FORGET_PASSWORD_OPT_FAIL = "FORGET_PASSWORD_OPT_FAIL";

export const FORGET_PASSWORD_CHANGE_REQUEST = "FORGET_PASSWORD_CHANGE_REQUEST";
export const FORGET_PASSWORD_CHANGE_SUCCESS = "FORGET_PASSWORD_CHANGE_SUCCESS";
export const FORGET_PASSWORD_CHANGE_FAIL = "FORGET_PASSWORD_CHANGE_FAIL";

export const UPDATE_PROVIDER_PROFILE_REQUEST = "UPDATE_PROVIDER_PROFILE_REQUEST";
export const UPDATE_PROVIDER_PROFILE_SUCCESS = "UPDATE_PROVIDER_PROFILE_SUCCESS";
export const UPDATE_PROVIDER_PROFILE_FAIL = "UPDATE_PROVIDER_PROFILE_FAIL";

export const GET_ORG_REQUEST = "GET_ORG_REQUEST";
export const GET_ORG_SUCCESS = "GET_ORG_SUCCESS";
export const GET_ORG_FAIL = "GET_ORG_FAIL";

export const SET_FILTER = "SET_FILTER"
export const CLEAR_FILTER = "CLEAR_FILTER"