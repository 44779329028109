import React, { useEffect, useState, Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import {
  getPatientInfo,
  getPatientVitals,
  getPatientCaretaker,
  getPatientMonitoringData,
  getPatientCriticalDevices
} from "../../redux/actions/patientAction"

import { Tabs, Alert, Avatar, Row, Col, Card, Button, Tag } from "antd"

import Loading from "../Loading/Loading"
import DiagnosticCode from "./PatientModal/DiagnosticCode"
import ShowAllPatientAction from "./PatientModal/ShowAllPatientAction"

import defaultPatientImage from "../../assets/patient.png"

import PatientBPComponent from "./PatientBP"
import PatientWeightMachine from "./PatientWeightMachine"
import HandleApiFail from "../layout/APIFail/ApiFail"
import PatientOxymeterComponent from "./PatientOxymeter"
import PatientGlucoseMeterComponent from "./PatientGlucoseMeter"

import moment from "moment"

// import RtmMentalHealth from "../Rtm/RtmMentalHealth"
// import RtmPainManagement from "../Rtm/RtmPainManagement"
// import RtmAddictionMedicine from "../Rtm/RtmAddictionMedicine"

import "./patient.css"
import { getMonitorListTypeAction } from "../../redux/actions/devicesActions"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { prettifyHeight } from "../../utils/heightConversion"

const currentDate = moment.utc().endOf("day").toDate().toISOString()
const durationObj = {
  week: moment.utc(currentDate).subtract(7, "days").startOf("day").toDate().toISOString(),
  month: moment.utc(currentDate).subtract(1, "month").startOf("day").toDate().toISOString(),
  year: moment.utc(currentDate).subtract(1, "year").startOf("day").toDate().toISOString()
}

const getProgramId = (rpmArr, deviceName) =>
  rpmArr
    ?.map(({ programs }) =>
      programs?.find(({ programName }) => programName.toLowerCase() === deviceName)
    )
    ?.filter(program => program)?.[0]?.programId

const PatientDetailInfo = () => {
  const [showAllAction, setShowAllAction] = useState(false)
  const [showDiagnosticCodes, setShowDiagnosticCodes] = useState(false)
  // const [showAddChronicCondition, setShowAddChronicCondition] = useState(false)

  const [showWm, setShowWm] = useState(false)
  const [showBp, setShowBp] = useState(false)
  const [showOxi, setShowOxi] = useState(false)
  const [showGluco, setShowGluco] = useState(false)
  const [bpCustomParameterTab, setBpCustomParameterTab] = useState(false);
  const [oxiCustomParameterTab, setOxiCustomParameterTab] = useState(false);
  const [glucoseCustomParameterTab, setGlucoseCustomParameterTab] = useState(false);
  // const [showPM, setShowPM] = useState(false)
  // const [showMH, setShowMH] = useState(false)
  // const [showAM, setShowAM] = useState(false)
  const [assignedProviders, setAssignedProviders] = useState({
    rpmProviderList: [],
    ccmProviderList: [],
    pcmProviderList: []
  })
  const [assignedPractitioners, setAssignedPractitioners] = useState({
    rpmPractitionerList: [],
    ccmPractitionerList: [],
    pcmPractitionerList: []
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id: patientId } = useParams()
  const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { patientVitals } = useSelector(state => state.patientVitals)
  const { patientCaretaker } = useSelector(state => state.patientCaretaker)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { patientCriticalDevices: criticalDevices, loading: criticalDevicesLoading } = useSelector(
    state => state.patientCriticalDevices
  )
  const monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const handleDiagnosticCodeClose = () => setShowDiagnosticCodes(false)
  const handleDiagnosticCodeShow = () => {
    setShowAllAction(false)
    setShowDiagnosticCodes(true)
  }

  // const handleAddChronicConditionClose = () => setShowAddChronicCondition(false)
  // const handleAddChronicConditionShow = () => {
  //   setShowAllAction(false)
  //   setShowAddChronicCondition(true)
  // }

  const handleShowAllActionClose = () => setShowAllAction(false)
  const handleShowAllActionShow = () => {
    setShowAllAction(true)
  }

  // useEffect(() => {
  //   dispatch(getLatestReadings({ patientId: id }))
  // }, [dispatch, id])

  useEffect(() => {
    if (!monitorTypes?.length) dispatch(getMonitorListTypeAction())
    dispatch(getPatientInfo({ patientId }))
    dispatch(getPatientVitals(patientId))
    dispatch(getPatientCaretaker(patientId))
    dispatch(getPatientMonitoringData({ patientId }))
  }, [dispatch, patientId])

  useEffect(() => {
    const programDevice = monitorTypes?.find(({ name }) => name.toLowerCase() === "rpm")?.programs
    if (patientMonitoringData && patientMonitoringData?.rpm && monitorTypes?.length) {
      patientMonitoringData?.rpm?.map(({ programs }) =>
        programs?.map(({ programId, programName }) => {
          if (
            programDevice?.find(
              ({ id, name }) => id === programId && name.toLowerCase() === programName.toLowerCase()
            )
          ) {
            if (programName.toLowerCase() === "weight machine") setShowWm(true)
            else if (programName.toLowerCase() === "blood pressure machine") setShowBp(true)
            else if (programName.toLowerCase() === "oximeter") setShowOxi(true)
            else if (programName.toLowerCase() === "glucose machine") setShowGluco(true)
          }
        })
      )
    } else {
      setShowWm(false)
      setShowBp(false)
      setShowOxi(false)
      setShowGluco(false)
    }
  }, [patientMonitoringData])

  useEffect(() => {
    if (patientInfoDetail?.critical) {
      dispatch(getPatientCriticalDevices(patientId))
    }
  }, [patientInfoDetail])

  const getCriticalDevices = () => (
    <div>
      <h4 className="mb-3 text-xl"> Patient is in Critical Situation</h4>
      <h6 className="text-lg mb-2">
        Critical Devices: {criticalDevicesLoading && !criticalDevices && "Loading..."}
      </h6>
      {[...new Set(criticalDevices?.map(({ deviceType }) => deviceType))].map((device, index) => (
        <p key={index} className="capitalize mb-2">
          {Number(index) + 1}. {device}
        </p>
      )) || "No Critical Device Found!"}
    </div>
  )

  const patientProfilePicture = patientInfoDetail?.profileImage
    ? `data:image/png;base64,${patientInfoDetail?.profileImage}`
    : defaultPatientImage

  const patientAge = Math.floor(
    (new Date() - new Date(patientInfoDetail?.dob).getTime()) / 3.15576e10
  )

  /*  eslint-disable */

  const heightInInches = patientVitals
    ? [...patientVitals]
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        ?.find(vitalInfo => vitalInfo && vitalInfo?.vitalType === "height")?.value
    : null

  const latestWeightReading = patientVitals
    ? [...patientVitals]
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      ?.find(vitalInfo => vitalInfo && vitalInfo?.vitalType === "weight")
    : null
  
  /*  eslint-enable */

  const items = patientInfoDetail => [
    patientInfoDetail !== undefined &&
    showWm && {
      key: "wm",
      label: "Weight Machine",
      children: (
        <PatientWeightMachine
          patientId={patientId}
          patientVitals={patientVitals}
          currentDate={currentDate}
          durationObj={durationObj}
          programId={getProgramId(patientMonitoringData?.rpm, "weight machine")}
        />
      )
    },
    patientInfoDetail !== undefined &&
    showBp && {
      key: "bp",
      label: `Blood Pressure Machine ${bpCustomParameterTab ? "(Custom Parameter Active)" : ""}`,
      children: (
        <PatientBPComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          programId={getProgramId(patientMonitoringData?.rpm, "blood pressure machine")}
          setBpCustomParameterTab={setBpCustomParameterTab}
        />
      )
    },
    patientInfoDetail !== undefined &&
    showOxi && {
      key: "oxi",
      label: `Oximeter Readings ${oxiCustomParameterTab ? "(Custom Parameter Active)" : ""}`,
      children: (
        <PatientOxymeterComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          programId={getProgramId(patientMonitoringData?.rpm, "oximeter")}
          setOxiCustomParameterTab={setOxiCustomParameterTab}
        />
      )
    },
    patientInfoDetail !== undefined &&
    showGluco && {
      key: "glu",
      label: `Glucose Meter ${glucoseCustomParameterTab ? "(Custom Parameter Active)" : ""}`,
      children: (
        <PatientGlucoseMeterComponent
          patientId={patientId}
          currentDate={currentDate}
          durationObj={durationObj}
          programId={getProgramId(patientMonitoringData?.rpm, "glucose machine")}
          setGlucoseCustomParameterTab={setGlucoseCustomParameterTab}
        />
      )
    }
    // patientInfoDetail !== undefined &&
    //   showPM && {
    //     key: "pm",
    //     label: "Pain Management",
    //     children: <RtmPainManagement patientId={patientId} />
    //   },
    // patientInfoDetail !== undefined &&
    //   showMH && {
    //     key: "mh",
    //     label: "Mental Health",
    //     children: <RtmMentalHealth patientId={patientId} />
    //   },
    // patientInfoDetail !== undefined &&
    //   showAM && {
    //     key: "am",
    //     label: "Addiction Medicine",
    //     children: <RtmAddictionMedicine patientId={patientId} />
    //   }
  ]

  useEffect(() => {
    if (!patientMonitoringData) return
    const rpmProviderList = []
    const ccmProviderList = []
    const pcmProviderList = []
    Object?.keys(patientMonitoringData).forEach(key =>
      patientMonitoringData?.[key]
        ?.filter(({ providerId }) => providerId !== null)
        ?.forEach(program => {
          if (program?.monitoringType?.toLowerCase() === "rpm") {
            program.programs.forEach(
              () =>
                !rpmProviderList?.includes(
                  `${program?.providerfirstName + " " + program?.providerLastName}`
                ) &&
                rpmProviderList.push(
                  `${program?.providerfirstName + " " + program?.providerLastName}`
                )
            )
          } else if (program?.monitoringType?.toLowerCase() === "ccm") {
            ccmProviderList.push(`${program?.providerfirstName + " " + program?.providerLastName}`)
          } else if (program?.monitoringType?.toLowerCase() === "pcm") {
            pcmProviderList.push(`${program?.providerfirstName + " " + program?.providerLastName}`)
          }
        })
    )
    setAssignedProviders({ rpmProviderList, ccmProviderList, pcmProviderList })
  }, [patientMonitoringData])

  useEffect(() => {
    if (!patientMonitoringData) return
    const rpmPractitionerList = []
    const ccmPractitionerList = []
    const pcmPractitionerList = []
    Object?.keys(patientMonitoringData).forEach(key =>
      patientMonitoringData?.[key]
        ?.filter(({ practitionerId }) => practitionerId !== null)
        ?.forEach(program => {
          if (program?.monitoringType?.toLowerCase() === "rpm") {
            program.programs.forEach(
              () =>
                !rpmPractitionerList?.includes(
                  `${program?.practitionerfirstName + " " + program?.practitionerLastName}`
                ) &&
                rpmPractitionerList.push(
                  `${program?.practitionerfirstName + " " + program?.practitionerLastName}`
                )
            )
          } else if (program?.monitoringType?.toLowerCase() === "ccm") {
            ccmPractitionerList.push(`${program?.practitionerfirstName + " " + program?.practitionerLastName}`)
          } else if (program?.monitoringType?.toLowerCase() === "pcm") {
            pcmPractitionerList.push(`${program?.practitionerfirstName + " " + program?.practitionerLastName}`)
          }
        })
    )
    setAssignedPractitioners({ rpmPractitionerList, ccmPractitionerList, pcmPractitionerList })
  }, [patientMonitoringData])

  return patientLoading ? (
    <Loading />
  ) : !patientInfoDetail ? (
    <HandleApiFail route="/patient" />
  ) : (
    <>
      <Row gutter={24}>
        <Col xs={24} lg={16}>
          <Card className="mb-4">
            <div className="flex gap-3 flex-wrap justify-between">
              <h4 className="text-2xl">Profile</h4>
            </div>
            <div className="mt-3 flex gap-3 flex-wrap justify-between items-center">
              <div className="flex items-center gap-3">
                <Avatar
                  className="border-2 border-current p-2"
                  size={72}
                  src={patientProfilePicture}
                />
                <div>
                  <h5 className="text-2xl capitalize">
                    {`${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName
                      }`}
                  </h5>
                  {patientInfoDetail?.email ? (
                    <p className="text-sm text-secondary my-1">{patientInfoDetail?.email}</p>
                  ) : null}

                  {patientInfoDetail?.mobileNumber ? (
                    <p className="text-sm text-secondary">{formatPhoneNumber(patientInfoDetail?.mobileNumber)}</p>
                  ) : null}
                  {patientInfoDetail?.specifiedParameter ? (
                    <Tag bordered={false} color="success" className="mt-1 p-1 text-[12px]">Custom Parameters Active</Tag>
                  ) : null}
                </div>
              </div>
              <div>
                {patientInfoDetail?.userName ? (
                  <div className="flex items-center mb-2">
                    <span className="font-medium min-w-[100px]">UserName : </span>
                    {patientInfoDetail?.userName}
                  </div>
                ) : null}

                {patientVitals ? (
                  <div className="flex items-center">
                    <span className="font-medium min-w-[100px]">Weight: </span>
                    {latestWeightReading ? (
                      <Fragment>{latestWeightReading?.value} LB</Fragment>
                    ) : null}
                  </div>
                ) : null}
                <div className="flex items-center my-2">
                  <span className="font-medium min-w-[100px]">Age: </span>
                  {patientAge} Years Old
                </div>
                {patientVitals && heightInInches ? (
                  <div className="flex items-center capitalize">
                    <span className="font-medium min-w-[100px]">Height: </span>
                    {prettifyHeight(heightInInches)}
                  </div>
                ) : null}

                {patientInfoDetail?.gender ? (
                  <div className="flex items-center mt-2 gap-2 capitalize">
                    <span className="font-medium min-w-[100px]"> Gender at time of birth :</span>{" "}
                    {patientInfoDetail?.gender}
                  </div>
                ) : null}
              </div>
            </div>
            <hr className="my-3" />
            <Row>
              <Col xs={24}>
                <div className="flex gap-3 flex-wrap justify-center">
                  <Button
                    className="mt-2"
                    onClick={() =>
                      navigate(`/ccm-view-care`, {
                        state: { patientInfoDetail, patientMonitoringData }
                      })
                    }>
                    View Care Plan
                  </Button>
                  <Button
                    className="mt-2"
                    onClick={() =>
                      navigate(`/rpm-view-treatement`, {
                        state: { patientInfoDetail, patientMonitoringData, patientCaretaker }
                      })
                    }>
                    View Treatment Plan
                  </Button>
                  <Button
                    className="mt-2"
                    onClick={() =>
                      navigate(`/pcm-view-care`, {
                        state: { patientInfoDetail, patientMonitoringData }
                      })
                    }>
                    View PCM Care Plan
                  </Button>
                  <Button className="mt-2" onClick={handleShowAllActionShow}>
                    Show More
                  </Button>
                </div>
              </Col>
              <Col xs={24}>
                {patientInfoDetail?.critical ? (
                  <Alert type="error" className="mt-4" message={getCriticalDevices()} />
                ) : null}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card className="patient-details-container">
            <div className="flex gap-3 flex-wrap justify-between">
              <div className="flex flex-col mb-2">
                <span className="font-medium min-w-[100px] block">Caretakers: </span>
                {!patientCaretaker || Object.keys(patientCaretaker).length === 0 ? (
                  <span>No Caretaker selected for this patient.</span>
                ) : (
                  <ol className="list-decimal ml-3 mt-2">
                    {patientCaretaker?.map((ct, index) => (
                      <li key={index}>
                        <span className="capitalize">{`${ct?.firstName} ${ct?.middleName ?? ""} ${ct?.lastName
                          }`}</span>
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            </div>
            <div className="flex gap-3 flex-wrap justify-between mt-2">
              <div className="flex flex-col mb-2">
                <span className="font-medium min-w-[100px] block">Monitor Types: </span>
                {!patientMonitoringData || Object.keys(patientMonitoringData).length === 0 ? (
                  <span>No Devices are selected for this patient.</span>
                ) : (
                  <ol className="list-decimal ml-3 mt-2">
                    {Object.keys(patientMonitoringData)?.map((device, index) => {
                      return (
                        <li key={index}>
                          <span className="uppercase">{device}</span>
                        </li>
                      )
                    })}
                  </ol>
                )}
              </div>
              
              <div className="flex flex-col mb-2">
                <span className="font-medium">Providers: </span>
                {assignedProviders?.rpmProviderList?.length === 0 &&
                assignedProviders?.ccmProviderList?.length === 0 &&
                assignedProviders?.pcmProviderList?.length === 0 ? (
                  <span>No Providers are selected for this patient.</span>
                ) : (
                  <div className="capitalize">
                    {assignedProviders?.rpmProviderList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">RPM : </span>
                        <span>{assignedProviders?.rpmProviderList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedProviders?.ccmProviderList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">CCM : </span>
                        <span>{assignedProviders?.ccmProviderList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedProviders?.pcmProviderList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">PCM : </span>
                        <span>{assignedProviders?.pcmProviderList?.join(", ")}</span>
                      </ol>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="flex flex-col mb-2">
                <span className="font-medium">Practitioners: </span>
                {assignedPractitioners?.rpmPractitionerList?.length === 0 &&
                assignedPractitioners?.ccmPractitionerList?.length === 0 && 
                assignedPractitioners?.pcmPractitionerList?.length === 0 ? (
                  <span>No Practitioners are selected for this patient.</span>
                ) : (
                  <div className="capitalize">
                    {assignedPractitioners?.rpmPractitionerList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">RPM : </span>
                        <span>{assignedPractitioners?.rpmPractitionerList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedPractitioners?.ccmPractitionerList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">CCM : </span>
                        <span>{assignedPractitioners?.ccmPractitionerList?.join(", ")}</span>
                      </ol>
                    ) : null}
                    {assignedPractitioners?.pcmPractitionerList?.length ? (
                      <ol className="list-decimal ml-3 mt-2">
                        <span className="font-medium">PCM : </span>
                        <span>{assignedPractitioners?.pcmPractitionerList?.join(", ")}</span>
                      </ol>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Col>
        {/* {showWm || showBp || showOxi || showGluco || showPM || showMH || showAM ? ( */}
        {showWm || showBp || showOxi || showGluco ? (
          <Col xs={24}>
            <Card className="mt-3">
              <Row>
                <Col xs={24}>
                  <Tabs tabPosition="top" className="w-100 mt-4" items={items(patientInfoDetail)} />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}
      </Row>
      <ShowAllPatientAction
        patientInfoDetail={patientInfoDetail}
        showAllAction={showAllAction}
        handleShowAllActionClose={handleShowAllActionClose}
        handleShowAllActionShow={handleShowAllActionShow}
        setShowAllAction={setShowAllAction}
        handleDiagnosticCodeShow={handleDiagnosticCodeShow}
      // handleAddChronicConditionShow={handleAddChronicConditionShow}
      />
      <DiagnosticCode
        handleDiagnosticCodeClose={handleDiagnosticCodeClose}
        showDiagnosticCodes={showDiagnosticCodes}
        patientInfo={patientInfoDetail}
      />
    </>
  )
}

export default PatientDetailInfo
