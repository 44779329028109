import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPractitionerProviderList } from "../../redux/actions/practitionerAction.js"
import { Card, Table, Input, Typography } from "antd"

import { useNavigate } from "react-router-dom"
import Loading from "../Loading/Loading.js"

const { Search } = Input

// column constants
const SNO_TITLE = "S.No."
const SNO_DATA_INDEX = "sNo"

const FULLNAME_TITLE = "Full Name"
const FULLNAME_DATA_INDEX = "fullName"

const SPECIALITY_TITLE = "Speciality"
const SPECIALITY_DATA_INDEX = "speciality"

// general constants
const PRACTITIONER = "practitioner"

const SEARCH_PROVIDER_PLACEHOLDER = "Search Provider..."

const manupulatedData = practitionerProviderList =>
  practitionerProviderList?.map((provider, idx) => ({
    sNo: idx + 1,
    providerId: provider?.id,
    fullName: `${provider?.firstName} ${provider?.middleName ?? ""} ${provider?.lastName}`,
    speciality: provider?.specialization
  }))

const PractitionerProviderList = () => {
  const [data, setData] = useState([])

  const { loading, practitionerProviderList } = useSelector(state => state.practitionerProviderList)
  const { userInfo } = useSelector(state => state.userInfo)

  const isPractitioner = userInfo?.userType?.toLowerCase() === PRACTITIONER

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isPractitioner) return navigate("/")
    dispatch(getPractitionerProviderList())
  }, [])

  useEffect(() => {
    if (!practitionerProviderList?.length) return
    setData(manupulatedData(practitionerProviderList))
  }, [practitionerProviderList])

  const handleSearch = e => {
    const searchQuery = e?.target?.value
    if (searchQuery) {
      const filteredPractitioners = practitionerProviderList?.filter(
        p =>
          `${p?.firstName?.toLowerCase()} ${
            p?.middleName?.toLowerCase() ?? ""
          } ${p?.lastName?.toLowerCase()}`
            .replace("  ", " ")
            .includes(searchQuery?.toLowerCase()) ||
          p?.specialization
            ?.toLowerCase()
            ?.replace(/~/g, ", ")
            ?.includes(searchQuery?.toLowerCase())
      )
      setData(manupulatedData(filteredPractitioners))
    } else {
      setData(manupulatedData(practitionerProviderList))
    }
  }

  const columns = [
    {
      title: SNO_TITLE,
      dataIndex: SNO_DATA_INDEX
    },
    {
      title: FULLNAME_TITLE,
      dataIndex: FULLNAME_DATA_INDEX,
      render: (fullName, { providerId, speciality }) => (
        <a
          className="capitalize truncate"
          onClick={() => navigate(`/provider/${providerId}`, { state: { speciality } })}
          style={{ cursor: "pointer" }}>
          {fullName}
        </a>
      ),
      sorter: (a, b) => a?.fullName.localeCompare(b?.fullName)
    },
    {
      title: SPECIALITY_TITLE,
      dataIndex: SPECIALITY_DATA_INDEX,
      render: speciality => <span className="capitalize">{speciality?.replace(/~/g, ", ")}</span>,
      sorter: (a, b) => a?.speciality.localeCompare(b?.speciality)
    }
  ]

  if (!isPractitioner) return <></>

  return (
    <>
      <Typography.Title level={3}>Providers</Typography.Title>
      <Card>
        {loading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : (
          <>
            <Search
              size="large"
              allowClear
              className="max-w-[300px] mb-4"
              placeholder={SEARCH_PROVIDER_PLACEHOLDER}
              onChange={handleSearch}
            />
            <Table columns={columns} rowKey="sNo" dataSource={data} scroll={{ x: "max-content" }} />
          </>
        )}
      </Card>
    </>
  )
}

export default PractitionerProviderList
