const formatPhoneNumber = (phone) => {

  const currentValue = phone?.replace(/[^\d]/g, '');
  const area = currentValue?.slice(0, 3);
  const first = currentValue?.slice(3, 6);
  const second = currentValue?.slice(6, 10);

  let formattedTelephoneNumber = currentValue;

  if (currentValue?.length >= 1) {
    formattedTelephoneNumber = `(${formattedTelephoneNumber}`;
  }

  if (currentValue?.length >= 4) {
    formattedTelephoneNumber = `(${area}) ${first}`;
  }

  if (currentValue?.length >= 7) {
    formattedTelephoneNumber = `(${area}) ${first}-${second}`;
  }

  return formattedTelephoneNumber;
};

export default formatPhoneNumber;