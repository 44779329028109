import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"

import { Row, Col, Input, Button, Divider, Spin } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { googleLoginAction, validateLoginDetailsAction } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import fitpeoLogo from "../../assets/fitpeo_logo.png"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import LoginComponent from "./Login"
import OTPForm from "../ForgetPassword/OTPForm"
import ChangePasswordForm from "../ForgetPassword/ChangePasswordForm"
import loginImage from "../../assets/Doctors.png"
import { forgetPasswordProviderAction } from "../../redux/actions/utilsActions"
import { Mixpanel } from "../../utils/mixpanel"
import { GoogleLogin } from "@react-oauth/google"


const schema = yup
  .object({
    credential: yup.string().required("Enter a Valid Email or Phone Number")
      .test('value-test', 'Enter a Valid Email or Phone Number',
        function (value) {
          const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        })

  })
  .required()

const defaultValues = {
  credential: ""
}


const LoginHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userName, setUserName] = useState("")
  const [showNext, setShowNext] = useState(false)


  const [otpValue, setOtpValue] = useState("")
  const [showOTPForm, setShowOTPForm] = useState(false)
  const [changePasswordShow, setChangePasswordShow] = useState(false)

  const { loading, error, data } = useSelector(state => state.validateLoginDetails)

  const { loading: googleLoginLoading, error: googleLoginErr } = useSelector(
    state => state.googleLogin
  )

  const { userInfo } = useSelector(state => state.userInfo)
  useEffect(() => {
    if (userInfo) {
      navigate("/", { replace: true })
    }
  }, [userInfo]) //eslint-disable-line

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ credential }) => {
    setUserName(credential)
    let res = await dispatch(validateLoginDetailsAction(credential))
    // res?.details?.forgotOTP === false // removed below from if condition (bug-FIT-5534)
    if (res?.details?.onBoardingOTP === false && res?.details?.setPassword === false) {
      setShowNext(true)
    }
    // if(res?.details?.forgotOTP === true){
    //   navigate('/forgot-password')
    // }
    //if (res?.details?.onBoardingOTP === true || res?.details?.forgotOTP === true) {
      if (res?.details?.onBoardingOTP === true) {
      setShowOTPForm(true)
    }
    if (res?.details?.setPassword === true) {
      // setChangePasswordShow(true)
      if (!otpValue) {
        dispatch(forgetPasswordProviderAction(credential))
        setShowOTPForm(true)
      } else navigate('/forget-password')
    }
  }

  const onSuccessGoogleLogin = ({ credential }) => dispatch(googleLoginAction({ credential }))

  return (
    !userInfo &&
    <>
      {showNext ?
        <LoginComponent userName={userName} />
        :
        <div className="flex h-screen p-4">
          <Row className="items-center justify-center w-full">
            <Col sm={24} className="block lg:hidden">
              <img alt="loginImage" src={loginImage} className="img-fluid float-end login-sm-image" />
            </Col>
            {
              showOTPForm ?
                <Col sm={24} lg={6}>
                  <div className="border-2 border-current rounded p-4">
                    <div className="text-center mb-5">
                      <img
                        alt="logo"
                        width={200}
                        src={fitpeoLogo}
                        className="img-fluid fitpeo_logo_login"
                      />
                    </div>

                    <OTPForm
                      credential={userName}
                      setOtpValue={setOtpValue}
                      setShowNext={setShowOTPForm}
                      setChangePasswordShow={setChangePasswordShow}
                    />
                  </div>
                </Col>
                :
                changePasswordShow ?
                  <Col sm={24} lg={6}>
                    <div className="border-2 border-current rounded p-4">
                      <div className="text-center mb-5">
                        <img
                          alt="logo"
                          width={200}
                          src={fitpeoLogo}
                          className="img-fluid fitpeo_logo_login"
                        />
                      </div>
                      <ChangePasswordForm otp={otpValue} userName={userName} setChangePasswordShow={setChangePasswordShow} />
                    </div>
                  </Col>
                  :
                  <Col sm={24} lg={6}>
                    <form className="p-4 border-2 border-current rounded" onSubmit={handleSubmit(onSubmit)}>
                      <div className="text-center mb-5">
                        <img
                          alt="logo"
                          width={200}
                          src={fitpeoLogo}
                          className="img-fluid fitpeo_logo_login"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="mb-2" htmlFor="input-credential">
                          Email or Phone Number
                        </label>

                        <Controller
                          name="credential"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Input
                              size="large"
                              id="input-credential"
                              status={errors.credential ? "error" : undefined}
                              {...field}
                              onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                            />
                          )}
                        />

                        {errors.credential ? (
                          <small className="text-danger">{errors.credential.message}</small>
                        ) : null}

                      </div>
                      {/* <div className="mb-4">
              <p className="text-center text-secondary" style={{cursor:"pointer"}}
              onClick={()=>{setShowLandline(true); dispatch(clearUserLoginError())}}
              >Login with landline</p>
            </div> */}


                      <div className="mt-5">
                        <Button
                          block
                          size="large"
                          type="primary"
                          htmlType="submit"
                          disabled={Boolean(loading)}
                          onClick={() => {
                            Mixpanel.track("Login Clicked", { Email: userName })
                          }}>
                          {loading ? "Loading..." : "Continue"}
                        </Button>
                      </div>
                      {error !== undefined && typeof error === "string" && (
                        <p className="text-danger text-center font-medium mt-4">{error}</p>
                      )}
                    </form>

                    {error !== undefined &&
                      typeof error === "object" &&
                      Object.values(error).map((value, index) => (
                        <p key={index} className="text-danger text-center">
                          <small>{value}</small>
                        </p>
                      ))}

                    <Divider plain>
                      <span className="text-secondary text-xs">Or log in with</span>
                    </Divider>

                    <Spin spinning={googleLoginLoading === true}>
                      <div className="my-3 grid place-content-center">
                        <GoogleLogin
                          onSuccess={onSuccessGoogleLogin}
                          onError={() => {
                            console.log("Login Failed")
                          }}
                        />
                      </div>
                    </Spin>
                    {googleLoginErr !== undefined && typeof googleLoginErr === "string" && (
                      <p className="mb-2 text-danger text-center font-medium">{googleLoginErr}</p>
                    )}
                  </Col>
            }
            <Col className="hidden lg:block ps-5" lg={12}>
              <img src={loginImage} alt="loginImage" className="img-fluid" />
            </Col>
          </Row>
        </div>
      }
    </>
  )
}

export default LoginHome
