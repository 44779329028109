import { applyMiddleware, combineReducers, createStore } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  ImageReducer, googleLoginReducer, passwordUpdateReducer, userLoginReducer,
  userOtpValidateReducer,
  validateLoginDetailsReducer
} from "./reducers/userReducer"
import {
  criticalPatientReducer,
  dashboardKPIReducer,
  filterDashboardReducer
} from "./reducers/dashboardReducer"
import {
  AddPatientReducer,
  chronicTypeReducer,
  criticalStatusReducer,
  getChronicConditionReducer,
  getManualTimeReducer,
  getPatientReportReducer,
  HealthBpReducer,
  HealthKpiReducer,
  HealthOxyReducer,
  HealthWmReducer,
  HealthGlucoseReducer,
  patientBpReadingReducer,
  PatientInfoReducer,
  patientOxyReadingReducer,
  PatientReadingReducer,
  patientTimelineReducer,
  patientWmReadingReducer,
  postChronicConditionReducer,
  updateLandlineReducer,
  UpdatePatientReducer,
  AddManualTime,
  patientManualLogTrack,
  PatientVitalsReducer,
  patientCaretakerReducer,
  PatientMonitoringReducer,
  PatientCriticalDevicesReducer,
  PatientDiagnosticReducer
} from "./reducers/patientReducer"
import {
  addProviderReducer,
  advancedFiltersOptionsReducer,
  getProviderAddressReducer,
  getProviderNPIReducer,
  getSingleProviderReducer,
  getSpecializationReducer,
  providerFilterPatientReducer,
  providerKPIReducer,
  providerPatientAdvFilterReducer,
  providerPatientReducer,
  providerReducer,
  searchProviderByNPI
} from "./reducers/providerReducer"
import {
  addCaretakerReducer,
  caretakerCardReducer,
  caretakerListReducer,
  caretakerPatientReducer,
  singleCaretakerReducer
} from "./reducers/caretakerReducer"
import { monitorTypeReducer } from "./reducers/devicesReducer"
import {
  emailVerificationReducer,
  getOrgReducer,
  updateProviderProfileReducer
} from "./reducers/utilReducer"
import {
  getCcmReducer,
  postCCMReducer,
  getCcmCarePlanReducer,
  getFacilityAddressReducer,
  getUserSearchReducer,
  getFilterUsersReducer
} from "./reducers/ccmReducer"
import { getRpmCarePlanReducer } from "./reducers/rpmReducer"

import {
  getRtmAddictionMedicine,
  getRtmMentalHealth,
  getRtmPainManagement,
  getSingleRtmAddictionMedicine,
  getSingleRtmMentalHealth,
  getSingleRtmPainManagement
} from "./reducers/rtmReducer"
import {
  getPatientBpCustomParameterReducer,
  postPatientBpCustomParameterReducer,
  updatePatientBpCustomParameterReducer,
  deletePatientBpCustomParameterReducer,
  getPatientOxiCustomParameterReducer,
  postPatientOxiCustomParameterReducer,
  updatePatientOxiCustomParameterReducer,
  deletePatientOxiCustomParameterReducer,
  getPatientGlucoCustomParameterReducer,
  postPatientGlucoCustomParameterReducer,
  updatePatientGlucoCustomParameterReducer,
  deletePatientGlucoCustomParameterReducer
} from "./reducers/customParameterReducer";
import {
  getPractitionerAddressReducer,
  getPractitionerSpecializationReducer,
  practitionerFilterPatientReducer,
  practitionerListReducer,
  practitionerPatientAdvFilterReducer,
  practitionerPatientReducer,
  practitionerProviderListReducer,
  singlePractitionerReducer
} from "./reducers/practitionerReducer"
import { getLatestPcmPlanReducer } from "./reducers/pcmReducer"
import {
  getSelfOnboardingPendingPatientReducer,
  postBulkMailMessagePatientReducer
} from "./reducers/eligiblePatientReducer"

const reducer = combineReducers({
  userInfo: userLoginReducer,
  googleLogin: googleLoginReducer,
  validateLoginDetails: validateLoginDetailsReducer,
  userOtpValidate: userOtpValidateReducer,
  imageUploader: ImageReducer,
  passwordUpdate: passwordUpdateReducer,
  dashboardKPI: dashboardKPIReducer,
  filterdashboardKPI: filterDashboardReducer,
  criticalPatient: criticalPatientReducer,
  manualList: getManualTimeReducer,
  chronicConcern: getChronicConditionReducer,
  criticalStatus: criticalStatusReducer,
  // PATINET_DATA
  patientDiagnosticData: PatientDiagnosticReducer,
  patientCriticalDevices: PatientCriticalDevicesReducer,
  patientMonitoringData: PatientMonitoringReducer,
  patientCaretaker: patientCaretakerReducer,
  patientVitals: PatientVitalsReducer,
  patientManualLogTrack: patientManualLogTrack,
  patientInfo: PatientInfoReducer,
  patientReport: getPatientReportReducer,
  healthKpi: HealthKpiReducer,
  healthWm: HealthWmReducer,
  healthBp: HealthBpReducer,
  healthOxy: HealthOxyReducer,
  healthGlucose: HealthGlucoseReducer,
  patientReading: PatientReadingReducer,
  patientTimeline: patientTimelineReducer,
  weightMachineReading: patientWmReadingReducer,
  oxygenMeterReading: patientOxyReadingReducer,
  bloodPressureReading: patientBpReadingReducer,
  addPatient: AddPatientReducer,
  updatePatient: UpdatePatientReducer,
  chronicDeviceType: chronicTypeReducer,
  chronicConditionStatus: postChronicConditionReducer,
  addManualTime: AddManualTime,
  updateLandline: updateLandlineReducer,
  // PROVIDER_DETAILS
  advancedFiltersOptions: advancedFiltersOptionsReducer,
  specialization: getSpecializationReducer,
  providerList: providerReducer,
  providerKPI: providerKPIReducer,
  providerPatient: providerPatientReducer,
  addProvider: addProviderReducer,
  providerNPI: searchProviderByNPI,
  singleProvider: getSingleProviderReducer,
  providerAddress: getProviderAddressReducer,
  getProviderNPI: getProviderNPIReducer,
  providerFilterPatient: providerFilterPatientReducer,
  providerPatientAdvFilter: providerPatientAdvFilterReducer,
  // PRACTITIONER_DETAILS
  practitionerList: practitionerListReducer,
  singlePractitioner: singlePractitionerReducer,
  practitionerPatient: practitionerPatientReducer,
  practitionerFilterPatient: practitionerFilterPatientReducer,
  practitionerPatientAdvFilter: practitionerPatientAdvFilterReducer,
  practitionerSpecialization: getPractitionerSpecializationReducer,
  practitionerAddress: getPractitionerAddressReducer,
  practitionerProviderList: practitionerProviderListReducer,
  
  // CATETAKER_DATA
  caretakerCard: caretakerCardReducer,
  caretakerList: caretakerListReducer,
  singleCaretaker: singleCaretakerReducer,
  caretakerPatient: caretakerPatientReducer,
  addCaretaker: addCaretakerReducer,
  //devices details
  monitorTypeList: monitorTypeReducer,

  //utils
  emailVerification: emailVerificationReducer,
  updateProviderProfile: updateProviderProfileReducer,
  getOrg: getOrgReducer,
  //CCM
  ccmList: getCcmReducer,
  postCCMData: postCCMReducer,
  ccmPlan: getCcmCarePlanReducer,
  facilityAddress: getFacilityAddressReducer,
  filterSearch: getUserSearchReducer,
  filter: getFilterUsersReducer,
  //rpm
  rpmPatientCarePlan: getRpmCarePlanReducer,
  //pcm
  getLatestPcmCareplan: getLatestPcmPlanReducer,

  //RTM
  getRtmPainManagement: getRtmPainManagement,
  getSingleRtmPainManagement: getSingleRtmPainManagement,
  getRtmMentalHealth: getRtmMentalHealth,
  getSingleRtmMentalHealth: getSingleRtmMentalHealth,
  getRtmAddictionMedicine: getRtmAddictionMedicine,
  getSingleRtmAddictionMedicine: getSingleRtmAddictionMedicine,
  // custom parameter
  getPatientBpCustomParameter: getPatientBpCustomParameterReducer,
  postPatientBpCustomParameter: postPatientBpCustomParameterReducer,
  updatePatientBpCustomParameter: updatePatientBpCustomParameterReducer,
  deletePatientBpCustomParameter: deletePatientBpCustomParameterReducer,
  getPatientOxiCustomParameter: getPatientOxiCustomParameterReducer,
  postPatientOxiCustomParameter: postPatientOxiCustomParameterReducer,
  updatePatientOxiCustomParameter: updatePatientOxiCustomParameterReducer,
  deletePatientOxiCustomParameter: deletePatientOxiCustomParameterReducer,
  getPatientGlucoCustomParameter: getPatientGlucoCustomParameterReducer,
  postPatientGlucoCustomParameter: postPatientGlucoCustomParameterReducer,
  updatePatientGlucoCustomParameter: updatePatientGlucoCustomParameterReducer,
  deletePatientGlucoCustomParameter: deletePatientGlucoCustomParameterReducer,
  // Eligible patients
  selfOnboardingPendingPatient: getSelfOnboardingPendingPatientReducer,
  postBulkMailMessagePatient: postBulkMailMessagePatientReducer,
})

const middleware = [thunk]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)))

export default store
