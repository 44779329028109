import { GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST,
    GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
    POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
    POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
    POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS } from "../types/eligiblePatientConstant"
  
  const initialState = {
    selfOnboardingPendingPatient: {
      selfOnboardingPatient: {},
      loading: false
    },
    postBulkMessage: { loading: false, error: null }
  }
  
  export const getSelfOnboardingPendingPatientReducer = (
    state = initialState.selfOnboardingPendingPatient,
    action
  ) => {
    switch (action.type) {
      case GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST:
        return { loading: true }
      case GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS:
        return { loading: false, selfOnboardingPendingPatient: action.payload }
      case GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const postBulkMailMessagePatientReducer = (state = initialState.postBulkMessage, action) => {
    switch (action.type) {
      case POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST:
        return { loading: true }
      case POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS:
        return { loading: false, error: null }
      case POST_BULK_MAIL_MESSAGE_PATIENT_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  