import React from "react"

const RPMOnlyViewForm = ({ques}) => {
    switch (ques.quesType) {
        case "text":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.datefield ?
                    <input
                    required
                    id={ques.id}
                    type='date'
                    disabled={true}
                    style={{width: "100%", height: "50px"}}
                    value={ques.text}
                />
                :
                    //         <textarea
                    //         id={ques.id}
                    //         ref={handleTextAreaAutoSize}
                    //         disabled={true}
                    //         style={{width: "100%"}}
                    //   >
                    //     {ques.notApplicable ? "" : ques.value ? ques.value : ""}
                    //   </textarea>
                        <p style={{ width: "100%", border: '1px solid grey', padding:'6px', lineBreak:'strict', borderRadius:'6px'}} id={ques.id}>
                        {ques.notApplicable ? "" : ques.value ? ques.value : ""}
                        </p>
                }
                        
                    </div>
            );
        case "single":
            return(
                <div style={{marginTop: "10px"}}>
                    <div className="d-flex align-items-center justify-content-evenly">
                    {ques.questionaryOptions.map((item,i)=>(
                        <div>
                            <input type="radio" id={item.id} name="single-select" disabled={true} checked={item.checked}/>
                        <label className="text-capitalize">{item.name}</label>
                        
                        </div>
                        
                    ))}
                    </div>
                    </div>
            );
            case "bool":
                return(
                    <div style={{marginTop: "10px"}}>
                        <div className="d-flex align-items-center">
                            <div className="d-flex">
                            
                                <input type="radio" id={ques.id}  checked={ques?.questionaryOptions[0] === 'true' ? true : false}
                                disabled={true}/>
                                <label className="mx-2">Yes</label>
                            </div>
                            <div className="d-flex mx-5">
                            
                                <input type="radio" id={ques.id}  checked={ques?.questionaryOptions[0] === 'false' ? true : false}
                                disabled={true}/>
                                <label className="mx-2">No</label>
                            </div>
                        </div>
                        </div>
                );
        case "multi":
            return(
                <div style={{marginTop: "10px"}}>
                    {ques.device === undefined ?
                    <div>
                    {ques.questionaryOptions.map((item,i)=>(
                        <div className="d-flex mb-1">
                        <input type="checkbox" id={item.id} checked={item.checked} disabled={true} />
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        
                        </div>
                        
                    ))}
                    </div>
                    :
                    ques.device === 'blood pressure machine' || ques.device === 'glucose machine'?
                    ques.questionaryOptions.map((item,i)=>(
                        item.name === "Other" ?
                        <div>
                        <div className="d-flex mb-1">
                        <input type="checkbox" id={item.id} disabled={true} checked={item.checked}/>
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        </div>
                        {item.checked === true &&
                        <div>
                        {/* <textarea
                        ref={handleTextAreaAutoSize}  style={{width: "100%"}}
                        disabled={true} 
                        >
                        {item.text}
                        </textarea> */}
                        <p style={{ width: "100%", border: '1px solid grey', padding:'6px', lineBreak:'strict', borderRadius:'6px'}}>
                        {item.text}
                        </p>
                        </div>
                        }
                        
                        </div>
                        :
                        item.name === "None of the above" ?
                        <div>
                        <div className="d-flex mb-1">
                        <input type="checkbox" id={item.id} checked={item.checked} disabled={true} />
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        </div>
                        </div>
                        :
                        <div className="d-flex mb-1">
                        <input type="checkbox" id={item.id} checked={item.checked} disabled={true} />
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        
                        </div>
                        
                    ))
                    :
                    ques.device === 'weight machine' || ques.device === 'oximeter'?
                    ques.questionaryOptions.map((item,i)=>(
                        <div>
                        <div className="d-flex mb-1">
                        <input type="checkbox" id={item.id} checked={item.checked} disabled={true} />
                        <label className="mx-3 text-capitalize">{item.name}</label>
                        </div>
                        {item.checked === true ?
                        item.subQuestion.map((sub_ques,ii)=>(
                            <div className="card shadow rounded p-4 my-4">
                                <h6 className="mb-3">{sub_ques.name}</h6>
                                {sub_ques.questionaryOptions.map((inner_ques,iii)=>(
                                        inner_ques.name === "Other" ?
                                        <div>
                                        <div className="d-flex mb-1">
                                        <input type="checkbox" id={inner_ques.id} disabled={true} checked={inner_ques.checked}/>
                                        <label className="mx-3 text-capitalize">{inner_ques.name}</label>
                                        </div>
                                        {inner_ques.checked === true &&
                                        <div>
                                        {/* <textarea
                                        ref={handleTextAreaAutoSize}  style={{width: "100%"}}
                                        disabled={true} 
                                        >
                                        {inner_ques.text}
                                        </textarea> */}
                                        <p style={{ width: "100%", border: '1px solid grey', padding:'6px', lineBreak:'strict', borderRadius:'6px'}}>
                                        {inner_ques.text}
                                        </p>
                                        </div>
                                        }
                                        </div>
                                        :
                                        inner_ques.name === "None of the above" ?
                                        <div className="d-flex mb-1">
                                        <input type="checkbox" id={inner_ques.id} checked={inner_ques.checked} disabled={true} />
                                        <label className="mx-3 text-capitalize">{inner_ques.name}</label>
                                        </div>
                                        :
                                    <div className="d-flex mb-1">
                                    <input type="checkbox" id={inner_ques.id} checked={inner_ques.checked}
                                    disabled={true} 
                                    />
                                    <label className="mx-3 text-capitalize">{inner_ques.name}</label>
                                    </div>  
                                ))}
                            </div>
                        ))
                    :
                    null
                    }
                        
                        </div>
                    ))
                    :
                    null
                
                }
                    
                    </div>
            );
            case "multi-field":
                return(
                    <div style={{marginTop: "10px"}}>
                        <div>
                        {ques.questionaryOptions.map((item,i)=>(
                            item.field ?
                            <div>
                            <div className="my-3">
                            <label className="text-capitalize">{item.name}</label><br></br>
                            {/* <textarea style={{width: "100%"}} disabled={true} >
                            {item.text}
                            </textarea> */}
                            <p style={{ width: "100%", border: '1px solid grey', padding:'6px', lineBreak:'strict', borderRadius:'6px'}}>
                            {item.text} 
                            </p>
                            </div>
                            </div>
                            :
                            <div>
                            <div className="d-flex mb-1">
                            <input type="checkbox" id={item.id} checked={item.checked} disabled={true} />
                            <label className="mx-3 text-capitalize">{item.name}</label>
                            </div>
                            </div>
                            
                        ))}
                        </div>
                        
                        </div>
                )
        default:
            return(
                <div>
                    Invalid question
                </div>
            )

    }
}

export default RPMOnlyViewForm
