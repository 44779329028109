export const PRACTITIONER_LIST_REQUEST = "PRACTITIONER_LIST_REQUEST"
export const PRACTITIONER_LIST_SUCCESS = "PRACTITIONER_LIST_SUCCESS"
export const PRACTITIONER_LIST_FAIL = "PRACTITIONER_LIST_FAIL"

export const PRACTITIONER_PROVIDER_LIST_REQUEST = "PRACTITIONER_PROVIDER_LIST_REQUEST"
export const PRACTITIONER_PROVIDER_LIST_SUCCESS = "PRACTITIONER_PROVIDER_LIST_SUCCESS"
export const PRACTITIONER_PROVIDER_LIST_FAIL = "PRACTITIONER_PROVIDER_LIST_FAIL"

export const SINGLE_PRACTITIONER_REQUEST = "SINGLE_PRACTITIONER_REQUEST"
export const SINGLE_PRACTITIONER_SUCCESS = "SINGLE_PRACTITIONER_SUCCESS"
export const SINGLE_PRACTITIONER_FAIL = "SINGLE_PRACTITIONER_FAIL"

export const PRACTITIONER_PATIENT_LIST_REQUEST = "PRACTITIONER_PATIENT_LIST_REQUEST"
export const PRACTITIONER_PATIENT_LIST_SUCCESS = "PRACTITIONER_PATIENT_LIST_SUCCESS"
export const PRACTITIONER_PATIENT_LIST_FAIL = "PRACTITIONER_PATIENT_LIST_FAIL"

export const PRACTITIONER_FILTER_PATIENT_REQUEST = "PRACTITIONER_FILTER_PATIENT_REQUEST"
export const PRACTITIONER_FILTER_PATIENT_SUCCESS = "PRACTITIONER_FILTER_PATIENT_SUCCESS"
export const PRACTITIONER_FILTER_PATIENT_FAIL = "PRACTITIONER_FILTER_PATIENT_FAIL"
export const PRACTITIONER_FILTER_PATIENT_RESET = "PRACTITIONER_FILTER_PATIENT_RESET"

export const GET_PRACTITIONER_SPECIALIZATION_REQUEST = "GET_PRACTITIONER_SPECIALIZATION_REQUEST"
export const GET_PRACTITIONER_SPECIALIZATION_SUCCESS = "GET_PRACTITIONER_SPECIALIZATION_SUCCESS"
export const GET_PRACTITIONER_SPECIALIZATION_FAIL = "GET_PRACTITIONER_SPECIALIZATION_FAIL"

export const GET_PRACTITIONER_ADDRESS_REQUEST = "GET_PRACTITIONER_ADDRESS_REQUEST"
export const GET_PRACTITIONER_ADDRESS_SUCCESS = "GET_PRACTITIONER_ADDRESS_SUCCESS"
export const GET_PRACTITIONER_ADDRESS_FAIL = "GET_PRACTITIONER_ADDRESS_FAIL"

export const SET_PRACTITIONER_PATIENT_ADVANCE_FILTER = "SET_PRACTITIONER_PATIENT_ADVANCE_FILTER"
export const RESET_PRACTITIONER_PATIENT_ADVANCE_FILTER = "RESET_PRACTITIONER_PATIENT_ADVANCE_FILTER"
