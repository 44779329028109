import React, { useState, useEffect } from "react"

import { Card, Input, Button } from "antd"
import { useToasts } from "react-toast-notifications"

import { useDispatch } from "react-redux"
import { updateProviderProfileAction } from "../../redux/actions/utilsActions"
// import { getUserProfileAction } from "../../redux/actions/userActions"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Mixpanel } from "../../utils/mixpanel"

const schema = yup
  .object({
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid email").required()
  })
  .required()

const UpdateProviderEmail = ({ singleProvider }) => {
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const {
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: singleProvider?.email || "" }
  })

  useEffect(() => {
    if (singleProvider?.email !== null) {
      setValue("email", singleProvider?.email)
    }
  }, [singleProvider?.email])

  const onSubmit = async ({ email }) => {
    setLoading(true)
    const result = await dispatch(
      updateProviderProfileAction(singleProvider?.id, { ...singleProvider, email })
    )
    setLoading(false)
    if (result?.status === 200) {
      addToast("Email Updated Successfully", {
        appearance: "success",
        autoDismiss: true
      })
      // setTimeout(() => {
      //   dispatch(getUserProfileAction(singleProvider?.id))
      // }, 500)
    } else {
      setError("email", {
        type: "custom",
        message: result?.message ?? "Unable to update Email!"
      })
    }
  }

  return (
    <Card loading={singleProvider === undefined}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="mb-3 text-lg" htmlFor="input-email">
          Update Email
        </label>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              size="large"
              id="input-email"
              status={errors.email ? "error" : undefined}
              {...field}
              onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
            />
          )}
        />
        {errors.email ? <small className="text-danger block">{errors.email.message}</small> : null}
        <Button className="mt-3" type="primary" htmlType="submit" loading={loading}  
         onClick={() => Mixpanel.track("Update Email Clicked")}>
          Update
        </Button>
      </form>
    </Card>
  )
}

export default UpdateProviderEmail
