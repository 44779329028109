import React, { useState, useEffect } from "react"
import "./CCMViewQuestion.css"
import PlanView from "@fitpeo/fit-plan-viewer"
import { useDispatch, useSelector } from "react-redux"

import { useLocation, useNavigate } from "react-router-dom"

import { Button, Typography } from "antd"
import Loading from "../../../../Loading/Loading"
import { getccmCareplanDataActions } from "../../../../../redux/actions/ccmActions"
import { getPatientDiagnosticsData } from "../../../../../redux/actions/patientAction"


function CCMAdminPatient(props) {
  const [callRestApis, setCallRestApis] = useState(false)
  const [provider, setProvider] = useState([])
  const [practitioner, setPractitioner] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const patientInfoDetail = location?.state?.patientInfoDetail
  const patientMonitoringData = location?.state?.patientMonitoringData

  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
  const { loading: ccmLoading, getCcmCarePlan: ccmCarePlan } = useSelector(state => state.ccmPlan)

  useEffect(() => {
    if (!patientInfoDetail || !patientInfoDetail?.id) navigate("/")
    dispatch(getccmCareplanDataActions(patientInfoDetail?.id))
    setCallRestApis(true);
  }, [dispatch, patientInfoDetail])

  useEffect(() => {
    if (ccmCarePlan && callRestApis) {
      if (!ccmCarePlan?.plan?.planIcds) dispatch(getPatientDiagnosticsData(patientInfoDetail?.id))
      setCallRestApis(false)
    }
  }, [ccmCarePlan])

  // It will convert v1 careplan structure to v2 careplan structure
  const convertCareplanToV2 = plan => {
    if (plan?.body) return plan?.body
    else return Object.values(plan)
  }

  useEffect(() => {
    let provider = []
    let practitioner = []

    if (ccmCarePlan?.plan?.providers) provider = ccmCarePlan?.plan?.providers
    else if (patientMonitoringData?.ccm?.[0]) {
      provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
    }
    if (ccmCarePlan?.plan?.practitioners) practitioner = ccmCarePlan?.plan?.practitioners
    else if (patientMonitoringData?.ccm?.[0]) {
      practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
      ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
    }

    if (ccmCarePlan?.plan?.provider) {
      provider = [`${ccmCarePlan?.plan?.provider?.providerFirstName && ccmCarePlan?.plan?.provider?.providerFirstName !== "" ? " " + ccmCarePlan?.plan?.provider?.providerFirstName : ""}
      ${ccmCarePlan?.plan?.provider?.providerMiddleName && ccmCarePlan?.plan?.provider?.providerMiddleName !== "" ? " " + ccmCarePlan?.plan?.provider?.providerMiddleName : ""} 
      ${ccmCarePlan?.plan?.provider?.providerLastName && ccmCarePlan?.plan?.provider?.providerLastName !== "" ? " " + ccmCarePlan?.plan?.provider?.providerLastName : ""}`]
    }

    setProvider(provider)
    setPractitioner(practitioner)
  }, [ccmCarePlan, patientMonitoringData])

  const planIcds = ccmCarePlan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

  return ccmLoading ? (
    <Loading />
  ) : (
    (patientInfoDetail !== undefined && ccmCarePlan && (
      <>
        <div className="d-flex justify-content-end">
          <Button
            type="primary"
            className="btn-sm mx-2"
            onClick={() =>
              navigate("/ccm-view-care-print", {
                state: {
                  type: "ccm",
                  patientInfoDetail: patientInfoDetail,
                  planDate: ccmCarePlan?.planDate,
                  plan: convertCareplanToV2(ccmCarePlan?.plan),
                  planIcds: planIcds,
                  provider: provider,
                  practitioner: practitioner,
                  addedBy: ccmCarePlan?.plan?.addedBy
                }
              })
            }>
            Print
          </Button>
        </div >
        <PlanView
          type="ccm"
          patientInfoDetail={patientInfoDetail}
          planDate={ccmCarePlan?.planDate}
          plan={convertCareplanToV2(ccmCarePlan?.plan)}
          planIcds={planIcds}
          providers={provider}
          practitioners={practitioner}
          addedBy={ccmCarePlan?.plan?.addedBy}
          isDarkMode={false}
        />
      </>
    )) || (
      <Typography.Title level={4} className="text-center">
        Care Plan has not been taken
      </Typography.Title>
    )
  )
}

export default CCMAdminPatient
