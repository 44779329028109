import {
    GET_RPM_CAREPLAN_REQUEST,
    GET_RPM_CAREPLAN_SUCCESS,
    GET_RPM_CAREPLAN_FAIL,
} from "../types/rpmConstants";
import { axiosGet, axiosPost } from "../axios";
import { userLogoutAction } from './userActions';



export const getrpmCareplanDataActions = (patientId) => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_CAREPLAN_REQUEST
    })

    const { data } = await axiosGet(`/api/rpm/readings/treatmentplan/latest/${patientId}/fetch`)

    dispatch({
      type: GET_RPM_CAREPLAN_SUCCESS,
      payload: data?.details
    })
    return data?.details
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_CAREPLAN_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}
