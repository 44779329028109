import React, { useEffect } from "react"

import { Card, Input, Row, Col} from "antd"

import { useDispatch, useSelector } from "react-redux"

// import { useToasts } from "react-toast-notifications"
// import imageCompression from "browser-image-compression"

// import { base64ImageConvertor } from "../layout/utils/imageConvertor"

// import { UpdateProfileAction } from "../../redux/actions/userActions"
import { getOrgAction } from "../../redux/actions/utilsActions"
import formatPhoneNumber from "../../utils/formatPhoneNumber"

const Profile = () => {
  const dispatch = useDispatch()
  // const { addToast } = useToasts()

  const { userInfo } = useSelector(state => state.userInfo)
  const { org } = useSelector(state => state.getOrg)

  const orgData = JSON.parse(localStorage.getItem("orgData"))
  

  // const [profilePic, setProfilePic] = useState(
  //   userInfo?.profileImage
  //     ? `data:image/png;base64,${userInfo?.profileImage}`
  //     : "/assets/fitpeo_logo.png"
  // )

  useEffect(()=>{
    if(!userInfo) return
    if(!orgData) dispatch(getOrgAction(userInfo?.orgId))
  },[userInfo, orgData])

  // const handleFileChange = async e => {
  //   const file = e.target.files[0]
  //   if (file !== undefined) {
  //     const options = {
  //       maxSizeMB: 1,
  //       useWebWorker: true,
  //       maxWidthOrHeight: 1920
  //     }
  //     try {
  //       const compressedFile = await imageCompression(file, options)
  //       const base64Image = await base64ImageConvertor(compressedFile)
  //       const profileImage = await base64Image.replace("data:image/png;base64,", "")
  //       setProfilePic(profileImage)
  //       dispatch(UpdateProfileAction(profileImage))
  //     } catch (error) {
  //       addToast("we are unable to process at this moment.", {
  //         appearance: "error",
  //         autoDismiss: true
  //       })
  //     }
  //   }
  // }

  return (
    <Card title="">
      <div className="flex justify-content-between align-items-center mb-4">
        <div>
          <h2 className="text-2xl">Profile</h2>
        </div>
        <div>
          <p className="text-secondary">Version : 2.4.3</p>
        </div>
      </div>
      {/* <div className="mt-5 flex flex-wrap items-center gap-4">
        <Avatar
          size={100}
          className="border-2 border-current"
          src={<img alt="user profile" src={profilePic} />}
        />
        <div>
          <input
            type="file"
            className="form-control"
            accept=".jpg,.jpeg,.png"
            onChange={handleFileChange}
          />
          <p className="text-secondary mb-0 mt-2">Only JPEG & PNG are allowed.</p>
        </div>
      </div>
      <hr className="my-5" /> */}
      <Row gutter={16}>
        <Col sm={24} lg={8} className="mb-4 w-full">
          <label htmlFor="firstName" className="mb-2">
            First Name
          </label>
          <Input readOnly size="large" id="firstName" value={userInfo.firstName} className="capitalize"/>
        </Col>
        <Col sm={24} lg={8} className="mb-4 w-full">
          <label htmlFor="lastName" className="mb-2">
            Last Name
          </label>
          <Input readOnly size="large" id="lastName" value={userInfo.lastName} className="capitalize"/>
        </Col>
        <Col sm={24} lg={8} className="mb-4 w-full">
          <label htmlFor="mobileNumber" className="mb-2">
            Mobile Number
          </label>
          <Input readOnly size="large" id="mobileNumber" value={formatPhoneNumber(userInfo?.mobileNumber)} />
        </Col>
        <Col sm={24} lg={8} className="mb-4">
          <label htmlFor="email" className="mb-2">
            Email
          </label>
          <Input readOnly size="large" id="email" value={userInfo.email} />
        </Col>
        <Col sm={24} lg={8} className="mb-4">
          <label htmlFor="organization" className="mb-2">
            Organization
          </label>
          <Input readOnly size="large" id="organization" value={orgData?.organizationName || org?.organizationName} className="capitalize"/>
        </Col>
      </Row>
    </Card>
  )
}

export default Profile
