import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Modal, Button, Switch } from "antd"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { criticalStatusAction, getPatientInfo } from "../../../redux/actions/patientAction"

const ShowAllPatientAction = ({
  patientDetails,
  showAllAction,
  handleShowAllActionClose,
  setShowAllAction,
  handleDiagnosticCodeShow
}) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { userInfo: patientInfo } = useSelector(state => state.userInfo)
  const [input, setInput] = useState(
    patientInfo?.criticalStatus === null ? false : patientInfo?.criticalStatus?.status
  )

  const { loading, error } = useSelector(state => state.criticalStatus)

  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const handleDCShow = () => {
    handleDiagnosticCodeShow()
    handleShowAllActionClose()
  }

  const handlePatientCriticalStatus = async (device, v1) => {
    // e.preventDefault();
    const result = await dispatch(criticalStatusAction(input, id, device, v1 && v1))
    if (result?.success) {
      addToast("Critical status of patient updated successfully.", {
        appearance: "success",
        autoDismiss: true
      })
      navigate(`/patient/${id}`)
      // dispatch(getPatientInfo({patientId: patientDetails?.userId}));
    } else {
      addToast("Unable to update critical status of patient.", {
        appearance: "error",
        autoDismiss: true
      })
    }
    // setShowAllAction(false);
  }

  return (
    <>
      <Modal
        footer={null}
        open={showAllAction}
        onCancel={handleShowAllActionClose}
        title={<h4 className="text-2xl">More Actions</h4>}>
        <div className="pt-4">
          <div className="flex flex-wrap items-center gap-4 justify-center">
            <div className="border-2 border-current text-center more-details-card mb-4 rounded p-4">
              <div
                className="mx-auto patient-header-button-show cursor-pointer"
                onClick={handleDCShow}>
                Show Diagnostic Codes
              </div>
            </div>
            {/* <div className="border-2 border-current text-center more-details-card mb-4 rounded p-4">
              <Switch
                checked={input}
                checkedChildren="Critical"
                unCheckedChildren={<span className="text-black">Not Critical</span>}
                onChange={() => {
                  setInput(!input)
                  !input && setShowAllAction(true)
                }}
              />
              <h6 className="my-3">Critical Status</h6>
              <Button
                type="primary"
                disabled={input}
                onClick={() => handlePatientCriticalStatus("dummy", true)}>
                Save
              </Button>
            </div> */}
          </div>
          {error !== undefined && typeof error === "string" && (
            <p className="text-danger text-center">
              <p className="font-medium">{error}</p>
            </p>
          )}
          {error !== undefined &&
            typeof error === "object" &&
            error.map(value => (
              <p className="text-danger text-center">
                <small>
                  {`${value?.loc?.[1]["0"]?.toUpperCase() + value?.loc?.[1]?.slice(1)} ${
                    value?.msg
                  }`}
                </small>
              </p>
            ))}
        </div>
      </Modal>
    </>
  )
}

export default ShowAllPatientAction
