export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST"
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS"
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL"
export const GOOGLE_LOGIN_RESET = "GOOGLE_LOGIN_RESET"

export const USER_OTP_REQUEST = "USER_OTP_REQUEST";
export const USER_OTP_SUCCESS = "USER_OTP_SUCCESS";
export const USER_OTP_FAIL = "USER_OTP_FAIL";

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

//profile pic upload
export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";
export const VALIDATE_LOGIN_DETAILS_REQUEST = "VALIDATE_LOGIN_DETAILS_REQUEST"
export const VALIDATE_LOGIN_DETAILS_SUCCESS = "VALIDATE_LOGIN_DETAILS_SUCCESS"
export const VALIDATE_LOGIN_DETAILS_FAIL = "VALIDATE_LOGIN_DETAILS_FAIL"
//profile pic upload
export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAIL = "IMAGE_UPLOAD_FAIL";

//profile pic upload
export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";
