import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
// import AddPatientModal from "../../Dashboard/AddPatient";
import "./filters.css";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { getMonitorListTypeAction } from '../../../redux/actions/devicesActions';
import { getCaretakerListAction } from '../../../redux/actions/caretakerActions';
import { getProviderList } from '../../../redux/actions/providerAction';
import { useDispatch } from 'react-redux';

export const sortBy = Object.freeze({
    AtoZ: 0,
    ZtoA: 1,
    DailyReadingCount: 2,
    LastActiveCount: 3,
    CriticalSortCount: 4,
});

const FiltersComponents = ({
    pageSize,
    setPageSize,
    onAscSort,
    onDecSort,
    setCurrentPage,
}) => {
    const dispatch = useDispatch()
    const [addPatientShow, setAddPatientShow] = useState(false);
    const handleAddPatientClose = () => setAddPatientShow(false);
    const handleAddPatientShow = () => {
        dispatch(getMonitorListTypeAction());
        dispatch(getCaretakerListAction());
        dispatch(getProviderList());
        setAddPatientShow(true)
    };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end">
        <Button variant="contained" startIcon={<Add />}
                className="text-nowrap"
                onClick={handleAddPatientShow}
        >
          Add Patient
        </Button>

        {/* ROW_DROPDOWN */}
        {/*<div className="fs-5 text-nowrap">
          Patients <span className="fs-6">(per page)</span> :
        </div>*/}
        <Dropdown className="me-3">
          <Dropdown.Toggle className="row-dropdown-value" id="dropdown-basic">
            {pageSize}
          </Dropdown.Toggle>

                    <Dropdown.Menu className="row-dropdown-menu">
                        <Dropdown.Item
                            onClick={() => {
                                setPageSize(10);
                                setCurrentPage(1);
                            }}>
                            10
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                setPageSize(20);
                                setCurrentPage(1);
                            }}>
                            20
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                setPageSize(50);
                                setCurrentPage(1);
                            }}>
                            50
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                setPageSize(100);
                                setCurrentPage(1);
                            }}>
                            100
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

        {/* FILTER_DROPDOWN */}
        <Dropdown className="me-3" align="start">
          <Dropdown.Toggle
            className="filter-dropdown-value"
            id="dropdown-basic"
          >
            <img src="/assets/Filter.svg" alt="filterIcon" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="filter-dropdown-menu patient">
            <div className="fs-6 ps-4 border-bottom border-dark pb-2">
              Sort Patient By
            </div>
            {/*<Dropdown.Item>
              <div onClick={() => onDefaultSort('', '')}>
                <Form.Check.Label className='ms-2'>Default</Form.Check.Label>
              </div>
            </Dropdown.Item>*/}
            <Dropdown.Item>
              <div onClick={() => onAscSort(sortBy.AtoZ, 1)}>
                <Form.Check.Label className="ms-2">
                  Sort by Name - Asc. Order
                </Form.Check.Label>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div onClick={() => onDecSort(sortBy.ZtoA, 1)}>
                <Form.Check.Label className="ms-2">
                 Sort by Name - Desc. Order
                </Form.Check.Label>
              </div>
            </Dropdown.Item>
            {/*<Dropdown.Item>
              <div onClick={() => onRecentSort(3, 1)}>
                <Form.Check.Label className="ms-2">
                  Sort by Last Active
                </Form.Check.Label>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div onClick={() => onCriticalSort(4, 1)}>
                <Form.Check.Label className="ms-2">
                  Sort by Chronic Issues
                </Form.Check.Label>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div onClick={() => onReadingSort(2, 1)}>
                <Form.Check.Label className="ms-2">
                  Sort by Reading Count
                </Form.Check.Label>
              </div>
            </Dropdown.Item>*/}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* <AddPatientModal
        addPatientShow={addPatientShow}
        setAddPatientShow={setAddPatientShow}
        handleAddPatientShow={handleAddPatientShow}
        handleAddPatientClose={handleAddPatientClose}
      /> */}
    </>
  );
};

export default FiltersComponents;
