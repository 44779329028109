import React, { useEffect, useState } from "react"
// import { useNavigate } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { getProviderList, getProviderPatientList } from "../../redux/actions/providerAction"
import { getFilterUsersAction, resetFilterUsersAction } from "../../redux/actions/ccmActions"

import Loading from "../Loading/Loading"
import ProviderModal from "./ProviderModal"
import { sortBy } from "../layout/Filters/Filters"
import ProviderPatientTable from "./ProviderPatientTable"
import ProviderDetailHeader from "./ProviderDetailHeader"
import ProviderPatientFilter from "./ProviderPatientFilter"
import moment from "moment"
import { Card, Tabs } from "antd"
import EligiblePatient from "../Patient/EligiblePatient/Index"

const ProviderDetailComponent = () => {
  const [sortColumn, setSortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { loading, userInfo } = useSelector(state => state.userInfo)
  const { loading: providerPatientLoading, providerPatient } = useSelector(
    state => state.providerPatient
  )
  const [showPotientialPatient, setShowPotientialPatient] = useState("activePatient")
  const [providerValue, setProviderValue] = useState("")
  const [showProviderSelectModal, setShowProviderSelectModal] = useState(false)
  const [tempLoading, setTempLoading] = useState(false) // eslint-disable-line
  const dispatch = useDispatch()
  const [allPatients, setAllPatients] = useState(null)
  const [sortedData, setSortedData] = useState([])

  // let navigate = useNavigate()

  // useEffect(() => {
  //   if (userInfo?.userType === "practitioner") {
  //     let getId = JSON.parse(localStorage.getItem("PractitionerProviderId"))
  //     // console.log('gi',getId)
  //     if (getId !== null) {
  //       dispatch(getProviderPatientList(getId?.providerId))
  //       setProviderValue(getId?.providerDetail)
  //     } else {
  //       setShowProviderSelectModal(true)
  //       dispatch(getProviderList())
  //     }
  //   } else {
  //    dispatch(getProviderPatientList())
  //   }
  // }, [userInfo])

  useEffect(() => {
    if (!userInfo?.id) return
    dispatch(getProviderPatientList(userInfo?.id, userInfo?.userType))
  }, [userInfo?.id])

  useEffect(() => {
    if (!providerPatient) return
    const patients = manupulatePatientData(providerPatient)
    setAllPatients(patients)
    setSortedData(patients)
  }, [providerPatient])

  const manupulatePatientData = patients => {
    const allPatients = patients?.map(patient => {
      if (patient?.manualLog?.length) {
        let ccmLogCount = 0
        let rpmLogCount = 0
        let manualLogCount = 0
        if (
          moment(patient?.manualLogActiveTime).month() === moment().month() &&
          moment(patient?.manualLogActiveTime).year() === moment().year()
        ) {
          const uniqueSet = new Set(patient.manualLog.map(JSON.stringify))
          Array.from(uniqueSet).forEach(manual => {
            manual = JSON.parse(manual)
            if (
              manual?.careType === "ccm" &&
              moment(manual?.careDate).month() === moment().month() &&
              moment(manual?.careDate).year() === moment().year()
            ) {
              ccmLogCount += manual?.count
            } else if (
              manual?.careType === "rpm" &&
              moment(manual?.careDate).month() === moment().month() &&
              moment(manual?.careDate).year() === moment().year()
            ) {
              rpmLogCount += manual?.count
            }
          })
          patient = { ...patient, rpmManualLogCount: rpmLogCount, ccmManualLogCount: ccmLogCount }
        } else {
          patient = {
            ...patient,
            rpmManualLogCount: rpmLogCount,
            ccmManualLogCount: ccmLogCount,
            manualLogCount: manualLogCount
          }
        }
      }
      if (
        patient?.rpmActiveDate?.length !== 0 &&
        moment(patient?.rpmActiveDate).month() === moment().month() &&
        moment(patient?.rpmActiveDate).year() === moment().year()
      ) {
        return { ...patient, monthlyLastestTotalReadingRPM: patient?.rpmReadingCount }
      } else {
        return { ...patient, monthlyLastestTotalReadingRPM: 0 }
      }
    })
    let sortedPatients = []
    if (sortColumn.path === sortBy.AtoZ) {
      sortedPatients = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    if (sortColumn.path === sortBy.ZtoA) {
      sortedPatients = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }

    return sortedPatients
  }

  const handleShowProviderModal = () => {
    setShowProviderSelectModal(true)
    dispatch(getProviderList())
  }

  const applyFilters = async (type, data) => {
    setCurrentPage(1)
    setTempLoading(true)
    if (type === "normal" && data?.facilityId) {
      const patients = manupulatePatientData(await dispatch(getFilterUsersAction(data)))
      setAllPatients(patients)
    } else if (type === "normal" && !data?.facilityId) {
      const userTypePatients = manupulatePatientData(
        providerPatient?.filter(patient => patient?.userType === data?.userType)
      )
      setAllPatients(userTypePatients)
    } else {
      getOnBoardingPatients()
    }
    setTempLoading(false)
  }

  const getOnBoardingPatients = async () => {
    await dispatch(resetFilterUsersAction()).then(() => {
      // dispatch(getOnboardingPatientAction(userInfo?.userInfo?.orgId))
    })
  }

  const resetFilters = async cb => {
    // await dispatch(resetFilterUsersAction())
    const patients = manupulatePatientData(providerPatient)
    setAllPatients(patients)
    if (cb) {
      cb()
    }
  }

  const onPatientTabChange = key => {
    setShowPotientialPatient(key)
  }

  const patientTabItems = [
    {
      key: "activePatient",
      label: `Patients`
    },
    {
      key: "eligiblePatient",
      label: `Eligible Patients`
    }
  ]

  return (
    <div>
      {loading && userInfo !== undefined ? (
        <Loading />
      ) : (
        <>
          <ProviderDetailHeader
            singleProvider={userInfo}
            providerValue={providerValue}
            handleShowProviderModal={handleShowProviderModal}
          />
          {!showProviderSelectModal && (
            <>
              <h3 className="text-2xl bold mb-2"> Patients</h3>
              <Tabs
                defaultActiveKey="activePatient"
                items={patientTabItems}
                onChange={onPatientTabChange}
                className="mb-3"
              />
              {showPotientialPatient === "activePatient" ? (
                <Card>
                  <ProviderPatientFilter
                    applyFilters={applyFilters}
                    resetFilters={resetFilters}
                    allPatients={allPatients}
                    setAllPatients={setAllPatients}
                    sortedData={sortedData}
                    setSortedData={setSortedData}
                    setTempLoading={setTempLoading}
                  />
                  <ProviderPatientTable
                    loading={providerPatientLoading || tempLoading}
                    sortColumn={sortColumn}
                    pageSize={pageSize}
                    sortedData={sortedData}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    providerPatient={providerPatient}
                  />
                </Card>
              ) : (
                <EligiblePatient />
              )}
            </>
          )}
          {showProviderSelectModal && (
            <ProviderModal
              providerValue={providerValue}
              setProviderValue={setProviderValue}
              showProviderSelectModal={showProviderSelectModal}
              setShowProviderSelectModal={setShowProviderSelectModal}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ProviderDetailComponent
