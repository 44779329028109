import { GET_MONITOR_TYPE_LIST_FAIL, GET_MONITOR_TYPE_LIST_REQUEST, GET_MONITOR_TYPE_LIST_SUCCESS } from "../types/devicesConstant";

export const monitorTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_MONITOR_TYPE_LIST_REQUEST:
            return { loading: true };
        case GET_MONITOR_TYPE_LIST_SUCCESS:
            return { loading: false, monitorTypeList: action.payload };
        case GET_MONITOR_TYPE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
