
import { Button, Col, Input, InputNumber, Modal, Row, Slider } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  postPatientBpCustomParameter,
  deletePatientBpCustomParameter,
  getPatientBpCustomParameter,
  updatePatientBpCustomParameter,
  resetPatientBpCustomParameter
} from "../../redux/actions/customParameterActions"
import { useToasts } from "react-toast-notifications"
import { getPatientInfo } from "../../redux/actions/patientAction"
import { ExclamationCircleFilled } from "@ant-design/icons"

const NORMAL_SYS_MSG = "Systolic normal range should be greater than or equal to 41 mmHg."
const STAGE1_SYS_MSG = "Systolic stage 1 hypertension range should be less than or equal to 199 mmHg."
const NORMAL_DIA_MSG = "Diastolic normal range should be greater than or equal to 40 mmHg."
const STAGE1_DIA_MSG = "Diastolic stage 1 hypertension range should be less than or equal to 100 mmHg."
const NORMAL_PULSE_MSG = "Normal pulse range should be greater than or equal to 40 BPM."
const HIGH_PULSE_MSG = "Normal pulse range should be less than or equal to 110 BPM."

const PatientBpCustomParameter = ({
  patientId,
  setShowCustomParameter,
  isEdit,
  setIsEdit,
  setIsCustomParameter,
  setBpCustomParameterTab
}) => {
  const [sysValues, setSysValues] = useState([10, 90, 121, 141, 161, 300])
  const [tempDefaultSysValues, setTempDefaultSysValues] = useState([10, 90, 121, 141, 161, 300])
  const [marksSys, setMarksSys] = useState({})
  const [diaValues, setDiaValues] = useState([10, 60, 81, 91, 100, 150])
  const [tempDefaultDiaValues, setTempDefaultDiaValues] = useState([10, 60, 81, 91, 100, 150])
  const [marksDia, setMarksDia] = useState({})
  const [pulseValues, setPulseValues] = useState([10, 60, 100, 250])
  const [tempDefaultPulseValues, setTempDefaultPulseValues] = useState([10, 60, 100, 250])
  const [marksPulse, setMarksPulse] = useState({})
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [disabledSys, setDisabledSys] = useState(true);
  const [disabledDia, setDisabledDia] = useState(true);
  const [disabledPulse, setDisabledPulse] = useState(true);
  const [sysCustomError, setSysCustomError] = useState("")
  const [diaCustomError, setDiaCustomError] = useState("")
  const [pulseCustomError, setPulseCustomError] = useState("")

  const [sysStageWidth, setSysStageWidth] = useState({
    low: 20,
    normal: 20,
    preHypertension: 20,
    stage1Hypertension: 20,
    stage2Hypertension: 20,
  });
  const [diaStageWidth, setDiaStageWidth] = useState({
    low: 20,
    normal: 20,
    preHypertension: 20,
    stage1Hypertension: 20,
    stage2Hypertension: 20,
  });
  const [pulseStageWidth, setPulseStageWidth] = useState({
    low: 20,
    normal: 20,
    high: 20,
  });

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { loading: BpCustomParameterLoading, bpCustomParameters } = useSelector(state => state.getPatientBpCustomParameter)
  const { loading: postBpCustomParameterLoading } = useSelector(state => state.postPatientBpCustomParameter)
  const { loading: updateBpCustomParameterLoading } = useSelector(state => state.updatePatientBpCustomParameter)
  const { loading: deleteBpCustomParameterLoading } = useSelector(state => state.deletePatientBpCustomParameter)

  function calculatePercentage(start, end, minValue, maxValue) {
    const range = maxValue - minValue;
    const percentage = ((end - start) / range) * 100;
    return parseInt(percentage)
  }

  const handleSubmit = async () => {
    const formData = {
      "higherLimitSystolic": sysValues[sysValues.length - 1],
      "lowerLimitSystolic": sysValues[0],
      "higherLimitDiastolic": diaValues[diaValues.length - 1],
      "lowerLimitDiastolic": diaValues[0],
      "normalSystolic": sysValues[1],
      "preHyperTensionSystolic": sysValues[2],
      "stageOneHyperTensionSystolic": sysValues[3],
      "stageTwoHyperTensionSystolic": sysValues[4],
      "normalDiastolic": diaValues[1],
      "preHyperTensionDiastolic": diaValues[2],
      "stageOneHyperTensionDiastolic": diaValues[3],
      "stageTwoHyperTensionDiastolic": diaValues[4],
      "bpmLow": pulseValues[0],
      "bpmNormalLow": pulseValues[1],
      "bpmNormalHigh": pulseValues[2],
      "bpmHigh": pulseValues[3]
    }
    let result
    if (isEdit) {
      result = await dispatch(updatePatientBpCustomParameter(formData, patientId))
    } else {
      result = await dispatch(postPatientBpCustomParameter(formData, patientId))
    }
    if (result?.message.toLowerCase() === "success") {
      addToast(`BP custom parameters ${isEdit ? "updated" : "added"} successfully`, {
        appearance: "success",
        autoDismiss: true
      })
      dispatch(getPatientInfo({ patientId }))
      setShowSaveModal(false);
      setIsCustomParameter(true);
      setBpCustomParameterTab(true);
      setShowCustomParameter(false);
    } else {
      addToast(`Unable to ${isEdit ? "update" : "add"} BP custom parameters`, {
        appearance: "error",
        autoDismiss: true
      })
      setShowSaveModal(false);
    }
  }

  useEffect(() => {
    if (!bpCustomParameters) {
      dispatch(getPatientBpCustomParameter(patientId))
    }
  }, [])

  useEffect(() => {
    if (isEdit) {
      if (bpCustomParameters?.hasOwnProperty("id") && bpCustomParameters?.hasOwnProperty("lowerLimitSystolic")) {
        let customSysValueArr = [];
        let customDiaValueArr = [];
        let customPulseValueArr = [];
        const sysValuesOrder = ["lowerLimitSystolic", "normalSystolic", "preHyperTensionSystolic", "stageOneHyperTensionSystolic", "stageTwoHyperTensionSystolic", "higherLimitSystolic"]
        const diaValuesOrder = ["lowerLimitDiastolic", "normalDiastolic", "preHyperTensionDiastolic", "stageOneHyperTensionDiastolic", "stageTwoHyperTensionDiastolic", "higherLimitDiastolic"]
        const pulseValueOrder = ["bpmLow", "bpmNormalLow", "bpmNormalHigh", "bpmHigh"]
        for (let i = 0; i < sysValuesOrder.length; i++) {
          customSysValueArr.push(bpCustomParameters && bpCustomParameters[sysValuesOrder[i]])
        }
        setSysValues(customSysValueArr);
        setTempDefaultSysValues(customSysValueArr);
        for (let j = 0; j < diaValuesOrder.length; j++) {
          customDiaValueArr.push(bpCustomParameters && bpCustomParameters[diaValuesOrder[j]])
        }
        setDiaValues(customDiaValueArr)
        setTempDefaultDiaValues(customDiaValueArr)
        for (let k = 0; k < pulseValueOrder.length; k++) {
          customPulseValueArr.push(bpCustomParameters && bpCustomParameters[pulseValueOrder[k]])
        }
        setPulseValues(customPulseValueArr);
        setTempDefaultPulseValues(customPulseValueArr)
      }
    }

  }, [bpCustomParameters])

  useEffect(() => {
    const firstToSecondPoint = calculatePercentage(sysValues[0], sysValues[1], sysValues[0], sysValues[sysValues.length - 1]);
    const secondToThirdPoint = calculatePercentage(sysValues[1], sysValues[2], sysValues[0], sysValues[sysValues.length - 1]);
    const thirdToFourthPoint = calculatePercentage(sysValues[2], sysValues[3], sysValues[0], sysValues[sysValues.length - 1]);
    const fourthToFifthPoint = calculatePercentage(sysValues[3], sysValues[4], sysValues[0], sysValues[sysValues.length - 1]);
    const fifthToEndPoint = calculatePercentage(sysValues[4], sysValues[sysValues.length - 1], sysValues[0], sysValues[sysValues.length - 1]);

    setSysStageWidth({
      ...sysStageWidth,
      low: firstToSecondPoint,
      normal: secondToThirdPoint,
      preHypertension: thirdToFourthPoint,
      stage1Hypertension: fourthToFifthPoint,
      stage2Hypertension: fifthToEndPoint,
    })
  }, [sysValues])

  useEffect(() => {
    let tempsysValues = [...sysValues]
    if (tempsysValues[1] < 41) {
      setSysCustomError(NORMAL_SYS_MSG)
    } else if (tempsysValues[4] > 199) {
      setSysCustomError(STAGE1_SYS_MSG)
    } else {
      setSysCustomError("")
    }
  }, [sysValues])

  useEffect(() => {
    const firstToSecondPoint = calculatePercentage(diaValues[0], diaValues[1], diaValues[0], diaValues[diaValues.length - 1]);
    const secondToThirdPoint = calculatePercentage(diaValues[1], diaValues[2], diaValues[0], diaValues[diaValues.length - 1]);
    const thirdToFourthPoint = calculatePercentage(diaValues[2], diaValues[3], diaValues[0], diaValues[diaValues.length - 1]);
    const fourthToFifthPoint = calculatePercentage(diaValues[3], diaValues[4], diaValues[0], diaValues[diaValues.length - 1]);
    const fifthToEndPoint = calculatePercentage(diaValues[4], diaValues[diaValues.length - 1], diaValues[0], diaValues[diaValues.length - 1]);

    setDiaStageWidth({
      ...diaStageWidth,
      low: firstToSecondPoint,
      normal: secondToThirdPoint,
      preHypertension: thirdToFourthPoint,
      stage1Hypertension: fourthToFifthPoint,
      stage2Hypertension: fifthToEndPoint,
    })
  }, [diaValues])

  useEffect(() => {
    let tempDiaValues = [...diaValues]
    if (tempDiaValues[1] < 40) {
      setDiaCustomError(NORMAL_DIA_MSG)
    } else if (tempDiaValues[4] > 100) {
      setDiaCustomError(STAGE1_DIA_MSG)
    } else {
      setDiaCustomError("")
    }
  }, [diaValues])

  useEffect(() => {
    const firstToSecondPoint = calculatePercentage(pulseValues[0], pulseValues[1], pulseValues[0], pulseValues[pulseValues.length - 1]);
    const secondToThirdPoint = calculatePercentage(pulseValues[1], pulseValues[2], pulseValues[0], pulseValues[pulseValues.length - 1]);
    const thirdToEndPoint = calculatePercentage(pulseValues[2], pulseValues[pulseValues.length - 1], pulseValues[0], pulseValues[pulseValues.length - 1]);

    setPulseStageWidth({
      ...pulseStageWidth,
      low: firstToSecondPoint,
      normal: secondToThirdPoint,
      high: thirdToEndPoint
    })
  }, [pulseValues])

  useEffect(() => {
    let tempPulseValues = [...pulseValues]
    if (tempPulseValues[1] < 40) {
      setPulseCustomError(NORMAL_PULSE_MSG)
    } else if (tempPulseValues[2] > 110) {
      setPulseCustomError(HIGH_PULSE_MSG)
    } else {
      setPulseCustomError("")
    }
  }, [pulseValues])

  useEffect(() => {
    const defaultValues = [10, 90, 121, 141, 161, 300]
    for (let i = 0; i < sysValues.length - 1; i++) {
      if (sysValues[i] >= sysValues[i + 1]) {
        setDisabledSys(true)
        break
      }
      if (isEdit) {
        if (sysValues[i] === tempDefaultSysValues[i]) {
          setDisabledSys(true)
        } else {
          setDisabledSys(false)
          break
        }
      } else {
        if (sysValues[i] === defaultValues[i]) {
          setDisabledSys(true)
        } else {
          setDisabledSys(false)
          break
        }

      }
    }
  }, [sysValues])

  useEffect(() => {
    const defaultValues = [10, 60, 81, 91, 100, 150]
    for (let i = 0; i < diaValues.length - 1; i++) {
      if (diaValues[i] >= diaValues[i + 1]) {
        setDisabledDia(true)
        break
      }
      if (isEdit) {
        if (diaValues[i] === tempDefaultDiaValues[i]) {
          setDisabledDia(true)
        } else {
          setDisabledDia(false)
          break
        }
      } else {
        if (diaValues[i] === defaultValues[i]) {
          setDisabledDia(true)
        } else {
          setDisabledDia(false)
          break
        }
      }
    }
  }, [diaValues])

  useEffect(() => {
    const defaultValues = [10, 60, 100, 250]
    for (let i = 0; i < pulseValues.length - 1; i++) {
      if (pulseValues[i] >= pulseValues[i + 1]) {
        setDisabledPulse(true)
        break
      }
      if (isEdit) {
        if (pulseValues[i] === tempDefaultPulseValues[i]) {
          setDisabledPulse(true)
        } else {
          setDisabledPulse(false)
          break
        }
      } else {
        if (pulseValues[i] === defaultValues[i]) {
          setDisabledPulse(true)
        } else {
          setDisabledPulse(false)
          break
        }
      }
    }
  }, [pulseValues])

  const handleSysReadingChange = (e, index) => {
    let tempSysValues = [...sysValues];
    if (e < 30) {
      tempSysValues[index + 1] = tempSysValues[index] + (tempSysValues[index + 1] - tempSysValues[index]);
    } else {
      tempSysValues[index + 1] = e
    }
    setSysValues(tempSysValues)
  }

  const handleDiaReadingChange = (e, index) => {
    let tempDiaValues = [...diaValues];
    if (e < 15) {
      tempDiaValues[index + 1] = tempDiaValues[index] + (tempDiaValues[index + 1] - tempDiaValues[index]);
    } else {
      tempDiaValues[index + 1] = e
    }
    setDiaValues(tempDiaValues)
  }

  const handlePulseReadingChange = (e, index) => {
    let tempPulseValues = [...pulseValues];
    if (e < 25) {
      tempPulseValues[index + 1] = tempPulseValues[index] + (tempPulseValues[index + 1] - tempPulseValues[index]);
    } else {
      tempPulseValues[index + 1] = e
    }
    setPulseValues(tempPulseValues)
  }

  useEffect(() => {
    const newSysvalue = sysValues.slice(1, -1)
    const newMarksSys = newSysvalue.reduce((acc, value, index) => {
      acc[value] = {
        label: (
          <>
            <InputNumber
              value={value}
              controls={false}
              style={{
                width: '50px',
                height: '30px',
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              onChange={(e) => handleSysReadingChange(e, index)}
            />
          </>
        ),
        style: {
          position: "absolute",
          top: "-54px",
          fontSize: "16px"
        }
      };
      return acc;
    }, {});
    setMarksSys(newMarksSys)
  }, [sysValues])

  useEffect(() => {
    const newdiavalue = diaValues.slice(1, -1)
    const newMarksDia = newdiavalue.reduce((acc, value, index) => {
      acc[value] = {
        label: (
          <>
            <InputNumber
              value={value}
              controls={false}
              style={{
                width: '50px',
                height: '30px',
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              onChange={(e) => handleDiaReadingChange(e, index)}
            />
          </>
        ),
        style: {
          position: "absolute",
          top: "-54px",
          fontSize: "16px"
        }
      };
      return acc;
    }, {});
    setMarksDia(newMarksDia)
  }, [diaValues])

  useEffect(() => {
    const newPulseValue = pulseValues.slice(1, -1)
    const newMarksPulse = newPulseValue.reduce((acc, value, index) => {
      acc[value] = {
        label: (
          <>
            <InputNumber
              value={value}
              controls={false}
              style={{
                width: '50px',
                height: '30px',
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              onChange={(e) => handlePulseReadingChange(e, index)}
            />
          </>
        ),
        style: {
          position: "absolute",
          top: "-54px",
          fontSize: "16px"
        }
      };
      return acc;
    }, {});
    setMarksPulse(newMarksPulse)
  }, [pulseValues])

  const trackColors = ["#ff3b30", "#34c759", "#ffcc00", "#ffcc00", "#ff3b30"]
  const pulseTrackColors = ["#ff3b30", "#34c759", "#ff3b30"]

  const HandleShowSaveModal = () => {
    setShowSaveModal(true);
  }
  const HandleShowRemoveModal = () => {
    setShowRemoveModal(true);
  }

  const handleRemoveCustomParamter = async () => {
    const result = await dispatch(deletePatientBpCustomParameter(patientId))
    if (result?.message.toLowerCase() === "success") {
      await dispatch(resetPatientBpCustomParameter())
      addToast("BP custom parameters deleted successfully", {
        appearance: "success",
        autoDismiss: true
      })
      dispatch(getPatientInfo({ patientId }))
      setShowRemoveModal(false);
      setIsCustomParameter(false);
      setBpCustomParameterTab(false);
      setIsEdit(false);
      setShowCustomParameter(false);
    } else {
      addToast("Unable to delete BP custom parameters", {
        appearance: "error",
        autoDismiss: true
      })
      setShowRemoveModal(false);
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-9 mb-4">
        <div>
          <div className="flex justify-start items-center gap-2">
            <h6 className="text-lg font-bold text-secondary">SYS</h6>
            <p>(mmHg)</p>
          </div>
          <div>
            <Slider
              range
              min={10}
              max={300}
              onChange={(props) => {
                let newProps = [...props];
                newProps[0] = 10;
                newProps[props.length - 1] = 300;
                setSysValues(newProps);
              }}
              value={sysValues}
              marks={marksSys}
              tooltip={{ open: false }}
              trackStyle={trackColors.map((color) => ({
                backgroundColor: color
              }))}
            />
            <Row className="flex justify-between">
              <Col className="flex justify-center items-center font-medium" style={{ width: `${sysStageWidth.low}%` }}>Low</Col>
              <Col className="flex justify-center items-center font-medium" style={{ width: `${sysStageWidth.normal}%` }}>Normal</Col>
              <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${sysStageWidth.preHypertension}%` }}>{sysStageWidth.preHypertension < 8 ? "Pre-Hyper- tension" : "Pre-Hypertension"}</Col>
              <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${sysStageWidth.stage1Hypertension}%` }}>{sysStageWidth.stage1Hypertension < 8 ? "Stage 1 Hyper- tension" : "Stage 1 Hypertension"}</Col>
              <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${sysStageWidth.stage2Hypertension}%` }}>{sysStageWidth.stage2Hypertension < 8 ? "Stage 2 Hyper- tension" : "Stage 2 Hypertension"}</Col>
            </Row>
            <div className="w-full text-danger flex justify-start items-center gap-2 font-medium">
              {(sysCustomError !== "") &&
                <>
                  <ExclamationCircleFilled className="text-[#E10505]" />
                  <span>{sysCustomError}</span>
                </>
              }
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-start items-center gap-2">
            <h6 className="text-lg font-bold text-secondary">DIA</h6>
            <p>(mmHg)</p>
          </div>
          <div>
            <Slider
              range
              min={10}
              max={150}
              onChange={(props) => {
                let newProps = [...props];
                newProps[0] = 10;
                newProps[props.length - 1] = 150;
                setDiaValues(newProps);
              }}
              value={diaValues}
              marks={marksDia}
              tooltip={{ open: false }}
              trackStyle={trackColors.map((color) => ({
                backgroundColor: color
              }))}
            />
            <Row className="flex justify-between">
              <Col className="flex justify-center items-center font-medium " style={{ width: `${diaStageWidth.low}%` }}>Low</Col>
              <Col className="flex justify-center items-center font-medium " style={{ width: `${diaStageWidth.normal}%` }}>Normal</Col>
              <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${diaStageWidth.preHypertension}%` }}>{diaStageWidth.preHypertension < 8 ? "Pre-Hyper- tension" : "Pre-Hypertension"}</Col>
              <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${diaStageWidth.stage1Hypertension}%` }}>{diaStageWidth.stage1Hypertension < 8 ? "Stage 1 Hyper- tension" : "Stage 1 Hypertension"}</Col>
              <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${diaStageWidth.stage2Hypertension}%` }}>{diaStageWidth.stage2Hypertension < 8 ? "Stage 2 Hyper- tension" : "Stage 2 Hypertension"}</Col>
            </Row>
            <div className="w-full text-danger flex justify-start items-center gap-2 font-medium">
              {(diaCustomError !== "") &&
                <>
                  <ExclamationCircleFilled className="text-[#E10505]" />
                  <span>{diaCustomError}</span>
                </>
              }
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-start items-center gap-2">
            <h6 className="text-lg font-bold text-secondary">Pulse</h6>
            <p>(BPM)</p>
          </div>
          <div>
            <Slider
              range
              min={10}
              max={250}
              onChange={(props) => {
                let newProps = [...props];
                newProps[0] = 10;
                newProps[props.length - 1] = 250;
                setPulseValues(newProps);
              }}
              value={pulseValues}
              marks={marksPulse}
              tooltip={{ open: false }}
              trackStyle={pulseTrackColors.map((color, index) => ({
                backgroundColor: color
              }))}
            />
            <Row className="flex justify-between">
              <Col className="flex justify-center items-center font-medium " style={{ width: `${pulseStageWidth.low}%` }}>Low</Col>
              <Col className="flex justify-center items-center font-medium " style={{ width: `${pulseStageWidth.normal}%` }}>Normal</Col>
              <Col className="flex justify-center items-center font-medium " style={{ width: `${pulseStageWidth.high}%` }}>High</Col>
            </Row>
            <div className="h-5 w-full mb-4 text-danger flex justify-start items-center gap-2 font-medium">
              {(pulseCustomError !== "") &&
                <>
                  <ExclamationCircleFilled className="text-[#E10505]" />
                  <span>{pulseCustomError}</span>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <Row className="flex justify-center items-center gap-4">
        {isEdit &&
          <Button
            type="default"
            size="large"
            className="w-full md:w-[40%] xl:w-1/3"
            onClick={() => HandleShowRemoveModal()}
          >
            Remove Custom Parameters
          </Button>
        }
        <Button
          type="primary"
          size="large"
          disabled={(disabledSys && disabledDia && disabledPulse) || (sysCustomError || diaCustomError || pulseCustomError)}
          className="w-full md:w-[40%] xl:w-1/3"
          onClick={() => HandleShowSaveModal()}
        >
          Save
        </Button>
      </Row>
      <Modal
        footer={true}
        open={showSaveModal}
        centered
        width={700}
        onCancel={() => setShowSaveModal(false)}
        title={<h4 className="text-xl">Are you sure you want to save the changes?</h4>}
      >
        <Row className="flex justify-end items-center gap-6 pr-4 mt-4">
          <Button
            type="default"
            size="large"
            className="w-32"
            onClick={() => setShowSaveModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="w-32"
            disabled={postBpCustomParameterLoading || updateBpCustomParameterLoading}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Row>
      </Modal>
      <Modal
        footer={true}
        open={showRemoveModal}
        centered
        width={700}
        onCancel={() => setShowRemoveModal(false)}
        title={<h4 className="text-xl">Are you sure you want to remove custom parameters and go back to default?</h4>}
      >
        <Row className="flex justify-end items-center gap-6 pr-4 mt-4">
          <Button
            type="default"
            size="large"
            className="w-32"
            onClick={() => setShowRemoveModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="w-32"
            disabled={deleteBpCustomParameterLoading}
            onClick={() => handleRemoveCustomParamter()}
          >
            Confirm
          </Button>
        </Row>
      </Modal>
    </div>
  )
}

export default PatientBpCustomParameter
