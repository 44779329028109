import React, { useEffect } from "react"

import { Card, Avatar, Row, Col, Button } from "antd"

import UpdatePhoneNumber from "./UpdatePhoneNumber"
import UpdateProviderEmail from "./UpdateProviderEmail"

import defaultProvider from "../../assets/doctor.png"
import { useDispatch, useSelector } from "react-redux"
import { getSpecializationAction, providerNPIAction } from "../../redux/actions/providerAction"
import formatPhoneNumber from "../../utils/formatPhoneNumber"

const ProviderDetailHeader = ({ singleProvider, providerValue, handleShowProviderModal }) => {
  const dispatch = useDispatch()

  const profilePicture = singleProvider?.profileImage
    ? `data:image/png;base64,${singleProvider?.profileImage}`
    : defaultProvider

  const { specialization } = useSelector(state => state.specialization)
  const { providerNPI } = useSelector(state => state.providerNPI)

  useEffect(() => {
    if (!singleProvider?.id) return
    dispatch(getSpecializationAction(singleProvider?.id))
    dispatch(providerNPIAction(singleProvider?.id))
  }, [dispatch, singleProvider?.id])

  return (
    <Row gutter={16}>
      <Col className="mb-4" xs={24} sm={12} lg={8}>
        <Card loading={singleProvider === undefined}>
          <div className="flex flex-col flex-wrap gap-4">
            <div className="mx-auto border-b-2 text-center border-current pb-2 w-full">
              <Avatar size={72} src={<img alt="avatar" src={profilePicture} />} />
            </div>
            <div>
              {singleProvider?.userType && singleProvider?.userType !== "" && (
                <div>
                  <span>Type: </span>
                  <span className="capitalize font-semibold text-current ml-2">
                    {singleProvider?.userType}
                  </span>
                </div>
              )}
              {singleProvider?.userName !== "" && (
                <div className="mt-2">
                  <span>Name: </span>
                  <span className="capitalize font-semibold ml-2">
                    {singleProvider?.firstName}&nbsp;
                    {singleProvider?.middleName !== "" && (
                      <span>{singleProvider?.middleName}&nbsp;</span>
                    )}
                    {singleProvider?.lastName}
                  </span>
                </div>
              )}
              {singleProvider?.email !== "" && (
                <div className="mt-2">
                  <span>Email: </span>
                  <span className="font-semibold ml-2"> {singleProvider?.email}</span>
                </div>
              )}
              {singleProvider?.mobileNumber !== "" && (
                <div className="mt-2">
                  <span>Contact: </span>
                  <span className="font-semibold ml-2">
                    {singleProvider?.mobileNumber !== null
                      ? formatPhoneNumber(singleProvider?.mobileNumber)
                      : "Mobile Number is not added."}
                  </span>
                </div>
              )}
              {specialization?.length ? (
                <div className="mt-2">
                  <span>Specialty :</span>
                  <span className="font-semibold ml-2 capitalize">
                    {" "}
                    {specialization?.map(({specialization})=>specialization).join(", ")}                    
                  </span>
                </div>
              ) : null}
              {providerNPI?.npi && (
                <div className="mt-2">
                  <span>NPI Number :</span>
                  <span className="font-semibold ml-2"> {providerNPI?.npi}</span>
                </div>
              )}
            </div>
          </div>
          {providerValue ? (
            <Button block type="primary" className="mt-4" onClick={handleShowProviderModal}>
              Change Provider
            </Button>
          ) : null}
        </Card>
      </Col>
      <Col className="mb-4" xs={24} sm={12} lg={8}>
        <UpdateProviderEmail singleProvider={singleProvider} />
      </Col>

      <Col className="mb-4" xs={24} sm={12} lg={8}>
        <UpdatePhoneNumber singleProvider={singleProvider} />
      </Col>
    </Row>
  )
}

export default ProviderDetailHeader
