import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Modal, List } from "antd"

import Loading from "../../Loading/Loading"
import { getPatientDiagnosticsData } from "../../../redux/actions/patientAction"
import moment from "moment"

const DiagnosticCode = ({ handleDiagnosticCodeClose, showDiagnosticCodes, patientInfo }) => {
  const dispatch = useDispatch()
  const [isFirstCall, setIsFirstCall] = useState(true)

  const { loading, patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)

  useEffect(() => {
    if (!patientInfo?.id || !showDiagnosticCodes || !isFirstCall) return

    dispatch(getPatientDiagnosticsData(patientInfo?.id))
    setIsFirstCall(false)
  }, [patientInfo, showDiagnosticCodes])

  return (
    <Modal
      footer={null}
      open={showDiagnosticCodes}
      onCancel={handleDiagnosticCodeClose}
      title={<h4 className="text-xl">Added Diagnostic Codes</h4>}>
      <div className="pt-4">
        {loading || patientDiagnosticData === undefined ? (
          <Loading />
        ) : patientDiagnosticData !== undefined && patientDiagnosticData?.length ? (
          <List
            dataSource={patientDiagnosticData}
            renderItem={(value, index) => {
              const isLastItem = patientDiagnosticData.length - 1 === index
              return (
                <div
                  key={index}
                  className={`mb-3 ${!isLastItem ? "border-b-2 border-current pb-3" : ""}`}>
                  <h5 className="flex justify-between">
                    <span className="mb-2">{value?.description}</span>
                    <span>{value?.icdCode}</span>
                  </h5>
                  <p className="mb-0">
                    <span className="font-medium">on Date :</span>{" "}
                    {moment(value?.diagnosedDate).format("MM/DD/YYYY")}
                  </p>
                </div>
              )
            }}
          />
        ) : (
          <h3 className="text-xl">No Diagnostic Code added for the patient yet.</h3>
        )}
      </div>
    </Modal>
  )
}

export default DiagnosticCode
