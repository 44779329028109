import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"
import { OnBoardedStage, OnBoardingPendingStage } from "./Types"
import { getSelfOnboardingPendingPatientAction } from "../../../redux/actions/eligiblePatientAction"
import { Tabs } from "antd"
import OnboardingPendingPatient from "./OnboardingPendingPatient"
import "./EligiblePatient.css"
import OnboardedPatient from "./OnboardedPatient"

const EligiblePatient = () => {
  const [onboardPendingPatient, setOnboardPendingPatient] = useState()

  const [selfOnboardedPatients, setSelfOnboardedPatients] = useState()
  const [patientLoading, setPatientLoading] = useState(true)
  const dispatch = useDispatch()
  const { orgId } = useParams()
  const { selfOnboardingPendingPatient, loading: selfOnboardLoading } = useSelector(
    state => state.selfOnboardingPendingPatient
  )
  // const { loading: caretakerLoading, caretakerLists } = useSelector(state => state.caretakerList)

  // const { onboardpatientStatistics, loading: onboardPatientStatisticLoading } = useSelector(
  //   state => state.getOrgOnboardPatientStatistics
  // )

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      disable: true,
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, patientDeatils) => (
        <p
          className="capitalize wrap-text mb-0"
          // style={{ cursor: "pointer" }}
          // onClick={() => {
          //   setPatientDetail(patientDeatils)
          //   setShowPatientProfileStatges(true)
          // }}
        >
          {name?.toLowerCase()}
        </p>
      )
    },
    {
      title: "Phone",
      dataIndex: "phone",
      disable: true,
      key: "phone",
      copyable: true,
      render: (_, { phone }) => <p>{phone ? formatPhoneNumber(phone) : "-"}</p>
    },
    { title: "Email", disable: true, dataIndex: "email", key: "email", copyable: true },
    {
      title: "Onboarding Stage",
      dataIndex: "onBoardingStage",
      key: "onBoardingStage",
      sorter: (a, b) => a.onBoardingStage.localeCompare(b.onBoardingStage)
    },
    {
      title: "Patient Type",
      dataIndex: "userType",
      key: "userType ",
      sorter: (a, b) => a.userType.localeCompare(b.userType)
    }
  ]

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("onboardComplete")
  // Function to handle tab change
  const handleTabChange = key => {
    setActiveTab(key)
  }

  useEffect(() => {
    if (selfOnboardingPendingPatient) {
      const pendingSignup = selfOnboardingPendingPatient?.filter(patient => !patient.signup)
      const completeSignup = selfOnboardingPendingPatient?.filter(patient => patient.signup)

      const mapPatient = p => ({
        id: p.id,
        dob: p.dob,
        email: p.email,
        phone: p.mobileNumber,
        name: `${p.firstName} ${p.middleName || ""} ${p.lastName}`,
        firstName: p.firstName,
        lastName: p.lastName,
        middleName: p.middleName,
        gender: p.gender,
        telephone: p.telephone,
        extension: p.extension,
        // caretaker: getCaretakerById(p.caretakerIds),
        caretakerIds: p.caretakerIds,
        userType: p.typeOfPatient,
        onBoardingStage: p?.deniedService
          ? OnBoardingPendingStage.DeniedService
          : p.signup
          ? OnBoardedStage.SignedUp
          : p.signupPending
          ? OnBoardingPendingStage.SignUpPending
          : OnBoardingPendingStage.SignUpInitiated
      })

      const pendingPatients = pendingSignup.map(mapPatient)
      const onboardedPatients = completeSignup.map(mapPatient)

      setOnboardPendingPatient(pendingPatients)
      //  setFilteredPatients(pendingPatients)
      setSelfOnboardedPatients(onboardedPatients)
      //  setFilteredOnboaredPatients(onboardedPatients)
    }
  }, [selfOnboardLoading, selfOnboardingPendingPatient])

  useEffect(() => {
    if (selfOnboardedPatients) setPatientLoading(false)
  }, [selfOnboardedPatients, onboardPendingPatient])

  useEffect(() => {
    dispatch(getSelfOnboardingPendingPatientAction())
  }, [dispatch])

  const patientTabItems = [
    {
      key: "onboardComplete",
      label: `Onboarded Patients`,
      children: (
        <OnboardedPatient
          columns={columns}
          selfOnboardedPatients={selfOnboardedPatients}
          patientLoading={patientLoading}
        />
      )
    },
    {
      key: "onboardPending",
      label: `Onboarding Pending Patients`,
      children: (
        <OnboardingPendingPatient columns={columns} onboardPendingPatient={onboardPendingPatient} />
      )
    }
  ]

  return (
    <div className="mt-2 ">
      <Tabs
        activeKey={activeTab}
        type="card"
        defaultActiveKey="onboardComplete"
        items={patientTabItems}
        onChange={handleTabChange}
      />
    </div>
  )
}
export default EligiblePatient
