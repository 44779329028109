//get all monitor type list action
import { GET_MONITOR_TYPE_LIST_FAIL, GET_MONITOR_TYPE_LIST_REQUEST, GET_MONITOR_TYPE_LIST_SUCCESS } from "../types/devicesConstant";
import { axiosGet, axiosPost } from "../axios";
import { userLogoutAction } from './userActions';

export const getMonitorListTypeAction = () => async (dispatch) => {
    try {
        dispatch({ type: GET_MONITOR_TYPE_LIST_REQUEST });

        const { data } = await axiosGet(`/api/cs/config/monitorTypes`);

        dispatch({
            type: GET_MONITOR_TYPE_LIST_SUCCESS,
            payload: data.details,
        });
        localStorage.setItem("monitorTypes", JSON.stringify(data?.details))

        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_MONITOR_TYPE_LIST_FAIL,
            payload: error.response && error?.response?.data?.details
        })
    }
};
