import { axiosBasicPost, axiosGet, axiosPost } from "../axios"
import {
    EMAIL_VERIFICATION_FAIL,
    EMAIL_VERIFICATION_REQUEST,
    EMAIL_VERIFICATION_SUCCESS,
    FORGET_PASSWORD_CHANGE_FAIL,
    FORGET_PASSWORD_CHANGE_REQUEST,
    FORGET_PASSWORD_CHANGE_SUCCESS,
    FORGET_PASSWORD_OPT_FAIL,
    FORGET_PASSWORD_OPT_REQUEST,
    FORGET_PASSWORD_OPT_SUCCESS,
    UPDATE_PROVIDER_PROFILE_REQUEST,
    UPDATE_PROVIDER_PROFILE_SUCCESS,
    UPDATE_PROVIDER_PROFILE_FAIL,
    GET_ORG_REQUEST,
    GET_ORG_SUCCESS,
    GET_ORG_FAIL,
    SET_FILTER,
    CLEAR_FILTER
} from "../types/utilConstant";
import { userLogoutAction } from './userActions';
import { getProviderPatientList } from './providerAction';
import { useNavigate } from 'react-router-dom';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_OTP_SUCCESS,
  USER_OTP_REQUEST,
  USER_OTP_FAIL
} from "../types/userConstant"
import qs from 'qs';
import { loginTypeChecker } from "../../utils/loinTypeChecker"
import { deviceInfo } from "../../utils/fixedInfo"
import AsyncLocalStorage from "@createnextapp/async-local-storage"

export const getOrgAction = oldId => async dispatch => {
  try {
    dispatch({
      type: GET_ORG_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/org/${oldId}/fetch`)
    await AsyncLocalStorage.setItem("orgData", JSON.stringify(data?.details))

    dispatch({
      type: GET_ORG_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    dispatch({
      type: GET_ORG_FAIL,
      payload: error.response && error.response?.data?.details
    })
    error?.response?.status === 401 && dispatch(userLogoutAction())
    return error.response
  }
}

export const emailVerificationAction = (email) => async (dispatch) => {
    try {
        dispatch({
            type: EMAIL_VERIFICATION_REQUEST,
        });

        const { data } = await axiosGet(`v1/validateEmail?email=${email}`);

        dispatch({
            type: EMAIL_VERIFICATION_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: EMAIL_VERIFICATION_FAIL,
            payload: error.response && error.response?.data?.details,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
        return error.response;
    }
};

export const forgetPasswordProviderAction = (userName) => async (dispatch) => {
    try {
        dispatch({
            type: FORGET_PASSWORD_OPT_REQUEST,
        });

        let loginTypes = loginTypeChecker(userName)
        let types = deviceInfo()
        const { data } = await axiosBasicPost(`/api/auth/forgotPassword`, {
          userName,
         loginTypes,
         deviceId: types.deviceId,
         deviceModel: types.deviceModel,
         appVersion: types.appVersion
         })    

        dispatch({
            type: FORGET_PASSWORD_OPT_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: FORGET_PASSWORD_OPT_FAIL,
            payload: error.response && error.response?.data?.details,
        });
        return error.response && error?.response?.data?.details
    }
};

export const forgetPasswordChangeAction = (otp, email, password) => async (dispatch) => {
    try {
        dispatch({
            type: FORGET_PASSWORD_CHANGE_REQUEST,
        });

        const { data } = await axiosPost(`v1/resetForgotPasswordProvider`, {
            otp,
            email,
            password,
        });

        dispatch({
            type: FORGET_PASSWORD_CHANGE_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: FORGET_PASSWORD_CHANGE_FAIL,
            payload: error.response && error.response?.data?.details,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};


export const updateProviderProfileAction = (providerId, formData) => async dispatch => {
    try {
      dispatch({ type: UPDATE_PROVIDER_PROFILE_REQUEST })
      const { userType } = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))

      const { data } = await axiosPost(`/api/cs/${userType}/${providerId}/update`, {
        ...formData,
        countryCode: formData?.countryCode?.toUpperCase(),
        middleName : formData?.middleName ? formData?.middleName : null
      })
    //   dispatch({ type: UPDATE_PROVIDER_PROFILE_SUCCESS, payload: data?.details })
    localStorage.setItem("userInfo", JSON.stringify(formData))
    dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: formData
      })
      return data
    } catch (error) {
      dispatch({
        type: UPDATE_PROVIDER_PROFILE_FAIL,
        payload: error.response && error.response.data.details
      })
      return error?.response && error.response?.data?.details
    }
}

// export const updateEmailAction = (type,userId, email) => async (dispatch) => {
//     try {
//         dispatch({ type: UPDATE_EMAIL_REQUEST });
//         const { data } = await axiosPost(`/support/v1/updateEmail`, { userId,email });
//         dispatch({ type: UPDATE_EMAIL_SUCCESS, payload: data.success });
//         if(type === 'provider') {
//             return document.location.href = '/provider';
//         }
//         if(type === 'caretaker') {
//             return document.location.href = '/caretaker';
//         }
//     } catch (error) {
//         dispatch({
//             type: UPDATE_EMAIL_FAIL,
//             payload: error.response && error.response.data.detail && error.response.data.detail
//         });
//         return error.response.data.detail
//     }
// };

export const forgetPasswordLoginAction = (userName, otp) => async dispatch => {
  try {
    dispatch({
    type: USER_OTP_REQUEST
    // type: USER_LOGIN_REQUEST
    })

    let loginTypes = loginTypeChecker(userName)
    const { data } = await axiosBasicPost(`/api/auth/validateOTP`, {
      userName,
      loginTypes,
      otp
    })
    dispatch({
        type: USER_OTP_SUCCESS,
        payload: data
      });
    return data
  } catch (error) {
    dispatch({
     type: USER_OTP_FAIL,
     // type: USER_LOGIN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
    return error.response && error?.response?.data?.details
  }
}

export const setFilterModalValuesAction = val => dispatch => {
    dispatch({ type: SET_FILTER, payload: val })
}
export const clearFilterModalValuesAction = () => dispatch => {
    dispatch({ type: CLEAR_FILTER })
}