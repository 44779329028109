import React, { useState, useEffect } from "react"

import { Card, Input, Button } from "antd"
import { useToasts } from "react-toast-notifications"

import { useDispatch } from "react-redux"
import { updateProviderProfileAction } from "../../redux/actions/utilsActions"
// import { getUserProfileAction } from "../../redux/actions/userActions"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Mixpanel } from "../../utils/mixpanel"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"

const schema = yup
  .object({
    mobileNumber: yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid Mobile Number").required()
  })
  .required()

const UpdatePhoneNumber = ({ singleProvider }) => {
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const dispatch = useDispatch()

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mobileNumber: singleProvider?.mobileNumber !== null ? formatPhoneNumber(singleProvider?.mobileNumber) : ""
    }
  })

  useEffect(() => {
    if (singleProvider?.mobileNumber !== null) {
      setValue("mobileNumber", formatPhoneNumber(singleProvider?.mobileNumber))
    }
  }, [singleProvider?.mobileNumber])

  const onSubmit = async values => {
    setLoading(true)
    const result = await dispatch(
      updateProviderProfileAction(singleProvider?.id, {
        ...singleProvider,
        mobileNumber: values?.mobileNumber?.replace(/[^\d]/g, '')
      })
    )
    setLoading(false)
    if (result.status === 200) {
      addToast("Update Provider Phone Number Successfully", {
        appearance: "success",
        autoDismiss: true
      })
      // setTimeout(() => {
      //   dispatch(getUserProfileAction(singleProvider?.id))
      // }, 500)
    }else{
      setError("mobileNumber", {
        type: "custom",
        message: result?.message ?? "Unable to update Mobile Number!"
      })
    }
  }

  const handleMobileChange = (e) => {
    const { value } = e.target;
    return formatPhoneNumber(value)
  };

  return (
    <Card loading={singleProvider === undefined}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="mb-3 text-lg" htmlFor="input-mobileNumber">
          Update Mobile Number
        </label>
        <Controller
          control={control}
          name="mobileNumber"
          rules={{ required: true }}
          render={({ field }) => (
            <PatternFormat
              format="(###) ###-####"
              className={`formatted-mobile-input flex p-2 text-[1rem] w-full  border border-${
                errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
              }  rounded-lg focus:outline-none`}
              id="input-mobileNumber"
              {...field}
              onChange={e => field.onChange(handleMobileChange(e))}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
        />
        {errors.mobileNumber ? (
          <small className="text-danger block">{errors.mobileNumber.message}</small>
        ) : null}
        <Button
          className="mt-3"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={() => Mixpanel.track("Update Mobile Clicked")}>
          Update
        </Button>
      </form>
    </Card>
  )
}

export default UpdatePhoneNumber
