import {
    CRITICAL_PATIENT_FAIL,
    CRITICAL_PATIENT_REQUEST,
    CRITICAL_PATIENT_SUCCESS,
    DASHBOARD_KPI_FAIL,
    DASHBOARD_KPI_REQUEST,
    DASHBOARD_KPI_SUCCESS,
    FILTER_DASHBOARD_FAIL,
    FILTER_DASHBOARD_REQUEST,
    FILTER_DASHBOARD_SUCCESS,
} from "../types/dashboardConstant";

const initialState = {
    dashboardKPI: {},
    criticalPatient: {},
};

export const dashboardKPIReducer = (state = initialState.dashboardKPI, action) => {
    switch (action.type) {
        case DASHBOARD_KPI_REQUEST:
            return { loading: true };
        case DASHBOARD_KPI_SUCCESS:
            return { loading: false, dashboardKPI: action.payload };
        case DASHBOARD_KPI_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const criticalPatientReducer = (state = initialState.criticalPatient, action) => {
    switch (action.type) {
        case CRITICAL_PATIENT_REQUEST:
            return { loading: true };
        case CRITICAL_PATIENT_SUCCESS:
            return { loading: false, criticalPatient: action.payload };
        case CRITICAL_PATIENT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const filterDashboardReducer = (state = initialState.dashboardKPI, action) => {
    switch (action.type) {
        case FILTER_DASHBOARD_REQUEST:
            return { loading: true };
        case FILTER_DASHBOARD_SUCCESS:
            return { loading: false, dashboardKPI: action.payload };
        case FILTER_DASHBOARD_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
