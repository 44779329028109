import {
    GET_RPM_CAREPLAN_REQUEST,
    GET_RPM_CAREPLAN_SUCCESS,
    GET_RPM_CAREPLAN_FAIL,
} from "../types/rpmConstants";


export const getRpmCarePlanReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_RPM_CAREPLAN_REQUEST:
            return { loading: true };
        case GET_RPM_CAREPLAN_SUCCESS:
            return { loading: false, getRpmCarePlan: action.payload };
        case GET_RPM_CAREPLAN_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

