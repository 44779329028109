import React, { useState, useEffect } from "react"
import "./PCMViewQuestion.css"
import { useLocation, useNavigate } from "react-router-dom"
import Loading from "../../../Loading/Loading"
import PlanView from "@fitpeo/fit-plan-viewer"
import { useDispatch, useSelector } from "react-redux"
import { getPatientDiagnosticsData } from "../../../../redux/actions/patientAction"
import { getLatestPcmCareplanActions } from "../../../../redux/actions/pcmActions"
import { Button } from "antd"

const PCMViewQuestion = () => {
    const [callRestApis, setCallRestApis] = useState(false)
    const [provider, setProvider] = useState([])
    const [practitioner, setPractitioner] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const patientInfoDetail = location?.state?.patientInfoDetail
    const patientMonitoringData = location?.state?.patientMonitoringData

    const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
    const { loading: pcmCareplanLoading, latestPcmCareplan } = useSelector(state => state.getLatestPcmCareplan)

    useEffect(() => {
        if (!patientInfoDetail || !patientInfoDetail?.id) navigate("/")
        dispatch(getLatestPcmCareplanActions(patientInfoDetail?.id))
        setCallRestApis(true)
    }, [dispatch, patientInfoDetail])

    useEffect(() => {
        if (latestPcmCareplan && callRestApis) {
            if (!latestPcmCareplan?.plan?.planIcds) dispatch(getPatientDiagnosticsData(patientInfoDetail?.id))
            setCallRestApis(false)
        }
    }, [latestPcmCareplan])

    useEffect(() => {
        let provider = []
        let practitioner = []

        if (latestPcmCareplan?.plan?.providers) provider = latestPcmCareplan?.plan?.providers
        else if (patientMonitoringData?.ccm?.[0]) {
            provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
        }
        if (latestPcmCareplan?.plan?.practitioners) practitioner = latestPcmCareplan?.plan?.practitioners
        else if (patientMonitoringData?.ccm?.[0]) {
            practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
        }

        if (latestPcmCareplan?.plan?.provider) {
            provider = [`${latestPcmCareplan?.plan?.provider?.providerFirstName && latestPcmCareplan?.plan?.provider?.providerFirstName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerFirstName : ""}
          ${latestPcmCareplan?.plan?.provider?.providerMiddleName && latestPcmCareplan?.plan?.provider?.providerMiddleName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerMiddleName : ""} 
          ${latestPcmCareplan?.plan?.provider?.providerLastName && latestPcmCareplan?.plan?.provider?.providerLastName !== "" ? " " + latestPcmCareplan?.plan?.provider?.providerLastName : ""}`]
        }

        setProvider(provider)
        setPractitioner(practitioner)
    }, [latestPcmCareplan, patientMonitoringData])

    const planIcds =
        latestPcmCareplan?.plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)

    // It will convert v1 careplan structure to v2 careplan structure
    const convertCareplanToV2 = plan => {
        if (plan?.body) return plan?.body
        else return Object.values(plan)
    }

    return pcmCareplanLoading ? (
        <Loading />
    ) : (
        (latestPcmCareplan && (
            <>
                <div className="d-flex justify-content-end">
                    <Button
                        type="primary"
                        className="btn-sm mx-2"
                        onClick={() =>
                            navigate("/pcm-view-care-print", {
                                state: {
                                    type: "pcm",
                                    patientInfoDetail: patientInfoDetail,
                                    planDate: latestPcmCareplan?.planDate,
                                    provider: provider,
                                    practitioner: practitioner,
                                    plan: convertCareplanToV2(latestPcmCareplan?.plan),
                                    planIcds,
                                    addedBy: latestPcmCareplan?.plan?.addedBy,
                                }
                            })
                        }>
                        Print
                    </Button>
                </div>
                <PlanView
                    type="pcm"
                    patientInfoDetail={patientInfoDetail}
                    providers={provider}
                    practitioners={practitioner}
                    plan={convertCareplanToV2(latestPcmCareplan?.plan)}
                    planIcds={planIcds}
                    addedBy={latestPcmCareplan?.plan?.addedBy}
                    planDate={latestPcmCareplan?.planDate}
                />
            </>
        )) || <h1 className="text-xl text-center">PCM Care Plan has not been taken</h1>
    )
}

export default PCMViewQuestion
