import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { sortBy } from "../../../utils/sortBy"
import moment from "moment"

import {
  fetchProviderProfile,
  getProviderPatientList,
  getFilterProviderPatientAction,
  resetFilterProviderPatientAction,
  saveProviderPatientAdvanceFilter
} from "../../../redux/actions/providerAction"

import Loading from "../../Loading/Loading"
import ProviderDetailHeader from "./ProviderDetailHeader"
import ProviderPatientTable from "./ProviderPatientTable"
import ProviderPatientFilter from "./ProviderPatientFilter"

const ProviderProfile = () => {
  const { providerId } = useParams()

  const [searchQuery, setSearchQuery] = useState("")
  const [sortColumn, setSortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [sortedData, setSortedData] = useState([])
  const [allPatients, setAllPatients] = useState([])

  const { loading: singleProviderLoading } = useSelector(state => state.singleProvider)
  const { loading: providerPatientLoading, providerPatient } = useSelector(
    state => state.providerPatient
  )

  const { providerPatientAdvFilter: patientAdvFilter } = useSelector(
    state => state.providerPatientAdvFilter
  )

  const { userInfo } = useSelector(state => state.userInfo)
  const isPractitioner = userInfo?.userType?.toLowerCase() === "practitioner"

  const { getFilterUsersData, loading: filterPatientLoading } = useSelector(
    state => state.providerFilterPatient
  )

  const [sortValue, setSortValue] = useState("title")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isPractitioner) return navigate("/")
    if (!providerId) return
    dispatch(fetchProviderProfile(providerId))
    if (providerId !== undefined) {
      dispatch(getProviderPatientList(providerId, "provider"))
    }
  }, [dispatch, providerId])

  // useEffect(() => {
  //   if (!providerPatient) return
  //   const patients = manupulatePatientData(providerPatient)
  //   setAllPatients(patients)
  //   setSortedData(patients)
  // }, [providerPatient])

  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }

  useEffect(() => {
    if (getFilterUsersData) {
      setAllPatients(getFilterUsersData)
    }
  }, [getFilterUsersData])

  useEffect(() => {
    if (providerPatient) {
      setAllPatients(providerPatient)
    }
  }, [providerPatient])

  const showSearchedData = () => {
    let patientList = allPatients
    const query = searchQuery?.toLowerCase()

    if (query) {
      patientList = patientList?.filter(m => {
        const fullName = `${m?.firstName}${m?.middleName || ""}${m?.lastName}`.toLowerCase()
        const reversedFullName = `${m?.lastName}${m?.middleName || ""}${m?.firstName}`.toLowerCase()
        const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
        return (
          m?.firstName?.toLowerCase().includes(query) ||
          m?.middleName?.toLowerCase().includes(query) ||
          m?.lastName?.toLowerCase().includes(query) ||
          m?.email?.toLowerCase().includes(query) ||
          String(m?.mobileNumber)?.toLowerCase().includes(query) ||
          m?.userName?.toLowerCase().includes(query) ||
          m?.id?.toLowerCase().includes(query) ||
          fullName.includes(trimmedSearchValue) ||
          reversedFullName.includes(trimmedSearchValue)
        )
      })
    }

    patientList = patientList.map(patient => {
      if (patient?.manualLog?.length) {
        let ccmLogCount = 0
        let rpmLogCount = 0
        let manualLogCount = 0
        if (
          moment(patient?.manualLogActiveTime).month() === moment().month() &&
          moment(patient?.manualLogActiveTime).year() === moment().year()
        ) {
          patient?.manualLog?.map(manual => {
            if (
              manual?.careType === "ccm" &&
              moment(manual?.careDate).month() === moment().month() &&
              moment(manual?.careDate).year() === moment().year()
            ) {
              ccmLogCount += manual?.count
            } else if (
              manual?.careType === "rpm" &&
              moment(manual?.careDate).month() === moment().month() &&
              moment(manual?.careDate).year() === moment().year()
            ) {
              rpmLogCount += manual?.count
            }
          })
          patient = { ...patient, rpmManualLogCount: rpmLogCount, ccmManualLogCount: ccmLogCount }
        } else {
          patient = {
            ...patient,
            rpmManualLogCount: rpmLogCount,
            ccmManualLogCount: ccmLogCount,
            manualLogCount: manualLogCount
          }
        }
      }
      if (
        patient?.rpmActiveDate?.length !== 0 &&
        moment(patient?.rpmActiveDate).month() === moment().month() &&
        moment(patient?.rpmActiveDate).year() === moment().year()
      ) {
        return { ...patient, monthlyLastestTotalReadingRPM: patient?.rpmReadingCount }
      } else {
        return { ...patient, monthlyLastestTotalReadingRPM: 0 }
      }
    })
    let temp = []
    if (sortColumn.path === sortBy.AtoZ) {
      temp = patientList?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    if (sortColumn.path === sortBy.ZtoA) {
      temp = patientList?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }

    if (sortValue === "count") {
      temp = sortReadingCount("desc", temp)
    } else if (sortValue === "Reading") {
      temp = sortLatestReading("desc", temp)
    } else if (sortValue === "critical") {
      temp = filterCriticalReading("desc", temp)
    }

    setSortedData(temp)
  }

  useEffect(() => {
    showSearchedData()
  }, [allPatients, sortValue, searchQuery]) //eslint-disable-line

  const applyFilters = (type, data) => {
    dispatch(saveProviderPatientAdvanceFilter(data))
    setCurrentPage(1)
    if (type === "normal" && data?.facilityId) {
      dispatch(getFilterProviderPatientAction({ formData: data, providerId }))
    } else if (type === "normal" && !data?.facilityId) {
      setAllPatients(providerPatient?.filter(patient => patient?.userType === data?.userType))
    } else {
      getOnBoardingPatients()
    }
  }

  const getOnBoardingPatients = async () => await dispatch(resetFilterProviderPatientAction())

  // if (sortColumn.path === sortBy.AtoZ) {
  //     sortedData = allPatients?.sort((a, b) => (a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1));
  // }

  const resetFilters = async cb => {
    setAllPatients(providerPatient)
    await dispatch(resetFilterProviderPatientAction())
    if (cb) {
      cb()
    }
  }

  // if (sortColumn.path === sortBy.ZtoA) {
  //     sortedData = allPatients?.sort((a, b) => (a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1));
  // }

  const sortReadingCount = (val, patients) => {
    const countExistsArr = patients.filter(
      x =>
        x?.rpmReadingCount !== null &&
        x?.rpmReadingCount !== undefined &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noCountArr = patients.filter(
      x => x?.rpmReadingCount === undefined || x?.rpmReadingCount === null
    )
    const ascCountArr = countExistsArr.sort((a, b) => a?.rpmReadingCount - b?.rpmReadingCount)
    const descCountArr = countExistsArr.sort((a, b) => b?.rpmReadingCount - a?.rpmReadingCount)
    if (val === "asc") {
      const sd = noCountArr.concat(ascCountArr)
      return sd
    } else {
      const sd = descCountArr.concat(noCountArr)
      return sd
    }
  }

  const sortLatestReading = (val, patients) => {
    const dateExistsArr = patients?.filter(
      x =>
        x?.rpmActiveDate !== null &&
        x?.rpmActiveDate !== undefined &&
        x?.rpmActiveDate !== "" &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noDateArr = patients?.filter(
      x => x?.rpmActiveDate === undefined && x?.rpmActiveDate === null && x?.rpmActiveDate === ""
    )
    const ascDateArr = dateExistsArr.sort(
      (a, b) => new Date(a?.rpmActiveDate) - new Date(b?.rpmActiveDate)
    )
    const descDateArr = dateExistsArr.sort(
      (a, b) => new Date(b?.rpmActiveDate) - new Date(a?.rpmActiveDate)
    )
    if (val === "asc") {
      const sd = noDateArr.concat(ascDateArr)
      return sd
    } else {
      const sd = descDateArr.concat(noDateArr)
      return sd
    }
  }

  const filterCriticalReading = (val, patients) => {
    const countExistsArr = patients.filter(x => x?.criticalStatus === true)
    return countExistsArr
  }

  if (!isPractitioner) return <></>

  return singleProviderLoading ? (
    <Loading />
  ) : (
    <>
      <ProviderDetailHeader providerId={providerId} />

      <ProviderPatientFilter
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        setSearchQuery={setSearchQuery}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        sortValue={sortValue}
        setSortValue={setSortValue}
      />

      <ProviderPatientTable
        loading={providerPatientLoading || filterPatientLoading}
        sortedData={sortedData}
      />
    </>
  )
}

export default ProviderProfile
