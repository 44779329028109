import {
    EMAIL_VERIFICATION_FAIL,
    EMAIL_VERIFICATION_REQUEST,
    EMAIL_VERIFICATION_SUCCESS,
    FORGET_PASSWORD_CHANGE_FAIL,
    FORGET_PASSWORD_CHANGE_REQUEST,
    FORGET_PASSWORD_CHANGE_SUCCESS,
    UPDATE_PROVIDER_PROFILE_REQUEST,
    UPDATE_PROVIDER_PROFILE_SUCCESS,
    UPDATE_PROVIDER_PROFILE_FAIL,
    GET_ORG_REQUEST,
    GET_ORG_SUCCESS,
    GET_ORG_FAIL,
} from "../types/utilConstant";

export const getOrgReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ORG_REQUEST:
            return { loading: true };
        case GET_ORG_SUCCESS:
            return { loading: false, org: action.payload };
        case GET_ORG_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const emailVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case EMAIL_VERIFICATION_REQUEST:
            return { loading: true };
        case EMAIL_VERIFICATION_SUCCESS:
            return { loading: false, emailVerification: action.payload };
        case EMAIL_VERIFICATION_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const passwordChangeReducer = (state = {}, action) => {
    switch (action.type) {
        case FORGET_PASSWORD_CHANGE_REQUEST:
            return { loading: true };
        case FORGET_PASSWORD_CHANGE_SUCCESS:
            return { loading: false, forgetPasswordChange: action.payload };
        case FORGET_PASSWORD_CHANGE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const updateProviderProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PROVIDER_PROFILE_REQUEST:
            return { loading: true };
        case UPDATE_PROVIDER_PROFILE_SUCCESS:
            return { loading: false, profileUpdate: action.payload };
        case UPDATE_PROVIDER_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
