import React, { useEffect, useState } from "react"

import { Row, Col } from "antd"

import fitpeoLogo from "../../assets/fitpeo_logo.png"
import illustration from "../../assets/forgot-password-illustration.png"

import OTPForm from "./OTPForm"
import CredentialForm from "./CredentialForm"
import ChangePasswordForm from "./ChangePasswordForm"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const ForgetPassword = () => {
  const navigate = useNavigate()
  const [credential, setCredential] = useState("")
  const [otpValue, setOtpValue] = useState("")
  const [showNext, setShowNext] = useState(false)
  const [userName, setUserName] = useState("")
  const [changePasswordShow, setChangePasswordShow] = useState(false)

  const { userInfo } = useSelector(state => state.userInfo)
  useEffect(() => {
    if (userInfo) {
      navigate("/", { replace: true })
    }
  }, [userInfo]) //eslint-disable-line

  return (
    !userInfo &&
    <div className="flex h-screen p-4">
      <Row className="items-center justify-center">
        <Col sm={24} className="block lg:hidden">
          <img src={illustration} alt="loginImage" className="img-fluid float-end login-sm-image" />
        </Col>

        <Col sm={24} lg={6}>
          <div className="border-2 border-current rounded p-4">
            <img src={fitpeoLogo} alt="logo" className="img-fluid fitpeo_logo_login" />
            {!showNext && !changePasswordShow && (
              <CredentialForm setShowNext={setShowNext} setCredential={setCredential} setUserName={setUserName} />
            )}
            {showNext ? (
              <OTPForm
                credential={credential}
                otpValue={otpValue}
                setOtpValue={setOtpValue}
                setShowNext={setShowNext}
                setChangePasswordShow={setChangePasswordShow}
              />
            ) : null}

            {changePasswordShow && <ChangePasswordForm otp={otpValue} userName={userName} setChangePasswordShow={setChangePasswordShow} />}
          </div>
        </Col>
        <Col className="hidden lg:block" lg={12}>
          <img src={illustration} alt="loginImage" className="img-fluid" />
        </Col>
      </Row>
    </div>
  )
}

export default ForgetPassword
