import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Button, Typography } from "antd"
import moment from "moment"
import PlanView from "@fitpeo/fit-plan-viewer"
import "./RPMViewQuestion.css"
import { getrpmCareplanDataActions } from "../../../../../redux/actions/rpmAction"
import { getPatientDiagnosticsData } from "../../../../../redux/actions/patientAction"
import Loading from "../../../../Loading/Loading"

function RPMViewQuestion() {
  const [providers, setProviders] = useState([])
  const [practitioners, setPractitioners] = useState([])

  const location = useLocation()
  const patientInfoDetail = location?.state?.patientInfoDetail
  const patientMonitoringData = location?.state?.patientMonitoringData
  const patientCaretaker = location?.state?.patientCaretaker

  const { loading: rpmLoading, getRpmCarePlan } = useSelector(state => state.rpmPatientCarePlan)
  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!patientInfoDetail || !patientInfoDetail?.id) navigate("/")
    dispatch(getrpmCareplanDataActions(patientInfoDetail?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, patientInfoDetail])

  useEffect(() => {
    if (!getRpmCarePlan) return
    dispatch(getPatientDiagnosticsData(patientInfoDetail?.id))
  }, [getRpmCarePlan]) //eslint-disable-line

  useEffect(() => {
    if (patientMonitoringData?.rpm) {
      let providers = []
      let practitioners = []
      patientMonitoringData?.rpm?.map(item => {
        if (item.providerId) {
          providers.push(`${item?.providerfirstName}${(item?.providerMiddleName && item?.providerMiddleName !== "") ? " " + item?.providerMiddleName : ""} ${item?.providerLastName}`)
        }
        if (item.practitionerId) {
          practitioners.push(`${item?.practitionerfirstName}${(item?.practitionerMiddleName && item?.practitionerMiddleName !== "") ? " " + item?.practitionerMiddleName : ""} ${item?.practitionerLastName}`)
        }
      })
      setProviders(providers)
      setPractitioners(practitioners)
    }

  }, [patientMonitoringData])

  return rpmLoading ? (
    <Loading />
  ) : (
    (patientInfoDetail && getRpmCarePlan && (
      <>
        <div className="flex justify-end">
          <Button
            type="primary"
            onClick={() =>
              navigate("/rpm-view-treatement-print", {
                state: {
                  patientInfoDetail,
                  plan: Object.values(getRpmCarePlan?.plan)?.filter(data => data.id),
                  planDate: getRpmCarePlan?.planDate?.slice(0, 10),
                  planIcds: patientDiagnosticData?.map(({ icdCode }) => icdCode),
                  addedBy: getRpmCarePlan?.plan?.addingBy,
                  patientMonitoringData,
                  patientCaretaker,
                  providers: providers,
                  practitioners: practitioners
                }
              })
            }>
            Print
          </Button>
        </div>
        <PlanView
          type="rpm"
          patientInfoDetail={patientInfoDetail}
          plan={Object.values(getRpmCarePlan?.plan)?.filter(data => data.id)}
          planDate={getRpmCarePlan?.planDate?.slice(0, 10)}
          planIcds={patientDiagnosticData?.map(({ icdCode }) => icdCode)}
          addedBy={getRpmCarePlan?.plan?.addingBy}
          providers={providers}
          practitioners={practitioners}
          isDarkMode={false}
        />
      </>
    )) || (
      <Typography.Title level={4} className="text-center">
        Treatement Plan has not been taken
      </Typography.Title>
    )
  )
}

export default RPMViewQuestion
