import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const AuthGuard = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const userInfo = useSelector(state => state.userInfo)

  const navigate = useNavigate()

  useEffect(() => {
    if (Object.keys(userInfo).length === 0 || userInfo.userInfo === null) {
      navigate("/login", { replace: true })
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
  }, [userInfo]) //eslint-disable-line

  return isAuthenticated ? children : null
}

export default AuthGuard
