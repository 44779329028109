import { axiosGet, axiosPost } from "../axios"
import {

  GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
  GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST,
  GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
  POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
  POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
  POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS
} from "../types/eligiblePatientConstant"
import { userLogoutAction } from "./userActions"

export const getSelfOnboardingPendingPatientAction = () => async dispatch => {
  try {
    const userInfo =
    localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST
    })

    const { data } = await axiosGet(
      `/api/cs/bulk-upload/onboardingStatus/provider/profiles/${userInfo?.id}/fetch`
    )
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
      payload: data?.details
    })

  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
      payload: error.response?.data?.details
    })
  }
}

// POST action for bulk mail/messages to the patients
export const postBulkMailMessagePatientAction = formData => async dispatch => {
  try {
    dispatch({ type: POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST })

    const { data } = await axiosPost(`/api/cs/bulk-upload/messages`, formData)
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
      payload: data?.details
    })
    return { success: true, data: data?.details }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
      payload:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    })
    return {
      success: false,
      error:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    }
  }
}
