import mixpanel from 'mixpanel-browser';

//dev token
mixpanel.init('55e45ed91402d8934a16db0f9dbee447');
const userInfo = JSON.parse(localStorage.getItem("userInfo"))

let actions = {
    identify: (id) => {
       // mixpanel.identify(id);
       return
    },
    alias: (id) => {
       // mixpanel.alias(id);
       return
    },
    track: (name, props) => {
       /* mixpanel.track(name, {
            ...props,
            "from": "Provider",
            "ID": userInfo?.id,
            "UserName": userInfo?.userName,
        }); */
        return
    },
    people: {
        set: (props) => {
          //   mixpanel.people.set(props);
          return
        },
    },
    register: (props) => {
       // mixpanel.register(props)
       return
    },
    reset: () => {
       // mixpanel.reset()
       return
    }
};

export let Mixpanel = actions;