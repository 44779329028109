import { Button, Col, InputNumber, Modal, Row, Slider } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    deletePatientOxiCustomParameter,
    postPatientOxiCustomParameter,
    getPatientOxiCustomParameter,
    updatePatientOxiCustomParameter,
    resetPatientOxiCustomParameter
} from "../../redux/actions/customParameterActions"
import { useToasts } from "react-toast-notifications"
import { getPatientInfo } from "../../redux/actions/patientAction"
import { ExclamationCircleFilled } from "@ant-design/icons"

const MEDIUM_SPO2_LOW_MSG = "SpO2 medium range should be greater than or equal to 85%"
const MEDIUM_SPO2_HIGH_MSG = "SpO2 Medium range should be less than 100%"
const NORMAL_PULSE_MSG = "Pulse normal range should be greater than or equal to 40 BPM."
const HIGH_PULSE_MSG = "Pulse normal range should be less than or equal to 110 BPM."

const PatientOxiCustomParameter = ({ patientId, setShowCustomParameter, isEdit, setIsEdit, setIsCustomParameter, setOxiCustomParameterTab }) => {
    const [spo2Values, setSpo2Values] = useState([0, 92, 95, 100])
    const [tempSpO2Values, setTempSpO2Values] = useState([0, 92, 95, 100])
    const [marksSpo2, setMarksSpo2] = useState({})
    const [bpmValues, setBpmValues] = useState([10, 60, 100, 250])
    const [tempBpmValues, setTempBpmValues] = useState([10, 60, 100, 250])
    const [marksBpm, setMarksBpm] = useState({})
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showRemoveModal, setShowRemoveModal] = useState(false)
    const [disabledSpO2, setDisabledSpO2] = useState(true);
    const [disabledBpm, setDisabledBpm] = useState(true);
    const [spo2CustomError, setSpo2CustomError] = useState(false);
    const [bpmCustomError, setBpmCustomError] = useState(false);

    const [spo2StageWidth, setSpO2StageWidth] = useState({
        low: 20,
        medium: 20,
        normal: 20,
    });

    const [bpmStageWidth, setBpmStageWidth] = useState({
        low: 20,
        normal: 20,
        high: 20,
    });

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const { loading: OxiCustomParameterLoading, oxiCustomParameters } = useSelector(state => state.getPatientOxiCustomParameter)
    const { loading: postOxiCustomParameterLoading } = useSelector(state => state.postPatientOxiCustomParameter)
    const { loading: updateOxiCustomParameterLoading } = useSelector(state => state.updatePatientOxiCustomParameter)
    const { loading: deleteOxiCustomParameterLoading } = useSelector(state => state.deletePatientOxiCustomParameter)

    function calculatePercentage(start, end, minValue, maxValue) {
        const range = maxValue - minValue;
        const percentage = ((end - start) / range) * 100;
        return parseInt(percentage)
    }

    const handleSubmit = async () => {
        const formData = {
            "higherLimitSPO": 100,
            "lowerLimitSPO": 0,
            "higherPulseRate": 250,
            "lowerPulseRate": 10,
            "normalLowSPO": spo2Values[1],
            "normalHighSPO": spo2Values[2],
            "normalLowBPM": bpmValues[1],
            "normalHighBPM": bpmValues[2]
        }
        let result
        if (isEdit) {
            result = await dispatch(updatePatientOxiCustomParameter(formData, patientId))
        } else {
            result = await dispatch(postPatientOxiCustomParameter(formData, patientId))
        }
        if (result?.message.toLowerCase() === "success") {
            addToast(`Oximeter custom parameters ${isEdit ? "updated" : "added"} successfully`, {
                appearance: "success",
                autoDismiss: true
            })
            dispatch(getPatientInfo({ patientId }))
            setShowSaveModal(false);
            setIsCustomParameter(true);
            setOxiCustomParameterTab(true);
            setShowCustomParameter(false);
        } else {
            addToast(`Unable to ${isEdit ? "update" : "add"} BP custom parameters`, {
                appearance: "error",
                autoDismiss: true
            })
            setShowSaveModal(false);
        }
    }

    useEffect(() => {
        if (!oxiCustomParameters) {
            dispatch(getPatientOxiCustomParameter(patientId))
        }
    }, [])

    useEffect(() => {
        if (!OxiCustomParameterLoading) {
            if (oxiCustomParameters?.hasOwnProperty("id") && oxiCustomParameters?.hasOwnProperty("normalLowSPO")) {
                let customSpo2ValueArr = [];
                let customBpmValueArr = [];
                const spO2ValuesOrder = ["lowerLimitSPO", "normalLowSPO", "normalHighSPO", "higherLimitSPO"]
                const bpmValueOrder = ["lowerPulseRate", "normalLowBPM", "normalHighBPM", "higherPulseRate"]
                for (let i = 0; i < spO2ValuesOrder.length; i++) {
                    customSpo2ValueArr.push(oxiCustomParameters && oxiCustomParameters[spO2ValuesOrder[i]])
                }
                setSpo2Values(customSpo2ValueArr);
                setTempSpO2Values(customSpo2ValueArr);
                for (let j = 0; j < bpmValueOrder.length; j++) {
                    customBpmValueArr.push(oxiCustomParameters && oxiCustomParameters[bpmValueOrder[j]])
                }
                setBpmValues(customBpmValueArr)
                setTempBpmValues(customBpmValueArr)
            }
        }

    }, [oxiCustomParameters, OxiCustomParameterLoading])

    useEffect(() => {
        let firstToSecondPoint = calculatePercentage(spo2Values[0], spo2Values[1], spo2Values[0], spo2Values[spo2Values.length - 1]);
        const secondToThirdPoint = calculatePercentage(spo2Values[1], spo2Values[2], spo2Values[0], spo2Values[spo2Values.length - 1]);
        const thirdToFourthPoint = calculatePercentage(spo2Values[2], spo2Values[3], spo2Values[0], spo2Values[spo2Values.length - 1]);
        firstToSecondPoint = (firstToSecondPoint > 90) ? 80 : firstToSecondPoint

        setSpO2StageWidth({
            ...spo2StageWidth,
            low: firstToSecondPoint,
            medium: secondToThirdPoint,
            normal: thirdToFourthPoint,
        })
    }, [spo2Values])

    useEffect(() => {
        let tempOxiValues = [...spo2Values];
        if (tempOxiValues[1] < 85) {
            setSpo2CustomError(MEDIUM_SPO2_LOW_MSG)
        } else if (tempOxiValues[2] > 99) {
            setSpo2CustomError(MEDIUM_SPO2_HIGH_MSG)
        } else {
            setSpo2CustomError("")
        }
    }, [spo2Values])

    useEffect(() => {
        const firstToSecondPoint = calculatePercentage(bpmValues[0], bpmValues[1], bpmValues[0], bpmValues[bpmValues.length - 1]);
        const secondToThirdPoint = calculatePercentage(bpmValues[1], bpmValues[2], bpmValues[0], bpmValues[bpmValues.length - 1]);
        const thirdToFourthPoint = calculatePercentage(bpmValues[2], bpmValues[3], bpmValues[0], bpmValues[bpmValues.length - 1]);

        setBpmStageWidth({
            ...bpmStageWidth,
            low: firstToSecondPoint,
            normal: secondToThirdPoint,
            high: thirdToFourthPoint,
        })
    }, [bpmValues])

    useEffect(() => {
        let tempBpmValues = [...bpmValues];
        if (tempBpmValues[1] < 40) {
            setBpmCustomError(NORMAL_PULSE_MSG)
        } else if (tempBpmValues[2] > 110) {
            setBpmCustomError(HIGH_PULSE_MSG)
        } else {
            setBpmCustomError("")
        }
    }, [bpmValues])

    useEffect(() => {
        const defaultValues = [0, 92, 95, 100]
        for (let i = 0; i < spo2Values.length - 1; i++) {
            if (spo2Values[i] >= spo2Values[i + 1]) {
                setDisabledSpO2(true)
                break
            }
            if (isEdit) {
                if (spo2Values[i] === tempSpO2Values[i]) {
                    setDisabledSpO2(true)
                } else {
                    setDisabledSpO2(false)
                    break
                }
            } else {
                if (spo2Values[i] === defaultValues[i]) {
                    setDisabledSpO2(true)
                } else {
                    setDisabledSpO2(false)
                    break
                }
            }
        }
    }, [spo2Values])

    useEffect(() => {
        const defaultValues = [10, 60, 100, 250]
        for (let i = 0; i < bpmValues.length - 1; i++) {
            if (bpmValues[i] >= bpmValues[i + 1]) {
                setDisabledBpm(true)
                break
            }
            if (isEdit) {
                if (bpmValues[i] === tempBpmValues[i]) {
                    setDisabledBpm(true)
                } else {
                    setDisabledBpm(false)
                    break
                }
            } else {
                if (bpmValues[i] === defaultValues[i]) {
                    setDisabledBpm(true)
                } else {
                    setDisabledBpm(false)
                    break
                }
            }
        }
    }, [bpmValues])

    const handleSpo2ReadingChange = (e, index) => {
        let tempSpo2Values = [...spo2Values];
        if (e < tempSpo2Values[index] + 11) {
            tempSpo2Values[index + 1] = tempSpo2Values[index] + (tempSpo2Values[index + 1] - tempSpo2Values[index]);
        } else {
            tempSpo2Values[index + 1] = e
        }
        setSpo2Values(tempSpo2Values)
    }

    const handleBpmReadingChange = (e, index) => {
        let tempBpmValues = [...bpmValues];
        if (e < 25) {
            tempBpmValues[index + 1] = tempBpmValues[index] + (tempBpmValues[index + 1] - tempBpmValues[index]);
        } else {
            tempBpmValues[index + 1] = e
        }
        setBpmValues(tempBpmValues)
    }

    useEffect(() => {
        const newSpO2value = spo2Values.slice(1, -1)
        const newMarksSpO2 = newSpO2value.reduce((acc, value, index) => {
            acc[value] = {
                label: (
                    <>
                        <InputNumber
                            value={value}
                            controls={false}
                            style={{
                                width: '50px',
                                height: '30px',
                                fontSize: '14px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                            onChange={(e) => handleSpo2ReadingChange(e, index)}
                        />
                    </>
                ),
                style: {
                    position: "absolute",
                    top: "-50px",
                    fontSize: "16px"
                }
            };
            return acc;
        }, {});
        setMarksSpo2(newMarksSpO2)

    }, [spo2Values])

    useEffect(() => {
        const newBpmvalue = bpmValues.slice(1, -1)
        const newMarksBpm = newBpmvalue.reduce((acc, value, index) => {
            acc[value] = {
                label: (
                    <>
                        <InputNumber
                            value={value}
                            controls={false}
                            style={{
                                width: '50px',
                                height: '30px',
                                fontSize: '14px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                            onChange={(e) => handleBpmReadingChange(e, index)}
                        />
                    </>
                ),
                style: {
                    position: "absolute",
                    top: "-50px",
                    fontSize: "16px"
                }
            };
            return acc;
        }, {});
        setMarksBpm(newMarksBpm)
    }, [bpmValues])

    const trackColors = ["#ff3b30", "#ffcc00", "#34c759"]
    const pulseTrackColors = ["#ff3b30", "#34c759", "#ff3b30"]

    const HandleShowSaveModal = () => {
        setShowSaveModal(true);
    }
    const HandleShowRemoveModal = () => {
        setShowRemoveModal(true);
    }

    const handleRemoveOxiCustomParamter = async () => {
        const result = await dispatch(deletePatientOxiCustomParameter(patientId))
        if (result?.message.toLowerCase() === "success") {
            await dispatch(resetPatientOxiCustomParameter())
            addToast("Oximeter custom parameters deleted successfully", {
                appearance: "success",
                autoDismiss: true
            })
            dispatch(getPatientInfo({ patientId }))
            setShowRemoveModal(false);
            setIsCustomParameter(false);
            setOxiCustomParameterTab(false);
            setIsEdit(false);
            setShowCustomParameter(false);
        } else {
            addToast("Unable to delete Oximeter custom parameters", {
                appearance: "error",
                autoDismiss: true
            })
            setShowRemoveModal(false);
        }
    }

    return (
        <div>
            <div className="flex flex-col gap-9 mb-5">
                <div>
                    <div className="flex justify-start items-center gap-2">
                        <h6 className="text-lg font-bold text-secondary mb-2">SpO2</h6>
                    </div>
                    <div className="relative">
                        <Slider
                            range
                            min={0}
                            max={100}
                            onChange={(props) => {
                                let newProps = [...props];
                                newProps[0] = 0;
                                newProps[props.length - 1] = 100;
                                setSpo2Values(newProps);
                            }}
                            value={spo2Values}
                            marks={marksSpo2}
                            tooltip={{ open: false }}
                            trackStyle={trackColors.map((color, index) => ({
                                backgroundColor: color
                            }))}
                        />
                        <Row className="flex justify-between">
                            <Col className="flex justify-center items-center font-medium" style={{ width: `${spo2StageWidth.low}%` }}>Low</Col>
                            <Col className="flex justify-center items-center font-medium" style={{ width: `${spo2StageWidth.normal}%` }}>Medium</Col>
                            <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${spo2StageWidth.high}%` }}>Normal</Col>
                        </Row>
                        <div className="w-full text-danger flex justify-start items-center gap-2 font-medium">
                            {(spo2CustomError !== "") &&
                                <>
                                    <ExclamationCircleFilled className="text-[#E10505]" />
                                    <span>{spo2CustomError}</span>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex justify-start items-center gap-2">
                        <h6 className="text-lg font-bold text-secondary mb-2">BPM</h6>
                    </div>
                    <div className="relative">
                        <Slider
                            range
                            min={10}
                            max={250}
                            onChange={(props) => {
                                let newProps = [...props];
                                newProps[0] = 10;
                                newProps[props.length - 1] = 250;
                                setBpmValues(newProps);
                            }}
                            value={bpmValues}
                            marks={marksBpm}
                            tooltip={{ open: false }}
                            trackStyle={pulseTrackColors.map((color, index) => ({
                                backgroundColor: color
                            }))}
                        />
                        <Row className="flex justify-between">
                            <Col className="flex justify-center items-center font-medium " style={{ width: `${bpmStageWidth.low}%` }}>Low</Col>
                            <Col className="flex justify-center items-center font-medium " style={{ width: `${bpmStageWidth.normal}%` }}>Normal</Col>
                            <Col className="flex justify-center items-center font-medium " style={{ width: `${bpmStageWidth.high}%` }}>High</Col>
                        </Row>
                        <div className="w-full text-danger flex justify-start items-center gap-2 font-medium">
                            {(bpmCustomError !== "") &&
                                <>
                                    <ExclamationCircleFilled className="text-[#E10505]" />
                                    <span>{bpmCustomError}</span>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Row className="flex justify-center items-center gap-4">
                {isEdit &&
                    <Button
                        type="default"
                        size="large"
                        className="w-full md:w-[40%] xl:w-1/3"
                        onClick={() => HandleShowRemoveModal()}
                    >
                        Remove Custom Parameters
                    </Button>
                }
                <Button
                    type="primary"
                    size="large"
                    disabled={(disabledSpO2 && disabledBpm) || (spo2CustomError || bpmCustomError)}
                    className="w-full md:w-[40%] xl:w-1/3"
                    onClick={() => HandleShowSaveModal()}
                >
                    Save
                </Button>
            </Row>
            <Modal
                footer={true}
                open={showSaveModal}
                centered
                width={700}
                onCancel={() => setShowSaveModal(false)}
                title={<h4 className="text-xl">Are you sure you want to save the changes?</h4>}
            >
                <Row className="flex justify-end items-center gap-6 pr-4 mt-4">
                    <Button
                        type="default"
                        size="large"
                        className="w-32"
                        onClick={() => setShowSaveModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        className="w-32"
                        disabled={postOxiCustomParameterLoading || updateOxiCustomParameterLoading}
                        onClick={() => handleSubmit()}
                    >
                        Save
                    </Button>
                </Row>
            </Modal>
            <Modal
                footer={true}
                open={showRemoveModal}
                centered
                width={700}
                onCancel={() => setShowRemoveModal(false)}
                title={<h4 className="text-xl">Are you sure you want to remove custom parameters and go back to default?</h4>}
            >
                <Row className="flex justify-end items-center gap-6 pr-4 mt-4">
                    <Button
                        type="default"
                        size="large"
                        className="w-32"
                        onClick={() => setShowRemoveModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        className="w-32"
                        disabled={deleteOxiCustomParameterLoading}
                        onClick={() => handleRemoveOxiCustomParamter()}
                    >
                        Confirm
                    </Button>
                </Row>
            </Modal>
        </div>
    )
}

export default PatientOxiCustomParameter