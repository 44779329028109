import { Button, Checkbox, Input, Space, Tooltip } from "antd"
import ProTable from "@ant-design/pro-table"

import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { OnBoardingPendingStage, PatientStage } from "./Types"
import { TbMessageShare } from "react-icons/tb"
import SendInviteModal from "./SendInviteModal"
import OnBoardingStageDropdown from "./OnboardingStageDropdown"

const OnboardingPendingPatient = props => {
  const { columns, onboardPendingPatient } = props

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [filteredPatients, setFilteredPatients] = useState([])
  const [searchPatients, setSearchPatients] = useState([])
  const [onboardingSelectedStage, setOnboardingSelectedStage] = useState()
  const [searchQuery, setSearchQuery] = useState()
  const [openSendInviteModal, setOpenSendInviteModal] = useState(false)

  useEffect(() => {
    let filtered = onboardPendingPatient
    // Filter based on onboarding stage
    if (onboardingSelectedStage) {
      filtered = filtered?.filter(
        patient =>
          patient?.onBoardingStage?.toLowerCase() === onboardingSelectedStage?.toLowerCase()
      )
    }
    setFilteredPatients(filtered)
  }, [onboardingSelectedStage, onboardPendingPatient])

  useEffect(() => {
    // @ts-expect-error
    setSearchPatients(filterPatients(filteredPatients, searchQuery))
  }, [searchQuery])

  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }
  const filterPatients = (patients, query) => {
    if (!query) return patients

    const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()

    return patients.filter(p => {
      const fullName = `${p.firstName}${p.middleName || ""}${p.lastName}`.toLowerCase()
      const reversedFullName = `${p.lastName}${p.middleName || ""}${p.firstName}`.toLowerCase()

      return (
        p.firstName?.toLowerCase().includes(query) ||
        p.middleName?.toLowerCase().includes(query) ||
        p.lastName?.toLowerCase().includes(query) ||
        p.email?.toLowerCase().includes(query) ||
        p.id?.toLowerCase().includes(query) ||
        String(p.phone)
          ?.toLowerCase()
          .includes(trimmedSearchValue?.replace(/[()-]/g, "") || "") ||
        fullName.includes(trimmedSearchValue || "") ||
        reversedFullName.includes(trimmedSearchValue || "")
      )
    })
  }

  const handleSelectAll = selected => {
    // @ts-expect-error
    setSelectedRowKeys(selected ? filteredPatients?.map(r => r.id) : [])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnTitle: (
      <Checkbox
        checked={selectedRowKeys?.length === onboardPendingPatient?.length}
        indeterminate={
          selectedRowKeys?.length > 0 && selectedRowKeys.length < onboardPendingPatient?.length
        }
        onChange={e => handleSelectAll(e.target.checked)}
      />
    )
  }

  const onBoardingStageSelectionClear = () => {
    setOnboardingSelectedStage("")
    setFilteredPatients(onboardPendingPatient)
  }

  return (
    <>
      <ProTable
        className="uploaded-patients-table"
        // @ts-expect-error
        rowKey={record => record.id}
        columnsState={{
          persistenceKey: "pro-table-onboardingpending-patient-columns",
          persistenceType: "localStorage"
        }}
        // loading={patientLoading}
        dataSource={!searchQuery ? filteredPatients : searchPatients}
        columns={columns}
        toolbar={{
          actions: [
            <Input
              key="search"
              style={{ width: 241, height: 48 }}
              onChange={e => handleSearch(e)}
              allowClear
              disabled={!onboardPendingPatient?.length}
              placeholder="Search Patient"
              prefix={<SearchOutlined className="disable" />}
            />
          ]
        }}
        tableAlertOptionRender={({ onCleanSelected }) => (
          <div className="flex max-sm:flex-col">
            <Tooltip title="Send Invite" className="mr-4">
              <Button
                type="primary"
                onClick={() => setOpenSendInviteModal(true)}
                shape="circle"
                icon={<TbMessageShare size="20px" />}
              />
            </Tooltip>
            &nbsp; &nbsp;
            <Button onClick={onCleanSelected} className="mr-auto text-danger border-danger">
              Clear selection
            </Button>
          </div>
        )}
        rowSelection={rowSelection}
        options={{
          density: false,
          reload: false,
          fullScreen: false,
          setting: {
            children: (
              <Space.Compact
                className={`card ${"light"} flex md:flex-row max-md:flex-col max-md:mb-20`}
                style={{ borderWidth: 0, height: 48 }}>
                <Button
                  style={{ width: 241, height: "inherit" }}
                  className="flex place-items-center p-0">
                  <span className="secondary text-xs px-4">Filter By</span>
                  <div
                    className="border-l flex-1 flex items-center justify-center gap-2"
                    style={{
                      height: "inherit",
                      borderColor: "rgba(0, 0, 0, 0.1)"
                    }}>
                    Column Visibility
                    <DownOutlined />
                  </div>
                </Button>
                <Space.Compact
                  style={{ height: "inherit", display: "flex" }}
                  onClick={e => e.stopPropagation()}>
                  <OnBoardingStageDropdown
                    setOnboardingSelectedStage={setOnboardingSelectedStage}
                    options={OnBoardingPendingStage}
                  />
                </Space.Compact>
              </Space.Compact>
            )
          }
        }}
        search={false}
        pagination={{
          defaultPageSize: 10,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
        }}
        scroll={{ x: "max-content" }}
      />
      <SendInviteModal
        selectedPatientIds={selectedRowKeys}
        open={openSendInviteModal}
        onClose={() => setOpenSendInviteModal(false)}
      />
    </>
  )
}

export default OnboardingPendingPatient
