import ErrorPage from "../views/404"
import MobileCCM from "../views/MobileCCM"
import MobileRPM from "../views/MobileRPM"
import LoginComponent from "../components/Login/LoginHome"
import ForgotPassword from "../components/ForgetPassword/ForgetPassword"
import AccountComponent from "../components/Account/Setting"
import RPMViewCare from "../components/RPMAdmin/RPMViewCare/RPMViewCare"
import CCMViewCare from "../components/CCMAdmin/CCMViewCare/CCMViewCare"
import ProviderDetailComponent from "../components/Provider/ProviderDetail"
import PatientDetailComponent from "../components/PatientDetails/PatientDetail"
// import RPMViewPrint from "../components/RPMAdmin/RPMViewCare/Component/RPMViewQuestion/RPMViewPrint"
// import CCMViewPrint from "../components/CCMAdmin/CCMViewCare/Component/CCMViewQuestion/CCMViewPrint"

import Layout from "../layout/MainLayout"
import AuthGuard from "../components/AuthGuard"
import CCMViewPrintV2 from "../components/CCMAdmin/CCMViewCare/Component/CCMViewQuestion/CCMViewPrintV2"
import RPMViewPrintV2 from "../components/RPMAdmin/RPMViewCare/Component/RPMViewQuestion/RPMViewPrintV2"
import PractitionerList from "../components/Practitioner/PractitionerList"
import PractitionerProfile from "../components/Practitioner/PractitionerProfile"
import PractitionerProviderList from "../components/Practitioner/PractitionerProviderList"
import PCMViewCare from "../components/PCMAdmin/PCMViewCare/PCMViewCare"
import PCMViewPrintV2 from "../components/PCMAdmin/PCMViewCare/PCMViewQuestion/PCMViewPrintV2"
import ProviderProfile from "../components/Practitioner/PractitionerProvider/ProviderProfile"
import MobilePCM from "../views/MobilePCM"

const Routes = [
  {
    path: "/",
    element: (
      <Layout>
        <AuthGuard>
          <ProviderDetailComponent />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/providers",
    element: (
      <Layout>
        <AuthGuard>
          <PractitionerProviderList />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/provider/:providerId",
    element: (
      <Layout>
        <AuthGuard>
          <ProviderProfile />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/practitioners",
    element: (
      <Layout>
        <AuthGuard>
          <PractitionerList />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/practitioner/:practitionerId",
    element: (
      <Layout>
        <AuthGuard>
          <PractitionerProfile />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/patient/:id",
    element: (
      <Layout>
        <AuthGuard>
          <PatientDetailComponent />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/patient",
    element: (
      <Layout>
        <AuthGuard>
          <ProviderDetailComponent />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/ccm-view-care",
    element: (
      <Layout>
        <AuthGuard>
          <CCMViewCare />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/pcm-view-care",
    element: (
      <Layout>
        <AuthGuard>
          <PCMViewCare />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/ccm-view-care-print",
    element: (
      <Layout>
        <AuthGuard>
          {/* <CCMViewPrint /> */}
          <CCMViewPrintV2 />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/pcm-view-care-print",
    element: (
      <Layout>
        <AuthGuard>
          {/* <CCMViewPrint /> */}
          <PCMViewPrintV2 />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/rpm-view-treatement",
    element: (
      <Layout>
        <AuthGuard>
          <RPMViewCare />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/rpm-view-treatement-print",
    element: (
      <Layout>
        <AuthGuard>
          {/* <RPMViewPrint /> */}
          <RPMViewPrintV2 />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/setting",
    element: (
      <Layout>
        <AuthGuard>
          <AccountComponent />
        </AuthGuard>
      </Layout>
    )
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/mobile-ccm",
    element: <MobileCCM />
  },
  {
    path: "/mobile-pcm",
    element: <MobilePCM />
  },
  {
    path: "/mobile-rpm",
    element: <MobileRPM />
  },

  { path: "/login", element: <LoginComponent /> },
  {
    path: "*",
    element: <ErrorPage />
  }
]

export default Routes
