//ccm
export const GET_CCM_REQUEST = "GET_CCM_REQUEST";
export const GET_CCM_SUCCESS = "GET_CCM_SUCCESS";
export const GET_CCM_FAIL = "GET_CCM_FAIL";

export const POST_CCM_USER_REQUEST = "POST_CCM_USER_REQUEST";
export const POST_CCM_USER_SUCCESS = "POST_CCM_USER_SUCCESS";
export const POST_CCM_USER_FAIL = "POST_CCM_USER_FAIL";

export const GET_CCM_CAREPLAN_REQUEST = "GET_CCM_CAREPLAN_REQUEST";
export const GET_CCM_CAREPLAN_SUCCESS = "GET_CCM_CAREPLAN_SUCCESS";
export const GET_CCM_CAREPLAN_FAIL = "GET_CCM_CAREPLAN_FAIL";

export const GET_FILTER_USERS_REQUEST = "POST_FILTER_USERS_REQUEST";
export const RESET_FILTER_USERS_REQUEST = "RESET_FILTER_USERS_REQUEST";
export const GET_FILTER_USERS_SUCCESS = "POST_FILTER_USERS_SUCCESS";
export const GET_FILTER_USERS_FAIL = "POST_FILTER_USERS_FAIL";

export const GET_USER_SEARCH_REQUEST = "POST_USER_SEARCH_REQUEST";
export const GET_USER_SEARCH_SUCCESS = "POST_USER_SEARCH_SUCCESS";
export const GET_USER_SEARCH_FAIL = "POST_USER_SEARCH_FAIL";

export const GET_FACILITY_ADDRESS_REQUEST = "POST_FACILITY_ADDRESS_REQUEST";
export const GET_FACILITY_ADDRESS_SUCCESS = "POST_FACILITY_ADDRESS_SUCCESS";
export const GET_FACILITY_ADDRESS_FAIL = "POST_FACILITY_ADDRESS_FAIL";